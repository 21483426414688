import React, { Component } from "react";
import "./style.css";
import ComponentTableDoc from "./ocrTable/componentTable";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { DOWNLOAD_DOC } from "./constants";
import { InputText } from "primereact/inputtext";

class ComponentDocumentosCargados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idDoc: false,
            filtroTable: [],
            labelTable: {
                columna1: 'Fecha de cargue',
                columna2: 'Nombre',
                columna3: 'Resumen',
                columna4: 'Acciones',
                columna7: 'Id Documento'
            }
        }

    }
    componentWillMount() {
        const {
            data

        } = this.props;
        this.setState({
            filtroTable: data
        })

    }

    filtrarTabla = (e) => {
        //console.log("VALUE FILTER TABLE", e.target.value)
        let texto = e.target.value
        const { data } = this.props;
        // console.log("TEXTTIO", texto)
        //console.log("filter intentsData", intentsData)
        let listaFiltrada = data.filter(function (objeto) {
            var valorPropiedad = String(objeto.name).toLowerCase();
            var textoBusqueda = texto.toLowerCase();

            // Verificar si el valor de la propiedad contiene el texto de búsqueda
            return valorPropiedad.includes(textoBusqueda);
        });
        //console.log("listaFiltrada", listaFiltrada)

        this.setState({
            filtroTable: listaFiltrada
        })
    }


    componentDidUpdate(prevProps) {
        const { data } = this.props;

        if (prevProps.data !== data) {
            // console.log("intentsData", intentsData)
            //  console.log("prevProps.intentsData", prevProps.intentsData)
            this.setState({
                filtroTable: [...data]
            })
            //getOCRDocumentos()
        }


    }
    idDocumento = (newData) => {
        const {
            mostrarDocumentosProcesados,
            showDocumentSelector
        } = this.props;
        this.setState({ idDoc: newData });
        mostrarDocumentosProcesados(newData)
        //EJECUTAR FUNCION QUE TRAE LOS DATOS DEL PDF PARA VISTA PREVIA
    }
    descargarDocumento = (newData) => {
        const {
            downloadDoc
        } = this.props;
        //  console.log("descargarDocumento", newData)
        downloadDoc(newData)
        //EJECUTAR FUNCION QUE TRAE LOS DATOS DEL PDF PARA VISTA PREVIA
    }


    render() {
        const {
            user,
            data,
            pdfData,
            cruceData,
            crossDoc
        } = this.props;
        return (
            <div className="App borrador-container">


                <Paper elevation={10} className="paper-conversaciones-ocr">
                    <div className="search-container">

                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText type="search" onChange={this.filtrarTabla} placeholder="Buscar documento proc..." />
                        </span>

                    </div>
                    <ComponentTableDoc
                        crossDoc={crossDoc}
                        downloadDoc={this.descargarDocumento}
                        labelTable={this.state.labelTable}
                        id={user}
                        cruceData={cruceData}
                        pdfData={pdfData}
                        idDocumento={this.idDocumento}
                        data={this.state.filtroTable}
                        title={'Documentos Procesados'} />
                </Paper>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {
        // downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value })

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentDocumentosCargados);