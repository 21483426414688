import React, { useState, useEffect } from 'react';
import "./style.css";

const RectangleOCR = ({ e, i,  startX, startY, width, height, setHoverState,stateHoverOcrDoc }) => {
  const [hovered, setHovered] = useState({ campo: e, state: false });

  useEffect(() => {
    // Update the document title using the browser API

  });
  const changeState = ((campo, state, type) => {
    if (hovered && hovered.campo === campo) {
      if (type === 1 && hovered.state === false) {
        setHovered({ campo: e, state: true })
        setHoverState(hovered)
      }
      if (type === 0 && hovered.state === true) {
        setHovered({ campo: e, state: false })
        setHoverState(hovered)
      }
    }

  })
  return (
    <React.Fragment>
      <rect
        // className={`${e} item ${hovered.state && hovered.campo === e ? 'underline' : ''}`} onMouseEnter={() => console.log("toque")} onMouseLeave={() => console.log("quite")}
        className={`${e}_${i} item ${(hovered.state && hovered.campo === e) || (stateHoverOcrDoc && stateHoverOcrDoc.campo === e && !stateHoverOcrDoc.state) ? 'underline-ocr-change-color' : ''}`} onMouseEnter={() => changeState(e, true, 1)} onMouseLeave={() => changeState(e, false, 0)}
        stroke="black"
        strokeWidth="2"
        x={startX}
        y={startY}
        width={width}
        height={height}
        style={{
          fill: "lightblue",
          opacity: 0.3
        }}
      />


    </React.Fragment>
  );
};

export default RectangleOCR;