import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, menuItems, menuItemsChatBot, PATH_API } from '../../Constants';

import {
  ADQUIRIR_PRODUCTO,
  BOT_ACTIVO,
  CARDS_ELE,
  COMPANY_DATA,
  GET_PLANES,
  GET_USERS, LOAD_MENU,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
  cardsEleSucces,
  botActivoSuccess,
  companyDataSuccess,
} from './actions';

import { firebaseDatabase } from "../../controller/firebase";
import { getFromSession } from "../../controller/session";
import { consultaProperty } from "../../controller/configuration";
import { botActivoActionSuccess, getBotsSuccess } from "../autogestionable/actions";
import moment from "moment";
import { doc } from "firebase/firestore";

function* getUsers() {
  const urlUsers = `${PATH_API}${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
    method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if (fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const { data } = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }




  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}




function* loadMenu(action) {
  let list = [];
  let company = getFromSession("company_id");
  let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItems.forEach((menu) => {
    if (menu.roles.includes(rol) && menu.configuration === enviosMasivos) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}

function* loadMenuChatBot(action) {
  //console.log("menuItemsChatBot SAGA inicio", menuItemsChatBot)
  let list = [];
  let company = getFromSession("company_id");
  //let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItemsChatBot.forEach((menu) => {
    if (menu.roles.includes(rol)) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}

function* cardsItems(action) {
  let cards = [];
  const user = getFromSession("currentUser");
  let resp = false;
  let botActivo = false
  let ocrActivo = false

  try {

    yield firebaseDatabase.collection(`productos`).get().then(docs => {
      docs.forEach(doc => {
        cards.push({
          ...doc.data(),
          key: doc.id
        });
      });
    });

 



    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/`).doc(`${resp.tipo_documento + resp.num_documento}`);
    const botActivoResp = yield docRef.get();
    if (botActivoResp.exists) {
      botActivo = botActivoResp.data().productoChatbot?.planActivo;
      ocrActivo = botActivoResp.data().productoOCR?.planActivo;
      //console.log('Valor del campo:', botActivo);
      // yield put(botActivoSuccess(botActivo));
      if (botActivo === true) {
        cards.forEach(doc => {
          if (doc.id === '1050') {
            doc.show = botActivo
          }
        });
      } else {
        doc.show = true
      }
      if (ocrActivo === true) {
        cards.forEach(doc => {
          if (doc.id === '1051') {
            doc.show = ocrActivo
          }
        });
      } else {
        doc.show = true
      }

    } else {
      console.log('El documento no existe.');
    }
  } catch (e) {
    console.log("erro obteniendo el campo bot_activo", e)
  }

  yield put(cardsEleSucces(cards));
}


function* isBotEnabled(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let botActivo = false
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/`).doc(`${resp.tipo_documento + resp.num_documento}`);
    const botActivoResp = yield docRef.get();
    if (botActivoResp.exists) {
      botActivo = botActivoResp.data().bot_activo;
      //console.log('Valor del campo:', botActivo);
      yield put(botActivoSuccess(botActivo));
    } else {
      console.log('El documento no existe.');
    }
  } catch (e) {
    console.log("erro obteniendo el campo bot_activo", e)
  }


  //return (botActivo)
}
function* companyData(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  yield put(companyDataSuccess(resp));
  return resp
}




export function* watchUsers() {
  yield takeLatest(COMPANY_DATA, companyData)
  yield takeLatest(BOT_ACTIVO, isBotEnabled)
  yield takeLatest(GET_USERS, getUsers);
  //yield takeLatest(LOAD_MENU, loadMenu);
  yield takeLatest(LOAD_MENU, loadMenuChatBot);
  yield takeLatest(CARDS_ELE, cardsItems)
}
