import { firebaseDatabase } from "./firebase";

export const compareArrays = (a, b) => JSON.stringify(a) === JSON.stringify(b);

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function convertMsToTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
        seconds,
    )}`;
}

export const emptyJson = (value) => {
    return value === undefined || JSON.stringify(value) === '{}' ? true : false;
}

export const emptyObject = (value) => {
    return typeof value !== 'object' && value !== undefined && value !== null && value !== "null" ? value : '';
}

export function convertMsToTimeObj(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;
    hours = ("0" + hours).slice(-2)
    seconds = ("0" + seconds).slice(-2)
    minutes = ("0" + minutes).slice(-2)
    days = ("0" + days).slice(-2)

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    return {
        days, hours, minutes, seconds
    }
        ;
}

function eliminarNull(obj) {
    return obj.filter((objeto) => {
        for (const propiedad in objeto) {
            if (objeto[propiedad] === null) {
                return false;
            }
        }
        return true;
    });
}

export async function mostrarDocumentosProcesados(action) {
    const { user } = action;

    return new Promise(async (resolve, reject) => {
        let resp = false;
        let dataDocument = false;
        let datosRender = false;
        let DataBD = false;
        const resultado = {};
        let company = false;
        let lote = false;
        let nameFile = "";
        let dataFile = false;

        try {
            await firebaseDatabase.collectionGroup('Documentos').where('datosDocumento.id', '==', 'kJdpKgggN45eUDcFOBDS').orderBy('datosDocumento.fecha_cargue', 'desc').get().then(docs => {
                docs.forEach(docs => {
                    resp = docs._delegate._key.path.segments;
                    nameFile = docs.data().datosDocumento.name;
                    dataFile = docs.data();
                });
            });
        } catch (error) {
            console.log('saga.mostrarDocumentosProcesados error1', error)
            throw error;
        }


        if (resp) {
            company = resp[6];
            lote = resp[8];

            console.log("*************000000000", lote, nameFile);
            try {
                const documentRef = await firebaseDatabase.collection('company').doc(`${company}`).collection('configuration').doc('DOCUMENT_FORM');

                // Obtén los datos del documento
                await documentRef.get()
                    .then((doc) => {
                        if (doc.exists) {
                            DataBD = doc.data().value;
                            console.log(doc.data());

                        } else {
                            console.log('El documento no existe');
                        }
                    })
            } catch (error) {
                console.log('saga.mostrarDocumentosProcesados error2', error)
                throw error;
            }

            let arrayCheckbox = []
            let arrayText = []
            let arraysolo = []
            let arregloNew = {};
            let arregloNew2 = {};
            let arregloNew3 = {};
            let textoFull = false;
            try {
                // await firebaseDatabase.collection(`company/${company}/documentosOCR/${lote}/Documentos`)

                //     .get().then(docs => {
                //         docs.forEach(doc => {

                if (dataFile) {


                    //console.log("nameFile", nameFile)
                    dataDocument = dataFile?.respuesta.pagina
                    textoFull = dataFile?.respuesta.texto_Full
                    //console.log("dataDocument", JSON.stringify(dataDocument))


                    let separador2 = '-'
                    let separador = '/'
                    dataDocument.forEach((objeto) => {

                        if (objeto.etiqueta.includes(separador)) {
                            //console.log("objeto", objeto)
                            const etiqueta = objeto.etiqueta.split("/")[0];
                            const nombreEtiqueta = objeto.etiqueta.split("/")[1];

                            if (etiqueta) {

                                arregloNew[etiqueta] = arregloNew[etiqueta] || [];

                                for (const propiedad in objeto.value) {
                                    //console.log("PROPIEDAD", propiedad)
                                    if (propiedad === 'booleanValue') {
                                        arregloNew[etiqueta].push({
                                            [nombreEtiqueta]: objeto.value.booleanValue,
                                            coordenadas: objeto.value.coordenadas
                                        });
                                    }
                                }

                            }
                        }
                        else if (objeto.etiqueta.includes(separador2)) {
                            const etiqueta = objeto.etiqueta.split("-")[0];
                            if (etiqueta) {
                                arregloNew2[etiqueta] = arregloNew2[etiqueta] || [];

                                if (objeto.value.subdatos) {
                                    arregloNew2[etiqueta].push(
                                        objeto.value.subdatos.map((subdato) => ({
                                            [subdato.Type]: subdato.mentiontext,
                                            coordenadas: subdato.coordenadas // Esta linea es para agregar las coordenadas a la respuesta. Debo quitar las coordenadas del front
                                        }))
                                    );
                                }
                            }
                        } else {
                            const etiqueta = objeto.etiqueta;
                            if (objeto.value.texto !== '') {
                                // arregloNew3[etiqueta] = arregloNew3[etiqueta] || [];
                                arregloNew3["Datos"] = arregloNew3["Datos"] || [];
                                arregloNew3["Datos"].push({ [etiqueta]: objeto.value.texto, coordenadas: objeto.value.coordenadas }) // Aqui falta sacar el subdato de la coordenada
                            }
                        }
                    });

                    arregloNew.Tipo_Id = eliminarNull(arregloNew.Tipo_Id);

                    let recuadrosEtiqueta = [];

                    arregloNew.Tipo_Id.forEach((element) => {
                        const firstKey = Object.keys(element)[0];
                        recuadrosEtiqueta.push({ Tipo_Id: firstKey, coordenadas: element.coordenadas });
                    });

                    for (let prop in arregloNew2) {
                        arregloNew2[prop].forEach((element) => {
                            element.forEach((element2) => {
                                recuadrosEtiqueta.push(element2);
                            });
                        });
                    };

                    arregloNew3.Datos.forEach((element) => {
                        recuadrosEtiqueta.push(element);
                    });

                    resultado.checkbox = arregloNew;
                    resultado.texto = arregloNew2;
                    resultado.campoUnico = arregloNew3;
                    resultado.textoFull = textoFull;
                    resultado.recuadrosEtiqueta = recuadrosEtiqueta;

                    if (DataBD) {

                        if (DataBD.length !== 0) {
                            DataBD.forEach((element) => {
                                //console.log("ELMENT DOCUMENT FORM DATA DB", element.tipoDocumento)

                                if (dataFile.respuesta?.tipoDeDocumento === element.tipoDocumento) {
                                    //console.log("dataFile.respuesta?.tipoDocumento ", dataFile.respuesta?.tipoDocumento )
                                    //console.log("element.tipoDocumento ", element.tipoDocumento )
                                    element.campos.forEach((e) => {


                                        let arraySubdatos = [];
                                        for (const propiedad in resultado.texto) {
                                            if (typeof resultado.texto[propiedad] === "object") {
                                                for (const propiedadSub in resultado.texto[propiedad]) {
                                                    // Imprime el elemento de la matriz
                                                    resultado.texto[propiedad][propiedadSub].forEach((data) => {
                                                        //console.log("e.value, data", e.value, Object.keys(data).toString())
                                                        if (e.value === Object.keys(data).toString()) {
                                                            // console.log("e.value, data", e.value, Object.keys(data))
                                                            //datosRender[propiedadSub] =  Object.values(data).toString();
                                                            // Agregamos una variable para almacenar los valores del arreglo
                                                            let valores = Object.values(data);

                                                            arraySubdatos.push(valores)

                                                        }
                                                    })
                                                }
                                            } else {
                                                // Imprime el valor del objeto
                                            }
                                        }

                                        datosRender[e.value] = arraySubdatos
                                        for (const propiedad in resultado) {

                                            if (typeof resultado[propiedad] === "object") {
                                                for (const propiedadSub in resultado[propiedad]) {
                                                    if (e.value === propiedadSub) {
                                                        datosRender[propiedadSub] = resultado[propiedad][propiedadSub];
                                                    }

                                                }
                                            } else if (e.value === propiedad) {
                                                datosRender[propiedad] = resultado[propiedad];
                                            }
                                        }

                                    });

                                }
                            })
                        }
                    }
                    if (Object.keys(datosRender).length === 0) {
                        datosRender = resultado;

                    }

                }
                //     });
                // });
                resolve("datosRender");
            } catch (error) {
                //console.log('uploadImage', error)
                reject(error);
                throw error;
            }
        }
        // console.log('uploadImage', datosRender);
        // return datosRender;
    })

}