import { CHARGE_OCR_DOCUMENTS_SUCCESS, SAVE_URL_PDF } from "./constants";

export function chargeOcrDocumentsSuccess (payload) {
  return {
      type: CHARGE_OCR_DOCUMENTS_SUCCESS,
      payload
  };
}
export function saveUrlPdf (payload) {
  return {
      type: SAVE_URL_PDF,
      payload
  };
}
