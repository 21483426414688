import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, menuItemsChatBotGratis, menuItemsChatBot, PATH_API } from '../../Constants';
import { collection, addDoc, setDoc, doc, getDoc, deleteDoc, updateDoc, getDocs } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import axios from "axios";
import moment from "moment";

import {
  BOT_ACTIVO,
  CREATE_AGENT,
  CREATE_DIALOGFLOW_INTENT,
  DELETE_BOTS,
  DELETE_DIALOGFLOW_AGENT,
  DELETE_DIALOGFLOW_INTENT,
  DELETE_INTENT,
  EDIT_INTENT,
  FIN_SUSCRIBCION,
  GET_BOTS,
  GET_INTENT,
  GET_PLANES,
  GET_TRANSACCION,
  GET_USERS, LIST_CLOUD_PROJECTS, LIST_DIALOGFLOW_INTENT, LOAD_MENU, NOMBRE_BOT, PRUEBA_GRATIS, REQUESTS_BACK_SUCCESS, UPDATE_DIALOGFLOW_INTENT, UPDATE_PROFILE, UPLOAD_ICON, UPLOAD_IMAGE, UPLOAD_INTENT,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
  uploadImageSuccess,
  getBotsSuccess,
  getIntentsSuccess,
  editIntentsSuccess,
  deleteIntentsSuccess,
  uploadIntentSuccess,
  createDialogflowIntentSuccess,
  deleteDialogflowAgentSuccess,
  deleteDialogflowIntentSuccess,
  listDialogflowIntentSuccess,
  updateDialogflowIntentSuccess,
  uploadIconSuccess,
  createAgentSuccess,
  requestBackendAction,
  nombreBotSuccess,
  botActivoActionSuccess,
  listCloudSuccess,
  updateProfileSuccess,
  pruebaGratisSuccess,
  getPlanesSuccess,
  finSuscribcionSuccess,
  getTransaccionSuccess,
} from './actions';

import { firebaseDatabase, firebaseField, storage } from "../../controller/firebase";
import { getFromSession } from "../../controller/session";
import { usersSelector } from "../bienvenida/selectors";

function* getUsers() {
  const urlUsers = `${PATH_API}${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
    method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if (fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const { data } = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }

  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}

function* loadMenuChatBot(action) {
  let list = [];
  let company = getFromSession("company_id");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  let resp = false;
  let producto = false;

  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/`).doc(`${resp.tipo_documento + resp.num_documento}`);
    const docResp = yield docRef.get();

    producto = docResp.data()
  } catch (error) {
    throw error;
  }

  if (producto?.productoChatbot?.plan === 'Gratis') {
    menuItemsChatBotGratis.forEach((menu) => {
      //console.log("menu", menu)
      if (menu.roles.includes(rol)) {
        list.push(menu);
      } else if (menu.roles.includes(rol)) {
        list.push(menu);
      }
    });
  }
  if (producto?.productoChatbot?.plan === 'Pymes') {
    menuItemsChatBot.forEach((menu) => {
      //console.log("menu", menu)
      if (menu.roles.includes(rol)) {
        list.push(menu);
      } else if (menu.roles.includes(rol)) {
        list.push(menu);
      }
    });
  }



  yield put(loadMenuSucces(list));



}




function* uploadIntent(action) {
  const user = getFromSession("currentUser");
  let nombreBot = false;
  let nombreBotUpd = false;

  //console.log("ACTION VALUE UPLOAD INTENT", action.value)

  try {
    let resp = false;
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        nombreBot = doc.data().nombreChatBot;
        nombreBotUpd = doc.data().nombreBotUpd;
        //console.log("INTENT OMBRE BOT", nombreBot)
      });
    });

    yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${nombreBot}`), {
      nombreIntent: action.value.nombreIntent,
      fraseEntrenamiento: action.value.fraseEntrenamiento,
      respuestaTexto: action.value.respuestaTexto ? action.value.respuestaTexto : '',
      respuestaLista: action.value.respuestaLista ? action.value.respuestaLista : '',
      respuestaImagen: action.value.respuestaImagen ? action.value.respuestaImagen : '',
      respuestaBoton: action.value.respuestaBoton ? action.value.respuestaBoton : '',
      fecha_creacion: firebaseField.serverTimestamp(),
      opciones_respuesta: action.value.opcionesRespuesta,
    });


  } catch (error) {
    //console.log('uploadIntent', error)
    throw error;
  }
  yield put(uploadIntentSuccess(true))
}

function* uploadImage(action) {
  //console.log("uploadImage")
  const user = getFromSession("currentUser");
  let resp = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  yield put(uploadImageSuccess(resp));
  return resp
}

function* getNombreBot() {
  const user = getFromSession("currentUser");
  let resp = false;
  let nombreBot = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        nombreBot = doc.data().nombreChatBot;
        //console.log("INTENT OMBRE BOT UPDATED", nombreBot)
      });
    });
  } catch (e) {
    return ("Error obteniendo el nombre del bot", e)
  }
  yield put(nombreBotSuccess(nombreBot));
  return (nombreBot);
}


function* uploadIcon(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let nombreBot = false;
  let updateIcon = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        nombreBot = doc.data().nombreChatBot;
        //console.log("INTENT OMBRE BOT UPDATED", nombreBot)
      });
    });

    //console.log("ICONOOO", `${resp.tipo_documento}${resp.num_documento}/productos/chatbot/icono/${action.value.name}`)
    const reader = new FileReader();

    const spaceRef = ref(storage, `${resp.tipo_documento}${resp.num_documento}/icono/${action.value.name}`);
    yield uploadBytes(spaceRef, action.value).then((snapshot) => {
    });

    let blob = yield fetch(action.value.objectURL).then((r) => r.blob()); //blob:url
    reader.readAsDataURL(blob);

    reader.onloadend = function () {
      const base64data = reader.result;
    };
    const url = `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${resp.tipo_documento}${resp.num_documento}/icono/${action.value.name}`
    //console.log("URLLL", url)
    const docRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento}${resp.num_documento}`);

    const updateData = {
      logoNombre: url
    };

    yield docRef.update(updateData)
      .then(() => {
        
        updateIcon = true
        // console.log("updateICON", updateIcon)
      })
      .catch((error) => {
        console.error('Error al actualizar el elemento:', error);
      });


  } catch (error) {
    //console.log('uploadIcon', error)
    throw error;
  }
  let toastDetail = false;
  console.log("updateICON", updateIcon)
  if (updateIcon) {
    toastDetail = {
      'summary': 'Realizado!',
      'severity': 'success',
      'detail': 'Se ha cargado el icono'
    }
  } else {
    toastDetail = {
      'summary': 'No realizado!',
      'severity': 'error',
      'detail': 'Ha ocurrido un error'
    }

  }

  yield put(uploadIconSuccess({
    toastDetail: toastDetail,
    showToast: true
  }));
  return resp
}
function* listCloudProjects(action) {
  const projectName = action.value.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  const projectId = projectName.replace(/[A-Z]/g, (letra) => letra.toLowerCase());
  try {
    let resp = false;
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "*",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }

    let projects = true;
    const urlDespliegueList = process.env.REACT_APP_LIST_GCLOUD_PROJECTS;
    projects = yield call(axios.post, urlDespliegueList, headers);
    if (projects) {
      projects.data.forEach(doc => {
        //console.log("CLOUD PROJECTSSS", doc)
        if (doc === projectId) {
          resp = doc;
          console.log("RESPSSS", resp)
        }
      });
    }

    return resp
  } catch (e) {
    return ("Error listando los proyectos - ", e)
  }
}

function* validateProjects(action) {
  let resp = false;
  const projects = yield listCloudProjects(action)
  //console.log("PPORJECTSSSS", projects)
  if (projects) {
    resp = true
  } else {
    resp = false
  }

  yield put(listCloudSuccess(resp));
  return (resp)
}


function* requestBackend(payload) {
  const resp = payload.payload.resp;
  const action = payload.payload.action;

  const projectName = action.value.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  const projectId = projectName.replace(/[A-Z]/g, (letra) => letra.toLowerCase());


  let bot = false;
  const collectionRef = firebaseDatabase.collection('bots');

  console.log("bot credetnials", bot);
  // Realiza la consulta para encontrar el primer valor que cumpla una condición
  yield collectionRef.where('company', '==', '').get().then((querySnapshot) => {
    console.log("bot credetnials", bot);
    if (!querySnapshot.empty) {
      // Si se encuentra un documento que cumple la condición, obtén el primer valor
      bot = querySnapshot.docs[0].data();
      console.log("bot credetnials", bot);
    } else {
      return ('No existen mas bots disponibles.');
    }
  })
  if (resp.bot_activo === false) {
    const docRefDIALOGFLOW_PROJECT = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('DIALOGFLOW_PROJECT');
    const DIALOGFLOW_PROJECT = {
      value: bot.projectId
    };
    const docRefDIALOGFLOW_ACCOUNT = yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('DIALOGFLOW_ACCOUNT');
    const DIALOGFLOW_ACCOUNT = {
      value: bot.APIKEYCLIENTE
    };

    docRefDIALOGFLOW_ACCOUNT.set(DIALOGFLOW_ACCOUNT);
    docRefDIALOGFLOW_PROJECT.set(DIALOGFLOW_PROJECT);
    console.log("CONFIGURATION")
  }
  //console.log("resp", resp)
  //console.log("action", action)
  //const nombreChatBot = action.value
  //let project = true;
  //const urlDespliegueProject = process.env.REACT_APP_CREATE_GLOUD_PROJECT;

  //project = yield call(axios.post, urlDespliegueProject, body, headers);

  //project = yield createPostSaga(body, headers, urlDespliegue)
  //console.log("project", project)



  if (resp.bot_activo === false) {
    //console.log("BOTTT", bot)
    yield setDoc(doc(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/productos/chatbots`), {
      nombreChatBot: bot.projectId,
      fecha_creacion: firebaseField.serverTimestamp(),
      nombreBotUpd: projectId
    });

    const docRefHORARIO_HABIL = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('HORARIO_HABIL');
    const HORARIO_HABIL = {
      administrable: true,
      value: {
        REG_HOURS: {
          "ini": "00:00",
          "fin": "23:59"
        },
        WEEKEND_HOURS: {
          "ini": "00:00",
          "fin": "00:00"
        }

      }
    };


    const docRefMENSAJE_BIENVENIDA = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('MENSAJE_BIENVENIDA');
    const MENSAJE_BIENVENIDA = {
      administrable: true,
      name: 'Mensaje de bienvenida',
      tipo: 'texto',
      value: '¡Hola!, soy tu agente de prueba, puedes probar tus conversaciones aquí'
    };
    const docRefMENSAJE_BIENVENIDA_CLIENTE_WEB = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('MENSAJE_BIENVENIDA_CLIENTE_WEB');
    const MENSAJE_BIENVENIDA_CLIENTE_WEB = {
      administrable: true,
      name: 'Mensaje de bienvenida para navegador web',
      tipo: 'texto',
      value: '¡Hola!, soy tu agente de prueba, puedes probar tus conversaciones aquí'
    };



    const docRef2 = yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/habilidades`), {
      nombre: 'Habilidad General',

    });
    const docId = docRef2.id;
    //console.log("docIDDDD", docId)
    const updateRef = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/habilidades`).doc(docId);
    const res = yield updateRef.update({ id: docId });

    const updateRefUser = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/usuarios`)
    const querySnapshot = yield getDocs(updateRefUser);
    let userRef = false;
    querySnapshot.forEach((doc) => {
      userRef = doc.id;
    });
    const resUser = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/usuarios`).doc(userRef)

    yield resUser.update({
      skill: {
        id: docId,
        nombre: 'Habilidad General'
      }
    });

    yield firebaseDatabase.collection(`company/`).doc(`${resp.tipo_documento + resp.num_documento}`).update({
      bot_activo: true
    })

    const docRefBOT_ABIERTO = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('INICIAR_BOT_ABIERTO');
    const INICIAR_BOT_ABIERTO = {
      value: true
    };


    const docRefBOT_ENABLED = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('BOT_ENABLED');
    const BOT_ENABLED = {
      value: true
    };

    docRefBOT_ABIERTO.set(INICIAR_BOT_ABIERTO);
    docRefMENSAJE_BIENVENIDA.set(MENSAJE_BIENVENIDA);
    docRefMENSAJE_BIENVENIDA_CLIENTE_WEB.set(MENSAJE_BIENVENIDA_CLIENTE_WEB);
    docRefBOT_ENABLED.set(BOT_ENABLED);
    docRefHORARIO_HABIL.set(HORARIO_HABIL);
    //console.log("CONFIGURATION")

  }

  const lowerCase = action.value.toLowerCase();
  const regex = /[^a-zA-Z0-9 ]/g;

  // Aplicar el reemplazo utilizando la expresión regular
  const textoSinCaracteresEspeciales = lowerCase.replace(regex, '');

  let projectNameToDialogflow = textoSinCaracteresEspeciales.replace(/ /g, "-");

  let body = {
    'displayName': projectNameToDialogflow,
    'parent': bot.projectId,
    'cuenta': resp.tipo_documento + resp.num_documento
  }
  console.log("body", body)

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    'Access-Control-Allow-Credentials': 'true',
    "Access-Control-Request-Headers": "*",
    'accept': "application/json, text/plain, */*",
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  }
  const urlDespliegueAgent = process.env.REACT_APP_CREATE_DIALOGFLOW_AGENT;
  let agent = true;

  agent = yield call(axios.post, urlDespliegueAgent, body, headers);
  console.log("agent", agent)

  let toastDetail = false;
  if (agent) {
    toastDetail = {
      'summary': 'Realizado!',
      'severity': 'success',
      'detail': 'Se ha creado el bot'
    }
  } else {
    toastDetail = {
      'summary': 'No realizado!',
      'severity': 'error',
      'detail': 'Ha ocurrido un error'
    }

  }

  // setTimeout(() => {
  //   getDialogflowAgent(body, headers)
  // }, 3000)

  // setTimeout(() => {
  //   console.log('getDialogflowIntents');
  //   const respuestaIntents = getDialogflowIntents(body, headers, resp, projectId)
  //   console.log("respuestaIntents", respuestaIntents)
  // }, 3000)

  // //const urlDespliegueKey = process.env.REACT_APP_GEY_KEY;

  yield delay(2000);
  //const urlDespliegueIntent = "http://127.0.0.1:5003/chat-asesores-prueba/us-central1/listintent"
  const urlDespliegueIntent = process.env.REACT_APP_LIST_DIAGLOGFLOW_INTENT;
  let respues = false;

  respues = yield call(axios.post, urlDespliegueIntent, body, headers);
  console.log("respues", respues)

  if (respues.data && respues.data.length > 0) {
    let idIntent = []
    let nombreIntent = []
    let frasesEntrenamientoFalbackIntent = [];
    let fraseEntrenamientoFalbackFinal = [];

    let frasesEntrenamientoWalcomeIntent = [];
    let fraseEntrenamientoWalcomeFinal = [];

    let respuestaFallback = []
    let respuestaWalcome = []
    let opcionesRespuestaFalback = []
    let opcionesRespuestaWalcome = []

    let respuestaFinFalback = {}
    let respuestaFinWalcome = {}

    let respuestaTextFalback = []
    let respuestaTextWalcome = []
    let posWelCome = 0;
    let posFall = 0;
    respues.data.forEach((e, i) => {
      idIntent.push(e.id)
      nombreIntent.push(e.nombre)
      if (e.nombre === "Default Welcome Intent") {
        posWelCome = i;
        if (e.frasesEntrenamiento && e.frasesEntrenamiento.length > 0) {
          e.frasesEntrenamiento.forEach((intent, i) => {
            fraseEntrenamientoWalcomeFinal.push(intent.parts[0]?.text)
          })
        }
        respuestaWalcome = e.respuestas[0]?.text?.text;
        respuestaWalcome.forEach((intent, i) => {
          opcionesRespuestaWalcome.push({
            tipoRespuesta: 'text',
            valorRespuesta: '',
            value: intent,
            respuestaTexto: {
              texto: intent
            }
          })
        });

        respuestaFinWalcome = {
          nombreIntent: nombreIntent[1],
          fraseEntrenamiento: fraseEntrenamientoWalcomeFinal,
          opcionesRespuesta: opcionesRespuestaWalcome,
          respuestaTexto: opcionesRespuestaWalcome[0].respuestaTexto,
          respuestaBoton: '',
          respuestaImagen: '',
          respuestaLista: ''
        }
      }
      else if (e.nombre === "Default Fallback Intent") {
        posFall = i;
        if (e.fraseEntrenamiento && e.fraseEntrenamiento.length > 0) {
          e.frasesEntrenamiento.forEach((intent, i) => {
            fraseEntrenamientoWalcomeFinal.push(intent.parts[0]?.text)
          })
        }
        respuestaFallback = e.respuestas[0]?.text?.text;
        respuestaFallback.forEach((intent, i) => {
          opcionesRespuestaFalback.push({
            tipoRespuesta: 'text',
            valorRespuesta: '',
            value: intent,
            respuestaTexto: {
              texto: intent
            }
          })
        });
        respuestaFinFalback = {
          nombreIntent: nombreIntent[0],
          fraseEntrenamiento: fraseEntrenamientoFalbackFinal,
          opcionesRespuesta: opcionesRespuestaFalback,
          respuestaTexto: opcionesRespuestaFalback[0].respuestaTexto,
          respuestaBoton: '',
          respuestaImagen: '',
          respuestaLista: ''
        }
      } else {
        //WALCOME INTENT

        e.fraseEntrenamiento.forEach((intent, i) => {
          fraseEntrenamientoWalcomeFinal.push(intent.parts[0]?.text)
        })
        respuestaWalcome = e.respuestas[0]?.text?.text
        //console.log("respuestaWalcome", respuestaWalcome)
        if (respuestaWalcome && respuestaWalcome.length > 0) {
          respuestaWalcome.forEach((intent, i) => {
            opcionesRespuestaWalcome.push({
              tipoRespuesta: 'text',
              valorRespuesta: '',
              value: intent,
              respuestaTexto: {
                texto: intent
              }
            })
          })
        }

        frasesEntrenamientoFalbackIntent = e.fraseEntrenamiento;
        frasesEntrenamientoFalbackIntent.length > 0 && frasesEntrenamientoFalbackIntent.forEach((intent, i) => {
          fraseEntrenamientoFalbackFinal.push(intent.parts[0]?.text)
        })
        respuestaFallback = e.respuestas[0]?.text?.text

        if (respuestaFallback && respuestaFallback.length > 0) {
          respuestaFallback.forEach((intent, i) => {
            opcionesRespuestaFalback.push({
              tipoRespuesta: 'text',
              valorRespuesta: '',
              value: intent,
              respuestaTexto: {
                texto: intent
              }
            })
          })
        }

        respuestaFinWalcome = {
          nombreBot: projectId,
          nombreIntent: nombreIntent[1],
          fraseEntrenamiento: fraseEntrenamientoWalcomeFinal,
          opcionesRespuesta: opcionesRespuestaWalcome,
          respuestaTexto: opcionesRespuestaWalcome[0].respuestaTexto,
          respuestaBoton: '',
          respuestaImagen: '',
          respuestaLista: ''

        }

        respuestaFinFalback = {
          nombreBot: projectId,
          nombreIntent: nombreIntent[0],
          fraseEntrenamiento: fraseEntrenamientoFalbackFinal,
          opcionesRespuesta: opcionesRespuestaFalback,
          respuestaTexto: opcionesRespuestaFalback[0].respuestaTexto,
          respuestaBoton: '',
          respuestaImagen: '',
          respuestaLista: ''
        }
      }
    })


    yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${bot.projectId}`), {
      nombreIntent: nombreIntent[posFall],
      fraseEntrenamiento: fraseEntrenamientoFalbackFinal,
      fecha_creacion: firebaseField.serverTimestamp(),
      opciones_respuesta: opcionesRespuestaFalback,
      respuestaTexto: opcionesRespuestaFalback[0].respuestaTexto,
      respuestaBoton: respuestaFinFalback.respuestaBoton,
      respuestaImagen: respuestaFinFalback.respuestaImagen,
      respuestaLista: respuestaFinFalback.respuestaLista,
    });

    yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${bot.projectId}`), {
      nombreIntent: nombreIntent[posWelCome],
      fraseEntrenamiento: fraseEntrenamientoWalcomeFinal,
      fecha_creacion: firebaseField.serverTimestamp(),
      opciones_respuesta: opcionesRespuestaWalcome,
      respuestaTexto: opcionesRespuestaWalcome[0].respuestaTexto,
      respuestaBoton: respuestaFinWalcome.respuestaBoton,
      respuestaImagen: respuestaFinWalcome.respuestaImagen,
      respuestaLista: respuestaFinWalcome.respuestaLista,

    });
  }



  try {

    const collectionRefUpd = firebaseDatabase.collection('bots');
    const querySnapshot = yield collectionRefUpd.where('projectId', '==', bot.projectId).get();

    querySnapshot.forEach((doc) => {
      const docRef = doc.ref;
      // Define los campos y los nuevos valores que deseas actualizar
      const datosActualizados = {
        company: `${resp.tipo_documento + resp.num_documento}`
      };

      // Actualiza el documento y espera la resolución de la promesa
      docRef.update(datosActualizados);
    });
  } catch (error) {
    return ('Error al obtener los documentos o actualizar el documento:', error);
  }
  let updatedValue = false;
  try {
    const collectionRef = firebaseDatabase.collection('bots');
    const documentRef = collectionRef.doc('BOTSAVALIABLE');
    yield documentRef.get().then((doc) => {
      if (doc.exists) {
        const currentValue = doc.data().value; // Suponiendo que el campo con el valor se llama "valor"
        updatedValue = currentValue - 1;

        // Actualiza el valor del documento
        documentRef.update({ value: updatedValue })
          .then(() => {
            console.log('Documento actualizado correctamente');
          })
          .catch((error) => {
            console.error('Error al actualizar el documento:', error);
          });
      }
    })

  } catch (e) {
    return ("error actualizando la coleccion BOTSAVALIABLE")
  }

  try {
    if (updatedValue < 6) {
      const messages = `<p>¡Hola!</p> 

    <p>Solo quedan 5 proyecto disponibles, recuerda crear más.</p>`

      const body = {
        from_: "Recordatorio proyectos Avanti <chatasesores@gmail.com>",
        to_: 'jairo.vera@avanti-it.co',
        subject_: "crear mas proyectos Avanti-it",
        body: messages,
        user: "chatasesores@gmail.com",
        pass: "wdnwdcqgpdhwoskg"
      }
      const mail = yield call(axios.post, process.env.REACT_APP_CHATASESORES_SEND_MAIL, body, headers);

      console.log("MAIL", mail)
    }
  } catch (e) {
    return ("error enviando correo", e)
  }

  //yield collectionRef.doc('BOTSAVALIABLE').get();

  yield put(createAgentSuccess({
    toastDetail: toastDetail,
    showToast: true
  }))
  yield getIntents(bot.projectId)



}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}


function* createAgent(action) {
  const user = getFromSession("currentUser");

  let resp = false;
  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });

  try {
    setTimeout(yield put(requestBackendAction({ action, resp })), 4000)


  } catch (error) {
    console.error("createAgent:", error);
    throw `saga.js - createAgent - ${error}`;
  }
}

function* deleteAgent() {
  const user = getFromSession("currentUser");
  try {
    const urlDespliegue = process.env.REACT_APP_DELETE_DIAGLOGFLOW_AGENT;
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
    let respues = true;

    respues = yield call(axios.get, urlDespliegue, headers);

    yield put(deleteDialogflowAgentSuccess(respues));

    return respues;
  } catch (error) {
    console.error("deleteAgent:", error);
    throw `saga.js - deleteAgent - ${error}`;
  }
}




function* createIntentDialogflow(action) {
  const user = getFromSession("currentUser");

  let resp = false;
  let nombreBot = false;
  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });

  yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (resp.tipo_documento + resp.num_documento === 'NIT8919007328') {
        nombreBot = 'chat-asesores-desa'
      }
      else {
        nombreBot = doc.data().nombreChatBot;
      }
      //console.log("INTENT OMBRE BOT UPDATED", nombreBot)
    });
  });

  //console.log("frases entrenamiento", action.value)
  try {

    let body = {
      'intentName': action.value.nombreIntent,
      'trainingFrases': action.value.fraseEntrenamiento,
      'messages': action.value.opcionesRespuesta,
      'nombreBot': nombreBot
    }
    const urlDespliegue = process.env.REACT_APP_CREATE_DIALOGFLOW_INTENT;
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
    let respues = true;
    respues = yield call(axios.post, urlDespliegue, body, headers);
    let toastDetail = false;
    if (respues) {
      toastDetail = {
        'summary': 'Realizado!',
        'severity': 'success',
        'detail': 'Se ha creado un nuevo intent'
      }
    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un error'
      }

    }

    yield put(createDialogflowIntentSuccess({
      toastDetail: toastDetail,
      showToast: true
    }));

    yield getIntents(nombreBot)
    return respues;

  } catch (error) {
    console.error("createIntentDialogflow:", error);
    throw `saga.js - createIntentDialogflow - ${error}`;
  }
}

function* deleteIntentDialogflow(action) {
  const user = getFromSession("currentUser");
  //console.log("deleteIntentDialogflow", action.value)
  try {
    let resp = false;
    let nombreBot = false;
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (resp.tipo_documento + resp.num_documento === 'NIT8919007328') {
          nombreBot = 'chat-asesores-desa'
        }
        else {
          nombreBot = doc.data().nombreChatBot;
        }
        //console.log("INTENT OMBRE BOT UPDATED", nombreBot)
      });
    });

    let body = {
      'intentName': action.value,
      'nombreBot': nombreBot
    }
    const urlDespliegue = process.env.REACT_APP_DELETE_DIAGLOGFLOW_INTENT;
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
    let respues = true;
    respues = yield call(axios.post, urlDespliegue, body, headers);
    let toastDetail = false;
    if (respues) {
      toastDetail = {
        'summary': 'Realizado!',
        'severity': 'success',
        'detail': 'Se ha eliminado el intent seleccionado'
      }
    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un error'
      }

    }

    yield put(deleteDialogflowIntentSuccess({
      toastDetail: toastDetail,
      showToast: true
    }));

    yield getIntents(nombreBot)
    return respues;

  } catch (error) {
    console.error("deleteIntentDialogflow:", error);
    throw `saga.js - deleteIntentDialogflow - ${error}`;
  }
}

function* listIntentDialogflow(action) {
  const user = getFromSession("currentUser");
  try {
    let resp = false;
    let nombreBot = false;
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        nombreBot = doc.data().nombreChatBot;
        //console.log("INTENT OMBRE BOT UPDATED", nombreBot)
      });
    });

    let body = {
      'displayName': nombreBot,
      'cuenta': resp.tipo_documento + resp.num_documento
    }

    const urlDespliegue = process.env.REACT_APP_LIST_DIAGLOGFLOW_INTENT;
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
    let respues = true;
    respues = yield call(axios.post, urlDespliegue, body, headers);
    yield put(listDialogflowIntentSuccess(respues));

    // return respues;


  } catch (error) {
    console.error("listIntentDialogflow:", error);
    throw `saga.js - listIntentDialogflow - ${error}`;
  }
}

function* updateIntentDialogflow(action) {
  //console.log("ACTION UPDATE INTENT DIALOGFLOW", action)
  const user = getFromSession("currentUser");
  let nombreBot = false;
  let resp = false;

  try {


    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (resp.tipo_documento + resp.num_documento === 'NIT8919007328') {
          nombreBot = 'chat-asesores-desa'
        }
        else {
          nombreBot = doc.data().nombreChatBot;
        }
        //console.log("INTENT OMBRE BOT UPDATED", nombreBot)
      });
    });

    let body = {
      'intentName': action.value.intentData.nombreIntent,
      'newName': action.value.respuesta.nombreIntent,
      'trainingFrases': action.value.respuesta.fraseEntrenamiento,
      'messages': action.value.respuesta.opcionesRespuesta,
      'nombreBot': nombreBot,
    }
    let nombreBotUrl = false;
    if (nombreBot === 'chat-asesores-desa') {
      nombreBotUrl = 'Chatbot6'
    } else {
      nombreBotUrl = nombreBot
    }

    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${nombreBotUrl}`)
      .doc(`${action.value.intent.idIntent}`).update({
        nombreIntent: action.value.respuesta.nombreIntent,
        fraseEntrenamiento: action.value.respuesta.fraseEntrenamiento,
        respuestaTexto: action.value.respuesta.respuestaTexto,
        respuestaLista: action.value.respuesta.respuestaLista,
        respuestaImagen: action.value.respuesta.respuestaImagen,
        respuestaBoton: action.value.respuesta.respuestaBoton,
        fecha_creacion: firebaseField.serverTimestamp(),
        opciones_respuesta: action.value.respuesta.opcionesRespuesta,
      })

    //console.log("url firebase", `company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${action.value.intent.idIntent}`)
    //console.log("nombreChatBot", action.value.intent.nombreChatBot)
    //company/NIT9898/productos/chatbots/2RnPBW2TQnNPt0og8Mdd
    //company/NIT9898/productos/chatbots/chatbot4.0/2RnPBW2TQnNPt0og8Mdd
    //console.log("ID INTENT", action.value.intent.idIntent)
    // const docRef = doc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${action.value.intent.idIntent}`));
    // updateDoc(docRef, { 
    //   nombreIntent: action.value.respuesta.nombreIntent,
    // });


    // yield updateDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${action.value.intent.idIntent}`), {
    //   nombreIntent: action.value.respuesta.nombreIntent,
    //   fraseEntrenamiento: action.value.respuesta.fraseEntrenamiento,
    //   respuestaTexto: action.value.respuesta.respuestaTexto,
    //   respuestaLista: action.value.respuesta.respuestaLista,
    //   respuestaImagen: action.value.respuesta.respuestaImagen,
    //   respuestaBoton: action.value.respuesta.respuestaBoton,
    //   fecha_creacion: firebaseField.serverTimestamp(),
    //   opciones_respuesta: action.value.respuesta.opcionesRespuesta,
    // });


    const urlDespliegue = process.env.REACT_APP_UPDATE_DIAGLOGFLOW_INTENT;
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }

    let respues = true;
    respues = yield call(axios.post, urlDespliegue, body, headers);
    let toastDetail = false;
    if (respues) {
      toastDetail = {
        'summary': 'Realizado!',
        'severity': 'success',
        'detail': 'Se ha actualizado el intent seleccionado'
      }
    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un error'
      }

    }

    yield put(updateDialogflowIntentSuccess({
      toastDetail: toastDetail,
      showToast: true
    }));

    yield getIntents(nombreBot)
    return respues;
  } catch (error) {
    console.error("updateIntentDialogflow", error);
    throw `saga.js - updateIntentDialogflow - ${error}`;
  }
}

function* getBots() {
  const user = getFromSession("currentUser");
  let resp = false;
  let chatbots = [];
  let nombreBot = false
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    var docRef = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/productos`).doc("chatbots");
    yield docRef.get().then((doc) => {
      const botsData = doc.data();
      nombreBot = doc.data()
      if (doc.exists) {
        const bots = {
          nombreChatBot: botsData.nombreChatBot,
          fecha: botsData.fecha_creacion ? moment(botsData.fecha_creacion.toDate()).format("ll, h:mm:ss a") : false,
          id: doc.id
        }
        chatbots.push(bots);
      } else {
        //console.log("No such document!");
      }
    }).catch((error) => {
      //console.log("Error getting document:", error);
    });

    //docId = docRef2.id;
    //console.log("IDD", docId.id)


  } catch (error) {
    //console.log('getBots', error)
    throw error;
  }

  //console.log("getBots", chatbots)



  yield put(getBotsSuccess(nombreBot));
  return resp
}

function* getIntents(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let intents = [];
  let nombreBot = false
  try {
    yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get().then(docs => {
        docs.forEach(doc => {
          resp = doc.data();
        });
      });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        nombreBot = doc.data().nombreChatBot;
        console.log("INTENT OMBRE BOT", nombreBot)
      });
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${nombreBot}`)
      .get().then(docs => {
        docs.forEach(doc => {
          const botsData = doc.data();
          const intent = {
            nombreIntent: botsData.nombreIntent,
            fecha: botsData.fecha_creacion ? moment(botsData.fecha_creacion.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
            id: doc.id
          }
          intents.push(intent);
        });
      });

  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  console.log("INTENTSSSSS", intents)

  yield put(getIntentsSuccess(intents));
  return intents
}

function* deleteBots(infoBot) {

  const user = getFromSession("currentUser");
  let resp = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
    const subcollectionRef = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).doc(`${infoBot?.value?.idBot}`).collection(`${infoBot?.value?.nombreBot}`);
    //  console.log("subcollectionRef", subcollectionRef)
    subcollectionRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref.delete();
      });
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).doc(`${infoBot?.value?.idBot}`).delete();
  } catch (error) {
    //console.log('deleteBots', error)
    throw error;
  }
}







function* deleteIntent(infoIntent) {

  const user = getFromSession("currentUser");
  let resp = false;
  let nombreBot = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        nombreBot = doc.data().nombreChatBot;
        //console.log("INTENT OMBRE BOT", nombreBot)
      });
    });
    // console.log("infoIntent delete SAGA", infoIntent.value)
    const docRef = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${nombreBot}`).doc(`${infoIntent?.value?.idIntent}`);
    docRef.delete().then(() => {
      //console.log("Document successfully deleted!");
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
  } catch (error) {
    //console.log('deleteIntent', error)
    throw error;
  }
  yield put(deleteIntentsSuccess(true));

}


function* editIntent(infoIntent) {
  //console.log("INFOINTENT EDIT INTENT", infoIntent)
  const user = getFromSession("currentUser");
  let resp = false;
  let intent = false;
  let nombreBot = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        nombreBot = doc.data().nombreChatBot;
        //console.log("INTENT OMBRE BOT", nombreBot)
      });
    });

    const docRef = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${nombreBot}`).doc(`${infoIntent?.value?.idIntent}`);
    try {
      const doc = yield docRef.get();
      if (doc.exists) {
        intent = doc.data();
      } else {
        //console.log('No such intent!');
      }
    } catch (error) {
      //console.log('Error getting document:', error);
    }
  } catch (error) {
    //console.log('editIntent', error)
    throw error;
  }
  yield put(editIntentsSuccess(intent));
  return intent

}


function* updateProfile(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let nombreBot = false;

  const lowerCase = action.value.nombreChatBot.toLowerCase();
  const regex = /[^a-zA-Z0-9 ]/g;
  const textoSinCaracteresEspeciales = lowerCase.replace(regex, '');
  let projectNameToDialogflow = textoSinCaracteresEspeciales.replace(/ /g, "-");

  let empresa = false;

  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        nombreBot = doc.data().nombreChatBot;
        //console.log("INTENT OMBRE BOT", nombreBot)
      });
    });

    if (action.value.nombreChatBot) {
      const docRef = firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos`).doc(`chatbots`);

      const updateData = {
        nombreBotUpd: projectNameToDialogflow
      };

      docRef.update(updateData)
        .then(() => {
          
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }

    if (action.value.icono && action.value.icono !== process.env.REACT_APP_NOPICTURE) {
      const reader = new FileReader();
      const spaceRef = ref(storage, `${resp.tipo_documento}${resp.num_documento}/icono/${action.value.icono.name}`);
      yield uploadBytes(spaceRef, action.value.icono).then((snapshot) => {
      });

      let blob = yield fetch(action.value.icono.objectURL).then((r) => r.blob()); //blob:url
      reader.readAsDataURL(blob);

      reader.onloadend = function () {
        const base64data = reader.result;
      };
      const url = `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${resp.tipo_documento}${resp.num_documento}/icono/${action.value.icono.name}`
      //console.log("URLLL", url)
      const docRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento}${resp.num_documento}`);

      const updateData = {
        logoNombre: url
      };

      docRef.update(updateData)
        .then(() => {
          
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }
    if (action.value.icono === process.env.REACT_APP_NOPICTURE) {
      const docRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento}${resp.num_documento}`);

      const updateData = {
        logoNombre: process.env.REACT_APP_NOPICTURE
      };

      docRef.update(updateData)
        .then(() => {
          
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }

    if (action.value.avatar && action.value.avatar !== process.env.REACT_APP_NOPICTURE) {

      const reader = new FileReader();
      const spaceRef = ref(storage, `${resp.tipo_documento}${resp.num_documento}/avatar/${action.value.avatar.name}`);
      yield uploadBytes(spaceRef, action.value.avatar).then((snapshot) => {
      });

      let blob = yield fetch(action.value.avatar.objectURL).then((r) => r.blob()); //blob:url
      reader.readAsDataURL(blob);

      reader.onloadend = function () {
        const base64data = reader.result;
      };
      const url = `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${resp.tipo_documento}${resp.num_documento}/avatar/${action.value.avatar.name}`
      //console.log("URLLL", url)
      const docRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento}${resp.num_documento}`);

      const updateData = {
        avatar: url
      };

      docRef.update(updateData)
        .then(() => {
          
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }

    if (action.value.avatar === process.env.REACT_APP_NOPICTURE) {
      const docRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento}${resp.num_documento}`);

      const updateData = {
        avatar: process.env.REACT_APP_NOPICTURE
      };

      docRef.update(updateData)
        .then(() => {
          
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }

    if (action.value.logo && action.value.logo !== process.env.REACT_APP_NOPICTURE) {

      const reader = new FileReader();
      const spaceRef = ref(storage, `${resp.tipo_documento}${resp.num_documento}/logo/${action.value.logo.name}`);
      yield uploadBytes(spaceRef, action.value.logo).then((snapshot) => {
      });

      let blob = yield fetch(action.value.logo.objectURL).then((r) => r.blob()); //blob:url
      reader.readAsDataURL(blob);

      reader.onloadend = function () {
        const base64data = reader.result;
      };
      const url = `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${resp.tipo_documento}${resp.num_documento}/logo/${action.value.logo.name}`
      //console.log("URLLL", url)
      const docRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento}${resp.num_documento}`);

      const updateData = {
        logo: url
      };

      docRef.update(updateData)
        .then(() => {
          
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });

      //uploadImage();
    }

    if (action.value.logo === process.env.REACT_APP_NOPICTURE) {
      const docRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento}${resp.num_documento}`);

      const updateData = {
        logo: process.env.REACT_APP_NOPICTURE
      };

      docRef.update(updateData)
        .then(() => {
          
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }

    let body = {
      'displayName': projectNameToDialogflow,
      'parent': nombreBot,
      'cuenta': resp.tipo_documento + resp.num_documento
    }


    console.log("BODYYY", body)

    const urlDespliegue = process.env.REACT_APP_CREATE_DIALOGFLOW_AGENT;
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
    let respues = true;
    respues = yield call(axios.post, urlDespliegue, body, headers);
    let toastDetail = false;
    if (respues) {
      toastDetail = {
        'summary': 'Realizado!',
        'severity': 'success',
        'detail': 'Se ha actualizado el perfil'
      }
    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un error'
      }

    }

    yield getIntents(nombreBot)

    yield put(updateProfileSuccess({
      toastDetail: toastDetail,
      showToast: true
    }));
    //return respues;

    yield getIntents(nombreBot)


  } catch (error) {
    //console.log('uploadIcon', error)
    throw error;
  }
  //yield put(uploadImageSuccess(resp));
  //return resp
}


function* isBotEnabled(action) {
  const user = getFromSession("currentUser");
  //console.log("user", user)
  let resp = false;
  let botActivo = false
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/`).doc(`${resp.tipo_documento + resp.num_documento}`);
    const botActivoResp = yield docRef.get();
    if (botActivoResp.exists) {
      botActivo = botActivoResp.data().bot_activo;
      //console.log('Valor del campo:', botActivo);
      yield put(botActivoActionSuccess(botActivo));
    } else {
      console.log('El documento no existe.');
    }
  } catch (e) {
    console.log("erro obteniendo el campo bot_activo", e)
  }

  //return (botActivo)
}

function* finSuscribcion(action) {
  const user = getFromSession("currentUser");
  //console.log("user", user)
  let resp = false;
  let fecha_prueba_gratis = false
  let fecha_hoy = false;
  let producto = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/`).doc(`${resp.tipo_documento + resp.num_documento}`);
    const docResp = yield docRef.get();
    producto = docResp.data()
    if (docResp.exists) {
      if (docResp.data().productoChatbot?.fecha_compra) {


        fecha_prueba_gratis = docResp.data().productoChatbot.fecha_compra

        fecha_hoy = new Date()
        fecha_prueba_gratis = new Date(fecha_prueba_gratis.seconds * 1000)

        const diferenciaMilisegundos = fecha_hoy - fecha_prueba_gratis;

        // Convierte la diferencia de milisegundos a días
        const dias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

        // Redondea el resultado a un número entero
        fecha_prueba_gratis = Math.round(dias)
        if (producto.productoChatbot.plan === 'Gratis') {
          if (fecha_prueba_gratis >= 15) {
            const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);
            const producto_adquirido = {
              'productoChatbot.planActivo': false,
              'bot_activo': false
            };

            // yield docRefCompany.set(producto_adquirido);
            yield docRefCompany.update(producto_adquirido)
              .then(() => {
                
                //updateIcon = true
                // console.log("updateICON", updateIcon)
              })
              .catch((error) => {
                console.error('Error al actualizar el elemento:', error);
              });
          }
        } else if (producto.productoChatbot.pruebaGratis === false && producto.productoChatbot.plan === 'Pymes' && producto.productoChatbot.tipo === 'mensual') {
          if (fecha_prueba_gratis >= 30) {
            const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);
            const producto_adquirido = {
              'productoChatbot.planActivo': false,
              'bot_activo': false
            };

            // yield docRefCompany.set(producto_adquirido);
            yield docRefCompany.update(producto_adquirido)
              .then(() => {
                
                //updateIcon = true
                // console.log("updateICON", updateIcon)
              })
              .catch((error) => {
                console.error('Error al actualizar el elemento:', error);
              });
          }
        } else if (producto.productoChatbot.pruebaGratis === false && producto.productoChatbot.plan === 'Pymes' && producto.productoChatbot.tipo === 'anual') {
          if (fecha_prueba_gratis >= 365) {
            const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);
            const producto_adquirido = {
              'productoChatbot.planActivo': false,
              'bot_activo': false
            };

            // yield docRefCompany.set(producto_adquirido);
            yield docRefCompany.update(producto_adquirido)
              .then(() => {
                
                //updateIcon = true
                // console.log("updateICON", updateIcon)
              })
              .catch((error) => {
                console.error('Error al actualizar el elemento:', error);
              });
          }
        }
      }


    }
  } catch (e) {
    console.log("erro obteniendo el campo bot_activo", e)
  }

  yield put(finSuscribcionSuccess({
    fecha_prueba_gratis: fecha_prueba_gratis,
    plan: producto?.productoChatbot?.plan,
    tipo: producto?.productoChatbot?.tipo
  }));
}



function* getPlanes(action) {
  let fecha_prueba_gratis = false
  const user = getFromSession("currentUser");

  let resp = false;
  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });
  let planChatbot = resp.productoChatbot?.plan
  let planGratis = resp.productoChatbot?.pruebaGratis
  let planOcr = resp.productoOCR?.plan
  //let tipo = resp.producto?.tipo
  let respuesta = [];
  yield firebaseDatabase.collection(`planes/${action.value}/subplanes`).get().then(docs => {
    docs.forEach(doc => {
      if (planChatbot) {

        if (planChatbot === 'Gratis') {
          fecha_prueba_gratis = resp.productoChatbot?.fecha_compra

          const fecha_hoy = new Date()
          fecha_prueba_gratis = new Date(fecha_prueba_gratis.seconds * 1000)

          const diferenciaMilisegundos = fecha_hoy - fecha_prueba_gratis;

          // Convierte la diferencia de milisegundos a días
          const dias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

          // Redondea el resultado a un número entero
          fecha_prueba_gratis = Math.round(dias)

          if (planChatbot === doc.data().title && action.value === 'mensual') {
            if (fecha_prueba_gratis >= 15 || planGratis === false) {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Tu prueba gratuita ha finalizado'
              });
            } else {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Mi plan actual'
              });
            }

          }
          else if (planChatbot === doc.data().title && action.value === 'anual') {
            if (fecha_prueba_gratis >= 15) {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Tu prueba gratuita ha finalizado'
              });
            } else {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Mi plan actual'
              });
            }
          }
          else {
            respuesta.push({
              ...doc.data(),
              key: doc.id
            });
          }

        }

        if (planChatbot === 'Pymes') {
          fecha_prueba_gratis = resp.productoChatbot?.fecha_compra

          const fecha_hoy = new Date()
          fecha_prueba_gratis = new Date(fecha_prueba_gratis.seconds * 1000)

          const diferenciaMilisegundos = fecha_hoy - fecha_prueba_gratis;

          // Convierte la diferencia de milisegundos a días
          const dias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

          // Redondea el resultado a un número entero
          fecha_prueba_gratis = Math.round(dias)

          if (planChatbot === doc.data().title && action.value === resp.productoChatbot?.tipo) {
            if (fecha_prueba_gratis >= 30) {
              respuesta.push({
                ...doc.data(),
                key: doc.id
              });
            } else {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Mi plan actual'
              });
            }

          }
          else if (planChatbot === doc.data().title && action.value === resp.productoChatbot?.tipo) {
            if (fecha_prueba_gratis >= 15) {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Tu suscripción terminó',
                actualizar: true
              });
            } else {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Mi plan actual'
              });
            }

          }
          else {
            respuesta.push({
              ...doc.data(),
              key: doc.id
            });
          }

        }
      }
      else {
        respuesta.push({
          ...doc.data(),
          key: doc.id
        });
      }

    });
  });
  yield put(getPlanesSuccess(respuesta));
}

function* adquirirProducto(action) {
  console.log("adquirirProducto", action)
  let plan = false;
  let fecha_suscripcion = new Date();
  let producto = false;
  let valor = false;
  let pruebaGratis = false;
  let productoAdquirido = [];
  let resp = false;
  let producto_adquirido = false;

  const user = getFromSession("currentUser");

  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });

  if (action.id === '00001') {
    pruebaGratis = true
    plan = 'Gratis'
  }
  if (action.id === '00002') {
    plan = 'Pymes'
    valor = '89000'
  }
  if (action.id === '00003') {
    plan = 'Empresarial'
  }
  if (action.id === '00004') {
    plan = 'Empresarial'
  }
  if (action.id === '00005') {
    plan = 'Gratis'
    pruebaGratis = true
  }
  if (action.id === '00006') {
    plan = 'Pymes'
    valor = '1068000'
  }



  if (action.producto === 'ChatBot AutoGestionable') {
    producto = 'chatbot'
    if (pruebaGratis) {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        pruebaGratis: pruebaGratis,
        planActivo: true,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    else {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        pruebaGratis: false,
        planActivo: true,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    producto_adquirido = {
      productoChatbot: productoAdquirido
    };

  } else if (action.producto === 'Reconocimiento de Imágenes') {
    producto = 'OCR'
    if (pruebaGratis) {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        pruebaGratis: pruebaGratis,
        planActivo: true,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    else {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        pruebaGratis: false,
        planActivo: true,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    producto_adquirido = {
      productoOCR: productoAdquirido
    };
  }

  if (resp.producto) {
    if (resp.productoChatbot) {
      const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);

      // yield docRefCompany.set(producto_adquirido);
      yield docRefCompany.update(producto_adquirido)
        .then(() => {
          
          //updateIcon = true
          // console.log("updateICON", updateIcon)
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }
    if (resp.productoOCR) {
      const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);

      // yield docRefCompany.set(producto_adquirido);
      yield docRefCompany.update(producto_adquirido)
        .then(() => {
          
          //updateIcon = true
          // console.log("updateICON", updateIcon)
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }
  } else {
    const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);

    yield docRefCompany.update(producto_adquirido)
      .then(() => {
        
        //updateIcon = true
        // console.log("updateICON", updateIcon)
      })
      .catch((error) => {
        console.error('Error al actualizar el elemento:', error);
      });
  }



}

function* getTransaccion(action) {

  let company = false;
  let resp = false;
  let ref = action.referencia;
  let productoBD = false;
  let transaccionAprobada = false;
  let pruebaGratis = false;
  let plan = false;
  let valor = false;
  const user = getFromSession("currentUser");
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        company = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }


  if (!action.data) {
    if (action.id === '00001') {
      pruebaGratis = true
      plan = 'Gratis'
    }
    if (action.id === '00002') {
      plan = 'Pymes'
      valor = 89000
    }
    if (action.id === '00003') {
      plan = 'Empresarial'
    }
    if (action.id === '00004') {
      plan = 'Empresarial'
    }
    if (action.id === '00005') {
      plan = 'Gratis'
      pruebaGratis = true
    }
    if (action.id === '00006') {
      plan = 'Pymes'
      valor = 1068000
    }
    if (plan === 'Gratis') {
      yield adquirirProducto(action)
    }
    else {


      const headers = {
        "Authorization": 'Bearer prv_test_NuNWT77DIyXLrpRIt3T25pR2wXrfnCmn'
      }

      const urlDespliegue = `https://sandbox.wompi.co/v1/transactions?reference=${ref}`;

      resp = yield call(axios.get, urlDespliegue, { headers });

      if (resp.data.data.length === 1) {
        productoBD = resp.data.data[0]
        yield adquirirProducto(action)


        if (action.producto === 'ChatBot AutoGestionable') {

          const docRef = yield addDoc(collection(firebaseDatabase, `ventas/PRODUCTOS/CHATBOT/`), {
            producto: action.producto,
            company: company.cuenta,
            referencia: action.referencia,
            valor: valor,
            fecha: new Date()
          })
        } else {
          const docRef = yield addDoc(collection(firebaseDatabase, `ventas/PRODUCTOS/OCR/`), {
            producto: action.producto,
            company: company.cuenta,
            referencia: action.referencia,
            valor: valor,
            fecha: new Date()
          })
        }




        const messages = `<p>¡Hola!</p> 
      
      <p>Haz adquirido ${action.producto} en el plan ${plan},  por valor de ${valor}</p>`

        const body = {
          from_: "Compra productos Avanti <chatasesores@gmail.com>",
          to_: `${user.mail}`,
          subject_: `${action.producto} Avanti-it`,
          body: messages,
          user: "chatasesores@gmail.com",
          pass: "wdnwdcqgpdhwoskg"
        }
        const mail = yield call(axios.post, process.env.REACT_APP_CHATASESORES_SEND_MAIL, body, headers);

      }
    }
    yield put(getTransaccionSuccess(productoBD));
  }
}







export function* watchUsers() {

  yield takeLatest(UPDATE_PROFILE, updateProfile);
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(LOAD_MENU, loadMenuChatBot);
  yield takeLatest(UPLOAD_IMAGE, uploadImage);
  yield takeLatest(UPLOAD_INTENT, uploadIntent);
  yield takeLatest(CREATE_AGENT, createAgent);
  yield takeLatest(GET_BOTS, getBots);
  yield takeLatest(GET_INTENT, getIntents);
  yield takeLatest(DELETE_BOTS, deleteBots);
  yield takeLatest(DELETE_INTENT, deleteIntent);
  yield takeLatest(EDIT_INTENT, editIntent);
  yield takeLatest(CREATE_DIALOGFLOW_INTENT, createIntentDialogflow);
  yield takeLatest(DELETE_DIALOGFLOW_AGENT, deleteAgent);
  yield takeLatest(DELETE_DIALOGFLOW_INTENT, deleteIntentDialogflow);
  yield takeLatest(LIST_DIALOGFLOW_INTENT, listIntentDialogflow);
  yield takeLatest(UPDATE_DIALOGFLOW_INTENT, updateIntentDialogflow);
  yield takeLatest(UPLOAD_ICON, uploadIcon);
  yield takeLatest(REQUESTS_BACK_SUCCESS, requestBackend)
  yield takeLatest(NOMBRE_BOT, getNombreBot)
  yield takeLatest(BOT_ACTIVO, isBotEnabled)
  yield takeLatest(LIST_CLOUD_PROJECTS, validateProjects)
  yield takeLatest(FIN_SUSCRIBCION, finSuscribcion)
  yield takeLatest(GET_PLANES, getPlanes)
  yield takeLatest(GET_TRANSACCION, getTransaccion)








}