import { takeLatest, put, call } from "redux-saga/effects";
import { INIT_CHARGE_OCR_DOCUMENTS_PROCESS } from "./constants";
import { firebaseDatabase } from "../../../../controller/firebase";
import { chargeOcrDocumentsSuccess, saveUrlPdf } from "./actions";



function eliminarNull(obj) {
  return obj.filter((objeto) => {
    for (const propiedad in objeto) {
      if (objeto[propiedad] === null) {
        return false;
      }
    }
    return true;
  });
}

function* processDocumentOcr(action) {

  const idDoc = action.value;

  let resp = false;
  let dataDocument = false;
  let datosRender = false;
  let DataBD = false;
  const resultado = {};
  let company = false;
  let lote = false;
  let nameFile = "";
  let dataFile = false;

  try {
    yield firebaseDatabase.collectionGroup('Documentos').where('datosDocumento.id', '==', idDoc).orderBy('datosDocumento.fecha_cargue', 'desc').get().then(docs => {
      docs.forEach(docs => {
        resp = docs._delegate._key.path.segments;
        nameFile = docs.data().datosDocumento.name;
        dataFile = docs.data();

      });
    });
  } catch (error) {
    console.log('saga.mostrarDocumentosProcesados error1', error)
    throw error;
  }


  if (resp) {
    company = resp[6];
    lote = resp[8];
    yield put(saveUrlPdf(`${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${company}/productos/ocr/${nameFile}`));

    try {
      const documentRef = yield firebaseDatabase.collection('company').doc(`${company}`).collection('configuration').doc('DOCUMENT_FORM');

      // Obtén los datos del documento
      yield documentRef.get()
        .then((doc) => {
          if (doc.exists) {
            DataBD = doc.data().value;

          } else {
            console.log('El documento no existe');
          }
        })
    } catch (error) {
      console.log('saga.mostrarDocumentosProcesados error2', error)
      throw error;
    }
    let personasDocumentos = {}
    let arrayCheckbox = []
    let arrayText = []
    let arraysolo = []
    let arregloNew = {};
    let arregloNew2 = {};
    let arregloNew3 = {};
    let textoFull = false;
    try {

      if (dataFile) {

        personasDocumentos = yield declaracionJuramentadaOrden(dataFile);
        dataDocument = dataFile?.respuesta.pagina
        textoFull = dataFile?.respuesta.texto_Full


        let separador2 = '-'
        let separador = '/'
        dataDocument.forEach((objeto) => {

          if (objeto.etiqueta.includes(separador)) {
            const etiqueta = objeto.etiqueta.split("/")[0];
            const nombreEtiqueta = objeto.etiqueta.split("/")[1];

            if (etiqueta) {

              arregloNew[etiqueta] = arregloNew[etiqueta] || [];

              for (const propiedad in objeto.value) {
                if (propiedad === 'booleanValue') {
                  arregloNew[etiqueta].push({
                    [nombreEtiqueta]: objeto.value.booleanValue,
                    coordenadas: objeto.value.coordenadas
                  });
                }
              }

            }
          }
          else if (objeto.etiqueta.includes(separador2)) {
            const etiqueta = objeto.etiqueta.split("-")[0];
            if (etiqueta) {
              arregloNew2[etiqueta] = arregloNew2[etiqueta] || [];

              if (objeto.value.subdatos) {
                arregloNew2[etiqueta].push(
                  objeto.value.subdatos.map((subdato) => ({
                    [subdato.Type]: subdato.mentiontext,
                    coordenadas: subdato.coordenadas // Esta linea es para agregar las coordenadas a la respuesta. Debo quitar las coordenadas del front
                  }))
                );
              }
            }
          } else {
            const etiqueta = objeto.etiqueta;
            if (objeto.value.texto !== '') {
              // arregloNew3[etiqueta] = arregloNew3[etiqueta] || [];
              arregloNew3["Datos"] = arregloNew3["Datos"] || [];
              arregloNew3["Datos"].push({ [etiqueta]: objeto.value.texto, coordenadas: objeto.value.coordenadas }) // Aqui falta sacar el subdato de la coordenada
            }
          }
        });
        let recuadrosEtiqueta = [];
        if (arregloNew.Tipo_Id) {
          arregloNew.Tipo_Id = eliminarNull(arregloNew.Tipo_Id);


          arregloNew.Tipo_Id.forEach((element) => {
            const firstKey = Object.keys(element)[0];
            recuadrosEtiqueta.push({ Tipo_Id: firstKey, coordenadas: element.coordenadas });
          });
        }
        
        for (let prop in arregloNew2) {
          arregloNew2[prop].forEach((element) => {
            element.forEach((element2) => {
              recuadrosEtiqueta.push(element2);
            });
          });
        };

        arregloNew3.Datos.forEach((element) => {
          recuadrosEtiqueta.push(element);
        });

        resultado.checkbox = arregloNew;
        resultado.texto = arregloNew2;
        resultado.campoUnico = arregloNew3;
        resultado.textoFull = textoFull;
        resultado.recuadrosEtiqueta = recuadrosEtiqueta;
        resultado.personasDocumentos = personasDocumentos;

        if (DataBD) {

          if (DataBD.length !== 0) {
            DataBD.forEach((element) => {


              if (dataFile.respuesta?.tipoDeDocumento === element.tipoDocumento) {

                element.campos.forEach((e) => {


                  let arraySubdatos = [];
                  for (const propiedad in resultado.texto) {
                    if (typeof resultado.texto[propiedad] === "object") {
                      for (const propiedadSub in resultado.texto[propiedad]) {
                        // Imprime el elemento de la matriz
                        resultado.texto[propiedad][propiedadSub].forEach((data) => {
                          //// console.log("e.value, data", e.value, Object.keys(data).toString())
                          if (e.value === Object.keys(data).toString()) {
                            // // console.log("e.value, data", e.value, Object.keys(data))
                            //datosRender[propiedadSub] =  Object.values(data).toString();
                            // Agregamos una variable para almacenar los valores del arreglo
                            let valores = Object.values(data);

                            arraySubdatos.push(valores)

                          }
                        })
                      }
                    } else {
                      // Imprime el valor del objeto
                    }
                  }

                  datosRender[e.value] = arraySubdatos
                  for (const propiedad in resultado) {

                    if (typeof resultado[propiedad] === "object") {
                      for (const propiedadSub in resultado[propiedad]) {
                        if (e.value === propiedadSub) {
                          datosRender[propiedadSub] = resultado[propiedad][propiedadSub];
                        }

                      }
                    } else if (e.value === propiedad) {
                      datosRender[propiedad] = resultado[propiedad];
                    }
                  }

                });

              }
            })
          }
        }
        if (Object.keys(datosRender).length === 0) {
          datosRender = resultado;

        }


        yield put(chargeOcrDocumentsSuccess(datosRender))

      }

    } catch (error) {
      console.log('uploadImage', error)

      throw error;
    }
  }


}

async function declaracionJuramentadaOrden(action) {

  var paginaCruda = action.respuesta.respuesta_Cruda
  var declarante = {}
  var conyugue = {}
  var beneficiarios = []
  for (const entity in paginaCruda) {
    if (!paginaCruda[entity].properties.length > 0) {
      if (paginaCruda[entity].confidence > 0.1) {
        const tipos_doc = ["Tipo_Id/CC", "Tipo_Id/CE", "Tipo_Id/PA", "Tipo_Id/PE", "Tipo_Id/PPT", "Tipo_Id/TI"];
        if (tipos_doc.includes(paginaCruda[entity].type)) {
          var tipo_doc = paginaCruda[entity].type;
        }

        switch (paginaCruda[entity].type) {
          case "Nombre_Declarante":
            declarante.nombre = paginaCruda[entity].mentionText
            break;
          case "ID_Declarante":
            declarante.id = paginaCruda[entity].mentionText
            break;
          case tipo_doc:
            declarante.tipo_doc = paginaCruda[entity].type.split("/")[1]

          default:
            break;
        }
      }

    } else {

      var beneficiario = {}
      for await (const property of paginaCruda[entity].properties) {
        if (property.confidence > 0.1) {
          var llave = property.type
          const nombre_beneficiario = llave.match(/^Nombre_(Ben|Beneficiario|ben)$/) ? llave : undefined;
          const id_beneficiario = llave === "Id_Ben" || llave === "Id_Beneficiario" ? llave : undefined;
          const tipo_Id_Ben = llave === "Tipo_Id_Ben" || llave === "Tipo_ID_Ben" ? llave : undefined
          const parentesco = llave === "Parentesco" ? llave : undefined

          // se pone así, porque es el unico que no tiene campo parentesco

          if (paginaCruda[entity].type == "Union_Libre") {
            if (nombre_beneficiario) {
              conyugue.nombre = property.mentionText
              conyugue.parentesco = "Conyugue"
            }
            if (id_beneficiario) {
              conyugue.id = property.mentionText
            }
            if (tipo_Id_Ben) {
              conyugue.tipo_doc = property.mentionText
            }

          } else {

            if (nombre_beneficiario && !beneficiario.nombre) {
              beneficiario.nombre = property.mentionText
            }
            if (beneficiario.nombre && id_beneficiario) {
              beneficiario.id = property.mentionText
            }
            if (beneficiario.nombre && tipo_Id_Ben) {
              beneficiario.tipo_doc = property.mentionText
            }
            if (beneficiario.nombre && parentesco) {
              beneficiario.parentesco = property.mentionText
            }
            if (beneficiario.nombre && beneficiario.parentesco) {
              beneficiarios.push(beneficiario)
              beneficiario = {}
            }
          }
        }
      }

    }

  }
  beneficiarios.push(conyugue)

  beneficiarios = beneficiarios.filter((beneficiario) => {
    return Object.keys(beneficiario).length > 0;
  });

  var personas = {
    nombreDoc: action.datosDocumento.name,
    declarante: declarante,
    beneficiarios: beneficiarios,
  }
  return personas;

}



export function* watchUsers() {
  yield takeLatest(INIT_CHARGE_OCR_DOCUMENTS_PROCESS, processDocumentOcr);

}
