import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';


import LineChart from "../../components/Charts/lineChart";
import PieChart from "../../components/Charts/pieChart"
import { Card } from 'primereact/card';



import DataTable from './dataTable/DataTableInformes';

import { Toast } from 'primereact/toast';
import SearchAccordion from "../../components/searchaccordion/searchaccordion";


import {
    getSelectedForm,

    convsCerradas,
    totalConvsCerradas,
    tmpPromEsp,
    tmpPromResp,
    tmpPromConv,
    convsResueltas,
    convsCanal,
    getConvsCerradas,
    getConvsFiltradas,
    reporteHuecos,

    estadosDisplay, agentesDisplay,
    mensajes,
    verModalDescargarConversacion,
    dataConv,
    toastMensaje,
    toastDetail,
    getColumns,
    ocultarGrafica,
    convsAtendidasChatBot,
    getDataCalificacionEncuesta,
    getStateFilterButton,
    getConvsCerradasPaginadas,
    getConsultaPaginada,
    getlistaCompletaConvsPaginadas,
    getConvsToDownloadCsv,
    arrayCsvConversacionesCerradas,
    getManageSpinerState,
    getShowSnackBar,
    getSearchTableGeneral,

} from "./selectors";

import {
    UPDATE_FIELD_ATTRIBUTE,
    CONVS_CERRADAS,
    FILTRAR_BUSQUEDA,
    LANZAR_EVENTOS,
    SELECT_CONVERSATIONDETAIL,
    OCULTAR_MODAL_DESCARGAR,
    MODAL_DESCARGAR_DATOS,
    SINGLE_BUSQUEDA,
    LIMPIAR_FORM,
    GRAFICA_ENCUESTA_GENERAL,
    DATA_LINECHART,
    TMPESP_PROM,
    TMPRESP_PROM,
    TMPCONV_PROM,
    CONVS_RESUELTAS,
    CONVS_CANAL,
    CONVS_ATENDIDAS_CHATBOT,
    TOAST_MESSAGE,
    PAGINAR_CONVS_CERRADAS,
    DESCARGAR_CSV_CONVS_CERRADAS,
    MODAL_ESPERA_DATA,

} from "./constants";
import { Button } from "primereact/button";
import { CSVLink } from "react-csv";
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Alert } from "react-bootstrap";



class EstGeneralesComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            seleccionRango: false,
            iniciaComponente: true
        }

    }


    componentWillMount() {
        const {
            obtenerDatosGraficaEncuesta,
            obtenerDatosGraficaLineChart,
            obtenerTmpPromEspConvsCerradas,
            obtenerTmpPromRespConvsCerradas,
            obtenerTmpPromConv,
            obtenerConvsCerradasResueltas,
            obtenerCanalConvsCerradas,
            obtenerConvsAtentidasChatbot,
            obtenerConvsCerradas,
            activarSpinner


        } = this.props;
        obtenerDatosGraficaEncuesta()
        obtenerDatosGraficaLineChart()
        obtenerTmpPromEspConvsCerradas()
        obtenerTmpPromRespConvsCerradas()
        obtenerTmpPromConv()
        obtenerConvsCerradasResueltas()
        obtenerCanalConvsCerradas()
        obtenerConvsAtentidasChatbot()
        obtenerConvsCerradas()
        activarSpinner()
    }

    componentDidUpdate(prevProps) {
        const {
            obtenerDatosGraficaEncuesta,
            obtenerDatosGraficaLineChart,
            obtenerTmpPromEspConvsCerradas,
            obtenerTmpPromRespConvsCerradas,
            obtenerTmpPromConv,
            obtenerConvsCerradasResueltas,
            obtenerCanalConvsCerradas,
            obtenerConvsAtentidasChatbot,
            obtenerConvsCerradas,
            estadoBotonFiltrarRangos,
            showToast,
            activarSpinner

        } = this.props

        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;

        if (seleccionRango == anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            obtenerDatosGraficaEncuesta(seleccionRango)
            obtenerDatosGraficaLineChart(seleccionRango)
            obtenerTmpPromEspConvsCerradas(seleccionRango)
            obtenerTmpPromRespConvsCerradas(seleccionRango)
            obtenerTmpPromConv(seleccionRango)
            obtenerConvsCerradasResueltas(seleccionRango)
            obtenerCanalConvsCerradas(seleccionRango)
            obtenerConvsAtentidasChatbot(seleccionRango)
            obtenerConvsCerradas(seleccionRango)
            activarSpinner()
            this.setState({
                iniciaComponente: false
            })
        }


        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {
            this.setState({
                seleccionRango: seleccionRango
            })
            obtenerDatosGraficaEncuesta(seleccionRango)
            obtenerDatosGraficaLineChart(seleccionRango)
            obtenerTmpPromEspConvsCerradas(seleccionRango)
            obtenerTmpPromRespConvsCerradas(seleccionRango)
            obtenerTmpPromConv(seleccionRango)
            obtenerConvsCerradasResueltas(seleccionRango)
            obtenerCanalConvsCerradas(seleccionRango)
            obtenerConvsAtentidasChatbot(seleccionRango)
            obtenerConvsCerradas(seleccionRango)
            activarSpinner()

        }
        if (seleccionRango == 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {
            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }

                this.setState({
                    seleccionRango: rango
                })
                obtenerDatosGraficaEncuesta(rango)
                obtenerDatosGraficaLineChart(rango)
                obtenerTmpPromEspConvsCerradas(rango)
                obtenerTmpPromRespConvsCerradas(rango)
                obtenerTmpPromConv(rango)
                obtenerConvsCerradasResueltas(rango)
                obtenerCanalConvsCerradas(rango)
                obtenerConvsAtentidasChatbot(rango)
                obtenerConvsCerradas(rango)
                activarSpinner()
            } else {
                showToast()
            }

        }

        //console.log('THIS PROPS SELECTEDform ', this.props.selectedForm, this.props.estadoBotonFiltrarRangos);
    }
    
    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    spinnerGraficos() {
        return (

            <div className="loading-wrapperx">
                <LinearProgress />

                <p className="loading-text">
                    Cargando información ...

                </p>
            </div>

        );
    }

    render() {
        const {
            updateAttributes,
            selectedForm,
            convsCerradas,
            totalConvsCerradas,
            tmpPromEsp,
            tmpPromResp,
            tmpPromConv,
            convsResueltas,
            convsCanal,
            filtrarBusqueda,
            singleSearch,
            convsCerradasFiltered,
            totalConvs,
            estadosDisplay,
            agentesDisplay,
            selectConversationDetail,
            mensajes,
            desplegarEventosBuscador,
            abrirModalDescargarDatos,
            verModalDescargarConversacion,
            dataConv,
            cerrarModalDescargarDatos,
            obtenerConvsCerradas,
            limpiarForm,
            convsAtendidasChatbot,
            verGrafica,
            columnsTable,
            dataCalificacionEncuesta,
            paginarConvsCerradas,
            convsCerradasPaginadas,
            consultaPaginada, showToast,
            totalConvsFiltradas,
            descargarCSV,
            convsToDownloadCsv,
            searchTableGeneral,
            spinerState,
            showSnackBar, 
        } = this.props

        
        let convertirSegundos = (segundos) => {

            let respuesta;
            if (segundos > 1) {
                let valorMin = 604801;
                let intervalos = {
                    'semanas': 604800,
                    'dias': 86400,
                    'hrs': 3600,
                    'min': 60,
                    'seg': 1
                }
                for (let intervalo of Object.keys(intervalos)) {
                    let resultado = segundos / intervalos[intervalo];
                    if (resultado > 1 && resultado < valorMin) {
                        valorMin = Number((resultado).toFixed(2));
                        respuesta = valorMin.toString() + ' ' + intervalo;
                    }
                }
            } else if (segundos === 0 || segundos === "0") {
                respuesta = segundos?.toString() + ' seg';
            } else {
                respuesta = "Sin datos";
            }
            return respuesta;

        }
        let header = (textTooltip, j) => {
            return [
                <span key={j} className='informes' data-title={textTooltip}>
                    <img className="icon-table4 float-right" src="/assets/icons/info.png" />
                </span>
            ]
        }
        let tooltipLineChart = 'Gráfico que muestra el número de conversaciones cerradas por hora o por día';
        
        return (
            <React.Fragment>
                
                <div>
                    <Toast ref={(el) => this.toast = el} />   
                    <div className=" p-mt-3 p-p-3">
                        <div>
                            <h4 className="tituloComponente">ESTADISTICAS GENERALES</h4>
                        </div>
                        {/*  <div className='p-ml-2'><h3>Total Conversaciones Cerradas: {totalConvsCerradas}</h3></div> */}
                        <div className="p-grid mb-4 p-jc-center">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <Card className="cardNumeros" title='Total conversaciones cerradas'>
                                    <div className="p-text-bold">{totalConvsCerradas ? totalConvsCerradas : "Sin datos"}</div>
                                </Card>
                            </div>
                        </div>

                        <div className="p-grid">
                            <div className="p-col-9 margin-left">
                            {spinerState.lineChart ? this.spinnerGraficos() : ""}
                                <Card header={header(tooltipLineChart, 'j')} >
                                    <LineChart
                                        data={convsCerradas}></LineChart>
                                </Card>
                            </div>
                        </div>

                        <div className="downloadButton">
                            {convsCerradas?.arrayCsv?.length > 0 ?
                                <CSVLink data={convsCerradas?.arrayCsv}
                                    filename={`descargar-data-csvData.csv`}>
                                    <Button label="Descargar Data" icon="pi pi-file-excel"
                                        className="p-button-info p-ml-2"
                                    >

                                    </Button>
                                </CSVLink>

                                : ''
                            }
                        </div>
                        <div className="p-grid  p-mt-2 margin-left-pie">
                            <div className="p-col-5">
                            {spinerState.pie1 ? this.spinnerGraficos() : ""}
                                <PieChart data={convsResueltas}
                                ></PieChart>

                                <div className="downloadButton">

                                    {convsResueltas?.graficas?.length > 0 ?
                                        <CSVLink data={convsResueltas?.graficas}
                                            filename={`descargar-data-csvData.csv`}>
                                            <Button label="Descargar Data" icon="pi pi-file-excel"
                                                className="p-button-info p-ml-2"
                                            >

                                            </Button>
                                        </CSVLink>

                                        : ''
                                    }
                                </div>
                            </div>




                            <div className="p-col-5">
                            {spinerState.pie2 ? this.spinnerGraficos() : ""}
                                <PieChart data={convsCanal}
                                ></PieChart>

                                <div className="downloadButton">

                                    {convsCanal?.graficas?.length > 0 ?
                                        <CSVLink data={convsCanal?.graficas}
                                            filename={`descargar-data-csvData.csv`}>
                                            <Button label="Descargar Data" icon="pi pi-file-excel"
                                                className="p-button-info p-ml-2"
                                            >

                                            </Button>
                                        </CSVLink>

                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="p-grid  p-mt-2 margin-left-pie">
                            <div className="p-col-5">
                            {spinerState.pie3 ? this.spinnerGraficos() : ""}
                                <PieChart data={convsAtendidasChatbot}
                                ></PieChart>

                                <div className="downloadButton">

                                    {convsAtendidasChatbot?.graficas?.length > 0 ?
                                        <CSVLink data={convsAtendidasChatbot?.graficas}
                                            filename={`descargar-data-csvData.csv`}>
                                            <Button label="Descargar Data" icon="pi pi-file-excel"
                                                className="p-button-info p-ml-2"
                                            >

                                            </Button>
                                        </CSVLink>

                                        : ''
                                    }
                                </div>
                            </div>
                            <div className="p-col-5">
                            {spinerState.pie4 ? this.spinnerGraficos() : ""}
                                <PieChart data={dataCalificacionEncuesta}
                                ></PieChart>

                                <div className="downloadButton">

                                    {dataCalificacionEncuesta?.graficas?.length > 0 ?
                                        <CSVLink data={dataCalificacionEncuesta?.graficas}
                                            filename={`descargar-data-csvData.csv`}>
                                            <Button label="Descargar Data" icon="pi pi-file-excel"
                                                className="p-button-info p-ml-2"
                                            >

                                            </Button>
                                        </CSVLink>

                                        : ''
                                    }
                                </div>
                            </div>
                        </div>

                        <SearchAccordion
                            selectedForm={selectedForm} updateAttributes={updateAttributes}
                            filtrarBusqueda={filtrarBusqueda} optionsEstados={estadosDisplay} lanzarEventos={desplegarEventosBuscador}
                            optionsAgentes={agentesDisplay} getConversations={obtenerConvsCerradas} limpiarForm={limpiarForm}
                            showToast={showToast} totalConvsFiltradas={totalConvsFiltradas}
                        ></SearchAccordion>

                        <div className="p-mt-3">

                            {convsCerradasFiltered && (<DataTable
                                selectItem={selectConversationDetail} abrirModalDescargarDatos={abrirModalDescargarDatos}
                                data={convsCerradasFiltered} itemsModal={mensajes} globalFilter={singleSearch}
                                verModalDescargarConversacion={verModalDescargarConversacion} dataConv={dataConv}
                                cerrarModalDescargarDatos={cerrarModalDescargarDatos}
                                columns={columnsTable} verGrafica={verGrafica} paginarConvsCerradas={paginarConvsCerradas}
                                selectedForm={selectedForm} seleccionRangoBusqueda={searchTableGeneral}
                                convsCerradasPaginadas={convsCerradasPaginadas} consultaPaginada={consultaPaginada}
                                totalConvsFiltradas={false} descargarCSV={descargarCSV} convsToDownloadCsv={convsToDownloadCsv}

                            >
                            </DataTable>)}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
const mapStateToProps = state => {
    return {

        toastMensaje: toastMensaje(state),
        toastDetail: toastDetail(state),
        selectedForm: getSelectedForm(state),
        convsAtendidasChatbot: convsAtendidasChatBot(state),
        convsCerradas: convsCerradas(state),
        totalConvsCerradas: totalConvsCerradas(state),
        tmpPromEsp: tmpPromEsp(state),
        tmpPromResp: tmpPromResp(state),
        tmpPromConv: tmpPromConv(state),
        convsResueltas: convsResueltas(state),
        convsCanal: convsCanal(state),
        convsCerradasFiltered: getConvsFiltradas(state),
        estadosDisplay: estadosDisplay(state),
        agentesDisplay: agentesDisplay(state),
        mensajes: mensajes(state),
        verModalDescargarConversacion: verModalDescargarConversacion(state),
        dataConv: dataConv(state),
        verGrafica: ocultarGrafica(state),
        columnsTable: getColumns(state),
        dataCalificacionEncuesta: getDataCalificacionEncuesta(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        convsCerradasPaginadas: getConvsCerradasPaginadas(state),
        consultaPaginada: getConsultaPaginada(state),
        totalConvsFiltradas: getlistaCompletaConvsPaginadas(state),
        convsToDownloadCsv: getConvsToDownloadCsv(state),
        searchTableGeneral:getSearchTableGeneral(state),
        spinerState: getManageSpinerState(state),
        showSnackBar: getShowSnackBar(state),
        


    }

}
const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        limpiarForm: (value) => dispatch({ type: LIMPIAR_FORM, value }),
        obtenerConvsCerradas: (value) => dispatch({ type: CONVS_CERRADAS, value }),
        desplegarEventosBuscador: (value) => dispatch({ type: LANZAR_EVENTOS, value: 1 }),
        filtrarBusqueda: (value) => dispatch({ type: FILTRAR_BUSQUEDA, value }),
        selectConversationDetail: (value) => dispatch({ type: SELECT_CONVERSATIONDETAIL, value }),
        cerrarModalDescargarDatos: () => dispatch({ type: OCULTAR_MODAL_DESCARGAR, payload: 1 }),
        abrirModalDescargarDatos: (value) => dispatch({ type: MODAL_DESCARGAR_DATOS, value }),
        singleSearch: (value) => dispatch({ type: SINGLE_BUSQUEDA, value }),
        obtenerDatosGraficaLineChart: (value) => dispatch({ type: DATA_LINECHART, value }),
        obtenerTmpPromEspConvsCerradas: (value) => dispatch({ type: TMPESP_PROM, value }),
        obtenerTmpPromRespConvsCerradas: (value) => dispatch({ type: TMPRESP_PROM, value }),
        obtenerTmpPromConv: (value) => dispatch({ type: TMPCONV_PROM, value }),
        obtenerConvsCerradasResueltas: (value) => dispatch({ type: CONVS_RESUELTAS, value }),
        obtenerDatosGraficaEncuesta: (value) => dispatch({ type: GRAFICA_ENCUESTA_GENERAL, value }),
        obtenerCanalConvsCerradas: (value) => dispatch({ type: CONVS_CANAL, value }),
        obtenerConvsAtentidasChatbot: (value) => dispatch({ type: CONVS_ATENDIDAS_CHATBOT, value }),
        showToast: (value) => dispatch({ type: TOAST_MESSAGE, value }),
        paginarConvsCerradas: (value) => dispatch({ type: PAGINAR_CONVS_CERRADAS, value }),
        descargarCSV: (value) => dispatch({ type: DESCARGAR_CSV_CONVS_CERRADAS, value }),
        activarSpinner: (value) => dispatch({ type: MODAL_ESPERA_DATA, value })

    }

}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstGeneralesComponent);