import HomePage from './pages/home';
import AuthPage from './pages/authentication';

import {
    faHome,
    faUsers,
    faUser,
    faNewspaper,
    faCogs,
    faRobot,
    faComment,
    faShuttleVan, faTimes,
    faFolderOpen,
    faCloudUploadAlt,
    faIdCard,
    faInfo,


} from "@fortawesome/free-solid-svg-icons";
import AgentPanelPage from './pages/agentPanel';
import SupervisorPanelPage from './pages/supervisorPanel';
import InformesPanelPage from './pages/informesPanel';
import AdminPanelPage from './pages/adminPanel';
import FlujoMensajesPage from './pages/flujoMensajes';
import ComponentBienvenida from './pages/bienvenida';
import PlanesPage from './pages/planes';
import ComponentCrearChaBot from './pages/autogestionable';
import ComponentImageOCR from './pages/imageOCR';
import misDocumentosPage from './pages/imageOCR/misDocumentos';
import LoteOCR from './pages/imageOCR/lotes';
import LotePendienteOCR from './pages/imageOCR/lotes pendientes';



import ComponentPerfilUsuario from './pages/perfilUsuario';
import ComponentBienvenidaCompra from './pages/planes/bienvenidaCompra';



import EnviosMasivosPage from './pages/enviosMasivos';
import InicioPage from './pages/inicio';
import AutobotsPage from './pages/autogestionable';
import crucePage from './pages/imageOCR/cruce';
import adminPage from './pages/imageOCR/admin';
import estadisiticasPage from './pages/imageOCR/estadisticas';
import PdfViewer from './pages/imageOCR/misDocumentos/ocrTable';

export const GET_USERS_API = 'users/';
export const GET_USER_API = 'users/2';
export const PATH_API = 'https://reqres.in/api/';
export const COLLECTION_USER_NAME = 'users';
export const paths = [
    { path: '/home/:company?', component: HomePage },
    { path: '/auth/:company?', component: AuthPage },
    { path: '/agentPanel/:company?', component: AgentPanelPage, key: 1 },
    { path: '/supervisorPanel/:company?', component: SupervisorPanelPage },
    { path: '/informesPanel/:company?', component: InformesPanelPage },
    { path: '/enviosMasivos/:company?', component: EnviosMasivosPage },
    { path: '/administradorPanel/:company?', component: AdminPanelPage },
    { path: '/flujoMensajes/:company?', component: FlujoMensajesPage },
    { path: '/inicio/:company?', component: InicioPage },
    { path: '/autobot/:chatbot?', component: AutobotsPage },
    { path: '/planes/:chatbot?', component: PlanesPage },
    { path: '/bienvenida/:company?', component: ComponentBienvenida },
    { path: '/bienvenidaCompra/:company?', component: ComponentBienvenidaCompra },
    { path: '/imageOCR/:company?', component: ComponentImageOCR },
    { path: '/lotesOCR/:company?', component: LoteOCR },
    { path: '/pendientesOCR/:company?', component: LotePendienteOCR },
    { path: '/misDocumentos/:company?', component: misDocumentosPage },
    { path: '/cruce/:company?', component: crucePage },
    { path: '/perfil/:company?', component: ComponentPerfilUsuario },
    { path: '/crearbot/:company?', component: ComponentCrearChaBot },
    { path: '/crearbot/:company?', component: ComponentCrearChaBot },
    { path: '/admin/:company?', component: adminPage },
    { path: '/estadisticas/:company?', component: estadisiticasPage },
    { path: '/documentOcr/:company?', component: PdfViewer },
];

export const menuItems = [
    {
        path: '/agentPanel',
        name: 'Conversaciones',
        css: faHome,
        key: 1,
        label: 'Conversaciones',
        roles: ['AGENTE', 'SUPERVISOR']
    },
    {
        path: '/supervisorPanel',
        name: 'Tiempo Real',
        css: faUsers,
        key: 2,
        label: 'Supervisión en tiempo real',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },
    {
        path: '/informesPanel',
        name: 'Informes',
        css: faNewspaper,
        key: 3,
        label: 'Informes y estadísticas',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },
    {
        path: '/enviosMasivos',
        name: 'Envios Masivos',
        css: faNewspaper,
        key: 4,
        label: 'Envios Masivos',
        roles: ['SUPERVISOR'],
        configuration: true
    },
    {
        path: '/administradorPanel',
        name: 'Administrador',
        css: faCogs,
        key: 5,
        label: 'Administración',
        roles: ['SUPERVISOR']
    },
    {
        path: 'flujoMensajes',
        name: 'FlujoMensajes',
        css: faComment,
        key: 9,
        label: 'Base de Conocimiento',
        external: true,
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },
    {
        path: '/documentOcr',
        name: 'documentOcr',
        css: faComment,
        key: 6,
        label: 'Documentos Ocr',
        external: true,
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },

]
export const menuItemsChatBot = [
    {
        path: '/autobot',
        name: 'Crear Preguntas',
        css: faRobot,
        key: 1,
        label: 'Crear Preguntas',
        roles: ['AGENTE', 'SUPERVISOR']
    },
    {
        path: '/agentPanel',
        name: 'Conversaciones',
        css: faHome,
        key: 3,
        label: 'Conversaciones',
        roles: ['AGENTE', 'SUPERVISOR']
    },
    {
        path: '/supervisorPanel',
        name: 'Tiempo Real',
        css: faUsers,
        key: 4,
        label: 'Supervisión en tiempo real',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },
    {
        path: '/informesPanel',
        name: 'Informes',
        css: faNewspaper,
        key: 5,
        label: 'Informes y estadísticas',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },
    {
        path: '/enviosMasivos',
        name: 'Envios Masivos',
        css: faNewspaper,
        key: 6,
        label: 'Envios Masivos',
        roles: ['SUPERVISOR'],
        configuration: true
    },
    {
        path: '/administradorPanel',
        name: 'Administrador',
        css: faCogs,
        key: 7,
        label: 'Administración',
        roles: ['SUPERVISOR']
    },
    {
        path: '/flujoMensajes',
        name: 'FlujoMensajes',
        css: faComment,
        key: 8,
        label: 'Base de Conocimiento',
        external: true,
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },


]
export const menuItemsChatBotGratis = [
    {
        path: '/autobot',
        name: 'Crear Preguntas',
        css: faRobot,
        key: 1,
        label: 'Crear Preguntas',
        roles: ['AGENTE', 'SUPERVISOR']
    },
    {
        path: '/agentPanel',
        name: 'Conversaciones',
        css: faHome,
        key: 3,
        label: 'Conversaciones',
        roles: ['AGENTE', 'SUPERVISOR']
    },
    {
        path: '/informesPanel',
        name: 'Informes',
        css: faNewspaper,
        key: 5,
        label: 'Informes y estadísticas',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    }

]



export const menuItemsOCR = [
    {
        path: '/imageOCR',
        name: 'Cargar Documentos',
        css: faCloudUploadAlt,
        key: 1,
        label: 'Cargar Documentos',
        roles: ['AGENTE', 'SUPERVISOR']
    },
    {
        path: '/misDocumentos',
        name: 'Mis Documentos',
        css: faFolderOpen,
        key: 2,
        label: 'Mis Documentos',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },
    {
        path: '/',
        name: 'Cruzar',
        css: faTimes,
        key: 3,
        label: 'Cruzar',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },
    {
        path: '/',
        name: 'Administrar',
        css: faCogs,
        key: 4,
        label: 'Administrar',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },
    {
        path: '/estadisticas',
        name: 'Estadisticas',
        css: faNewspaper,
        key: 5,
        label: 'Estadisticas',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },
    {
        path: '/',
        name: 'Varios',
        css: faComment,
        key: 6,
        label: 'Varios',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },

]
export const menuItemsPerfil = [
    {
        path: '/',
        name: 'Cambiar plan',
        css: faIdCard,
        key: 1,
        label: 'Cambiar plan',
        roles: ['AGENTE', 'SUPERVISOR']
    },
    {
        path: '/',
        name: 'Mi perfil',
        css: faUser,
        key: 2,
        label: 'Mi perfil',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },
    {
        path: '/inicio',
        name: 'Página principal',
        css: faInfo,
        key: 3,
        label: 'Página principal',
        roles: ['SUPERVISOR', 'GESTOR_CONOCIMIENTO']
    },

]



export const URL_DIALOG_FLOW = 'https://dialogflow.cloud.google.com/#/agent/DIALOG_PROJECT/intents';
export const URL_AUDIT_LOG = 'https://console.cloud.google.com/logs/query?hl=es-419&project=PROJECTURL';

export const main = AuthPage;