import { takeLatest, put, select } from "redux-saga/effects";
import { reset, change, initialize } from "redux-form";

import { consultarEmpresa, agenteByCompany } from '../../controller/company'
import { consultaProperty } from '../../controller/configuration'
import {
  LOG_USER, SIGN_USER, AUTH_LOAD_PARAMS, SEND_EMAIL
} from './constants';

import {
  authSuccess,
  logSuccess,
  authError,
  loadParamsError, loadParamsSuccess, returnHome
} from './actions';

import { getFormRecovery } from './selectors';
import { firebaseAuthentication, firebaseField } from "../../controller/firebase";
import { storeInSession } from "../../controller/session";
import { firebaseDatabase } from "../../controller/firebase";


/**
 * El proceso de login tiene varios pasos:
 * 1. Se consulta que la cuenta de empresa exista, se fija en el localStorage el company
 * 2. si la emprsa existe, se consulta si el usuario (con el login indicado) pertenece a la empresa
 * 3. se valida el usuario / clave contra la autenticacionPropTypes.
 * 4. se crea el connected user bajo el company, se valida que estuviera conectado y se cierra otras conexiones
 * @param {} action 
 */
function* logUser(action) {
  const { value } = action;
  const flag = false;
  const { company, login, password } = value ? value : {};
  let preguntasEncuesta;
  let response;
  try {
    //1. Autenticar el usuario
    try {
      response = yield firebaseAuthentication.signInWithEmailAndPassword(login, password);
    } catch (err) {
      yield put(authError({ message: 'ERROR: usuario / clave inválidos' }));
      return;
    }

    if (!response) {
      yield put(authError({ message: 'ERROR: usuario / clave inválidos' }));
      return;
    }
    const userResponse = response.user;
    const { _delegate: { emailVerified, email, uid } } = userResponse;

   

    //2. validar si existe la empresa:
    const emp = yield consultarEmpresa(company);
    const dataCompany = emp !== false && emp.data()
    //console.log("company_id.-**", emp.data().cuenta)
    //console.log("emailVerified", emailVerified)
    //console.log("nuevaCuenta", dataCompany.cuenta_nueva)
    
    if(dataCompany && dataCompany.cuenta_nueva){
      //console.log("VAlidar si el correo existe")
      if (!emailVerified) {
        //console.log("correo no validado") 
        sessionStorage.removeItem('inSession')
        yield put(authError({ message: `ERROR: Apreciado usuario por favor ingrese al correo ${email} y valide su cuenta` }));
        return;
      }
    }

    if (!emp) {
      //console.log('error de que no existe cuenta')
      yield put(authError({ message: 'No existe la cuenta de empresa indicada' }));
      return;
    }
    const { cuenta_activa } = emp.data()


    const company_id = emp.id
    storeInSession('company_id', company_id);
    storeInSession('company', JSON.stringify(emp.data()));

    storeInSession('inSession', '1');
    //3. validamos si el usuario existe en la compañia:
    const { user } = response;
    let usuario = yield agenteByCompany(company_id, user.uid);
    //console.log("usuario**", usuario)
    
    //console.log("usuario cuenta_activa**", cuenta_activa)
    if (cuenta_activa !== 'Si') {
      emp.ref.update({ fecha_activacion: firebaseField.serverTimestamp(), cuenta_activa: 'Si' });
      usuario.ref.update({ fecha_activacion: firebaseField.serverTimestamp() });
    }
    
    if (!usuario) {
      yield put(authError({ message: 'ERROR: El usuario no pertenece a la empresa indicada' }));
      return;
    }
    const { rol } = usuario.data();

    //si la cuenta está desactivada la activa la primera vez que inicia sesión

    //se pone la fecha de login:
    usuario.ref.update({ last_login: new Date() });
    //obtengo la configuracion de max_conversations para cualquier agente:
    let maxConvs = yield consultaProperty(company_id, 'MAX_CONVERSACIONES_AGENTE');

    let isSurveyEnabled = yield consultaProperty(company_id, 'SURVEY_ENABLED');

    if (isSurveyEnabled) {
      preguntasEncuesta = yield consultaProperty(company_id, 'ENCUESTA');
    }
    storeInSession('configurationVariables', JSON.stringify({ isSurveyEnabled, preguntasEncuesta }))

    //Se agrega a la coleccion de "connectedUsers"
    const docRef = firebaseDatabase.collection(`company/${company_id}/connectedUsers`).doc();
    let connectedUser = {
      fecha_ini: new Date(),
      estado: rol === 'AGENTE' ? 1 : 0, //solo el agente se deja conectado en 1 (conectado), los demas en cero

      conv_atendidas: 0,
      conv_maximas: maxConvs ? maxConvs : 0,
      agente: { ...usuario.data(), idDoc: docRef.id },
      agenteId: usuario.id
    }
    const dataUser = yield obtenerInfoAgente(company_id, usuario.data().uid);
    //console.log('dataUser', dataUser);

    if (dataUser && dataUser.idDoc.length > 0) {
      yield actualizarDataUser(company_id, dataUser);
    }


    yield actualizarAgenteEnConversacion(company_id, usuario.data().uid, connectedUser.agente);
    docRef.set(connectedUser);

    storeInSession('currentUser', JSON.stringify(usuario.data()))
    storeInSession('currentUserId', usuario.id)
    storeInSession('connectedUser', docRef.id)

    yield put(logSuccess({ message: 'Session started' }));

  } catch (error) {
    console.error('error saga authenticacion:', error)
    yield put(authError(error));

  }
}

function* readFields(action) {
  const { value } = action;
  const { company, login, password } = value ? value : {};

  // console.log('logUser.readFields', action);
}






function actualizarDataUser(company, { idDoc, estado }) {

  let fechaActual = new Date();
  try {
    if (estado[0] === 2) {

    } else {
      idDoc.forEach(id => {
        firebaseDatabase.collection(`company/${company}/connectedUsers`).doc(id).update({
          estado: 2,
          fecha_fin: fechaActual,
        })
      })
    }
  } catch (error) {
    console.error('saga.actualizarDataUser', error);
    throw error;

  }

}

function* newAccount(action) {
  let cuentaNueva = false
  // console.log("newAccount", action)
  try {

    const docRef = firebaseDatabase.collection(`/company/`).doc(action);
    const cuentaNuevaResp = yield docRef.get();
  
    if (cuentaNuevaResp.exists) {
      cuentaNueva = cuentaNuevaResp.data().cuenta_nueva;
      return cuentaNueva
    } else {
      return cuentaNueva
    }
  } catch (e) {
    console.log("erro obteniendo el campo cuentaNueva", e)
  }
}



async function obtenerInfoAgente(company, uid) {
  try {
    let idDoc = [];
    //let convsActuales = [];
    let estado = [];

    await firebaseDatabase.collection(`company/${company}/connectedUsers`).where('estado', 'in', [0, 1, 2, 3])
      .where('agente.uid', '==', uid).orderBy('fecha_ini', 'desc').limit(1).get().then(results => {
        results.forEach(doc => {
          idDoc.push(doc.data().agente.idDoc);
          //convsActuales.push(doc.data().conv_actuales);
          estado.push(doc.data().estado);
        });
      })

    return { idDoc, estado };

  } catch (error) {
    console.error('saga.obtenerConversacionesActuales', error);
    throw error;
  }
}

function actualizarAgenteEnConversacion(company, uidAgente, newAgent) {

  try {
    firebaseDatabase.collection(`company/${company}/conversations`).where('estado', 'in', [1, 2, 3, 4, 5, 6, 7, 8, 9]).
      where('agente.uid', '==', uidAgente).get().then(results => {
        results.forEach(conv => {
          let idDoc = conv.ref.id
          // console.log(' OBTUVO EL idDoc', idDoc);
          firebaseDatabase.collection(`company/${company}/conversations`).doc(idDoc).update({
            agente: newAgent
          })
        });
      })
  } catch (error) {
    console.error('saga.actualizarAgenteEnConversacion', error);
    throw error;
  }
}

function* signUser(action) {
  const { value } = action;
  const { login, password } = value ? value : {};

  try {
    const response = yield firebaseAuthentication.createUserWithEmailAndPassword(login, password);
    yield put(authSuccess({ message: 'User created succesfully' }));

  } catch (error) {
    yield put(authError(error));
  }
}


function* loadDataCompany(action) {
  const { value } = action;

  try {
    if (value.company) {
      const response = yield consultarEmpresa(value.company)
      yield put(loadParamsSuccess(response.data().logo));
    } else {
      yield put(loadParamsSuccess(false));
    }
  } catch (error) {
    yield put(loadParamsError(error));
  }
}

function* enviarEmail(action) {
  const data = yield select(getFormRecovery);
  var ban1 = false;
  var ban2 = false;

  if (data?.sectionRecovery && data?.sectionRecovery?.emailRecovery) {
    if (!data.sectionRecovery["emailRecovery"]) {
      ban1 = true;
    }

    if (
      data.sectionRecovery.emailRecovery &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.sectionRecovery.emailRecovery)
    ) {
      ban2 = true;
    }
  } else {
    ban1 = true;
  }
  if (!ban1) {
    if (!ban2) {
      try {
        yield firebaseAuthentication.languageCode = "es";
        const res = yield firebaseAuthentication.sendPasswordResetEmail(data.sectionRecovery.emailRecovery);
        if (res || res === undefined) {
          yield put(change("authFormRecovery", 'sectionRecovery.emailRecovery', ''));
          yield put(returnHome(true))
        }
      } catch (error) {
        console.log(error);
        yield put(authError({ message: 'ERROR: No se pudo procesar su petición, intentelo nuevamente' }));
      }

    } else {
      yield put(authError({ message: 'ERROR: El correo electrónico es un correo invalido' }));
    }
  } else {
    yield put(authError({ message: 'ERROR: El campo correo electrónico, no puede estar vacio' }));
  }


}

export function* watchUsers() {
  yield takeLatest(LOG_USER, logUser);
  yield takeLatest(SEND_EMAIL, enviarEmail);
  yield takeLatest(SIGN_USER, signUser);
  yield takeLatest(AUTH_LOAD_PARAMS, loadDataCompany);
}
