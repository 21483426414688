import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';


import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

export default class LineChartDemo extends Component {

    basicData = {};

    constructor(props) {
        super(props);
        this.options = this.getLightTheme(true);
    }

    componentDidUpdate() {
        const { data } = this.props

        this.options = this.getLightTheme(this.props.data.graficoGrande);
    }

    getLightTheme(fullCaracteristicas) {
        let basicOptions = {
            legend: {
                display: fullCaracteristicas
            },
            scales: {
                x: {
                    ticks: {
                        fontColor: '#495057',
                        display: fullCaracteristicas
                    },
                    grid: {
                        display: fullCaracteristicas
                    }

                },
                y: {
                    ticks: {
                        fontColor: '#495057',
                        display: fullCaracteristicas
                    },
                    grid: {
                        display: fullCaracteristicas
                    }

                }
            }
        };
        return {
            basicOptions
        }

    }

    render() {
        const { basicOptions } = this.options;
        const { data } = this.props;
        let colorLine = data && data.color ? data.color : '#2adb59'
        let colorBackground = data && data.colorBackground ? data.colorBackground : '#f7ba7e';
        let labelData = data && data.dataLabel ? data.dataLabel : '';
        const colores = ["#FFA726", "#42A5F5", "#27AE60", "#2980B9", "#FF5733", "#E91E63", "#00BCD4",
            "#FDD835", "#795548", "#1E88E5"];


        let dataSet = []
        
       
        if (data && data.ejeY && typeof data.ejeY[0] == "object") {
            let copia = [...colores];
            data.ejeY.forEach((eje, index) => {

                let posicion = Math.floor(Math.random() * copia.length)
                let color = copia[posicion]
                copia.splice(posicion, 1);
                let data1 = {
                    label: data.labels[index],
                    data: eje,
                    fill: true,
                    borderColor: color,
                  
                }

                dataSet.push(data1)

            });

            this.basicData = {
                labels: data ? data.ejeX : [],
                datasets: dataSet
            };

        } else {

            this.basicData = {
                labels: data ? data.ejeX : [],
                datasets: [
                    {
                        label: labelData,
                        data: data ? data.ejeY : [],
                        fill: true,
                        borderColor: colorLine,
                        backgroundColor: colorBackground,
                    },
                  
                ]
            };

        }


        return (

            <Chart type="line" data={this.basicData} options={basicOptions} />

        )
    }

}

