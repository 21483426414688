import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, menuItemsOCR, PATH_API } from '../../../Constants';
import { firebaseDatabase, firebaseField, storage} from "../../../controller/firebase";
import moment from "moment";

import {
  COMPANY_DATA,
  DOCUMENT_FORM,
  DOWNLOAD_DOC,
  GET_DOC_FALL,
  GET_DOC_PROC,
  GET_OCR_DOC,
  GET_USERS, GUARDAR_DOCUMENT_FORM, LOAD_MENU, SHOW_DOC_PROC,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
  companyDataSuccess,
  getOCRDocumentosSuccess,
  documentosProcesadosSuccess,
  documentosFallidosSuccess,
  downloadDocuSuccess,
  showDocumentosProcesadosSuccess,
  traerConfFormDocumentosSuccess,
  traerConfFormDocumentosError,
  guardarFormularioDocumentosSuccess,
} from './actions';

import { getFromSession } from "../../../controller/session";
import { consultaProperty } from "../../../controller/configuration";

function* getUsers() {
  const urlUsers = `${PATH_API}${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
    method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if (fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const { data } = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }

  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}

function* loadMenuImageOCR(action) {
  let list = [];
  let company = getFromSession("company_id");
  //let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItemsOCR.forEach((menu) => {
    if (menu.roles.includes(rol)) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}

function* companyData(action) {
 
  const user = getFromSession("currentUser");
  let resp = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  yield put(companyDataSuccess(resp));
  return resp
}



function* getOCRDocumentos(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let respuesta = [];
  try {
    yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get().then(docs => {
        docs.forEach(doc => {
          resp = doc.data();
        });
      });


    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      .get().then(docs => {
        docs.forEach(doc => {
          const documentData = doc.data();
          if (documentData.estado === 1) {
            const idDoc = doc.id
            documentos.push(idDoc);
          }
        });
      });
    for (const id of documentos) {
      yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .get().then(docs => {
          docs.forEach(doc => {
            const documentData = doc.data();
            //console.log("documentData", documentData)
            const dataDocument = {
              lote: id,
              fecha: documentData.fecha_cargue ? moment(documentData.fecha.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
              name: documentData.datosDocumento.name,
              size: documentData.datosDocumento.tamano,
              id: doc.id,
              resumen: documentData.resumen ? documentData.resumen : ''
            }
            respuesta.push(dataDocument);
          });
        });
    }



  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  console.log("documentos", respuesta)

  yield put(getOCRDocumentosSuccess(respuesta));
  return respuesta
}

function* getDocumentosProc(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let respuesta = [];
  try {
    yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get().then(docs => {
        docs.forEach(doc => {
          resp = doc.data();
        });
      });


    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      .get().then(docs => {
        docs.forEach(doc => {
          const documentData = doc.data();
          if (documentData.estado === 3) {
            const idDoc = doc.id
            documentos.push(idDoc);
          }
        });
      });
    for (const id of documentos) {
      yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .get().then(docs => {
          docs.forEach(doc => {
            const documentData = doc.data();
            //console.log("documentData *********", documentData)
            if (documentData.datosDocumento.estado === 1) {
              const dataDocument = {
                lote: id,
                fecha: documentData.fecha_cargue ? moment(documentData.fecha.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                name: documentData.datosDocumento.name,
                size: documentData.datosDocumento.tamano,
                id: doc.id,
                resumen: documentData.resumen ? documentData.resumen : ''
              }
              respuesta.push(dataDocument);
            }
          });
        });
    }



  } catch (error) {
    //console.log('intents', error)
    throw error;
  }

  console.log("respuesta", respuesta);
  yield put(documentosProcesadosSuccess(respuesta));
}


function* getDocumentosFallidos(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let respuesta = [];
  try {
    yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get().then(docs => {
        docs.forEach(doc => {
          resp = doc.data();
        });
      });


    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      .get().then(docs => {
        docs.forEach(doc => {
          const documentData = doc.data();
          if (documentData.estado === 3) {
            const idDoc = doc.id
            documentos.push(idDoc);
          }
        });
      });
      for (const id of documentos) {
        yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
          .get().then(docs => {
            docs.forEach(doc => {
              const documentData = doc.data();
              if(documentData.datosDocumento.estado === 2){
                const dataDocument = {
                  lote: id,
                  fecha: documentData.fecha_cargue ? moment(documentData.fecha.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                  name: documentData.datosDocumento.name,
                  size: documentData.datosDocumento.tamano,
                  id: doc.id,
                  resumen: documentData.resumen ? documentData.resumen : ''
                }
                respuesta.push(dataDocument);
              }
            });
          });
      }
  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  //console.log("documentos fallidos misDoc", respuesta)

  yield put(documentosFallidosSuccess(respuesta));
}

function* mostrarDocumentosProcesados(action) {
  console.log("ACTION MOSTRAR DOCUMENTOS", action)
  let user = getFromSession("currentUser");
  let resp = false
  let dataDocument = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

 

  try {
    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`).get().then(docs => {
      docs.forEach(doc => {
        if(doc.data().datosDocumento.name === action.value.name){
          console.log('datosDocumento', doc.data().Documento_Ejemplo)
          dataDocument = doc.data().Documento_Ejemplo2
        }
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  yield put(showDocumentosProcesadosSuccess(dataDocument))
}

function* downloadDoc(action) {
  console.log("URL PDF SAGA", action)
  try {
    // Obtén una referencia al almacenamiento de Firebase
    const storageRef = storage.refFromURL(action.value);

    // Obtén la URL de descarga del documento
    const downloadUrl = yield storageRef.getDownloadURL();

    // Crea un enlace temporal para descargar el documento
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'documento.pdf'; // Establece el nombre del archivo
    link.target = '_blank'; // Abre el enlace en una nueva pestaña

    // Simula un clic en el enlace para iniciar la descarga
    link.click();
  } catch (error) {
    console.error('Error al descargar el documento:', error);
  }

  yield put (downloadDocuSuccess())
}

function sortObjectByKeys(o) {
  return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
}

export function* traerConfFormDocumentos() {
  try {
    const company = getFromSession("company_id");
    let confClienteForm = yield consultaProperty(company, 'DOCUMENT_FORM');
    let clientFormEnable = yield consultaProperty(company, 'DOCUMENT_FORM_ENABLED');
    let listaPreguntas = [];
    if (clientFormEnable) {
      let dataOrdenada = sortObjectByKeys(confClienteForm)
      for (let clave in dataOrdenada) {
        let pregunta = dataOrdenada[clave]
        listaPreguntas.push(pregunta)
      }
    }

    yield put(traerConfFormDocumentosSuccess({ listaPreguntas, clientFormEnable }))
  } catch (error) {
    console.error('saga.traerConfFormClientWeb', error);
    yield put(traerConfFormDocumentosError(error));
    throw error

  }
}

export function* guardarFormDocumentos(formValues, company, document) {
	try {
		let resp = false

		yield firebaseDatabase.collection(`company/${company}/configuration`).doc(`${document}`).update({
			value: formValues
		}).then(() => {
			console.log('Document succesfully updated');
			resp = true

		}).catch((error) => {
			console.error("Error", error);
			resp = false
		})
		return resp;
	} catch (error) {
		console.error('controller.configuration.guardarFormClienteWeb', error);
		throw error;

	}
}


export function* guardarFormularioDocumentos(values) {
  try {
    const company = getFromSession("company_id");
    console.log('Values form saga', values);
    let valuesForm = values.value.preguntas;
    let clientForm = {};
    let toastDetail;

    valuesForm.forEach((currentValue, index) => {
      clientForm['pregunta' + (index + 1)] = currentValue;
    });

    let resp = yield guardarFormDocumentos(clientForm, company, 'DOCUMENT_FORM');
    if (resp) {
      toastDetail = {
        'summary': 'Realizado',
        'severity': 'success',
        'detail': 'Formulario web modificado!'
      }

    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un problema con el servidor intente mas tarde'
      }

    }

    yield put(guardarFormularioDocumentosSuccess({
      toastDetail: toastDetail,
      showToast: true,
      updateForm: true,
    }));


  } catch (error) {
    console.error('saga.guardarFormularioClienteWeb', error);
  }
}



export function* watchUsers() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(LOAD_MENU, loadMenuImageOCR);
  yield takeLatest(COMPANY_DATA, companyData);
  yield takeLatest(GET_OCR_DOC, getOCRDocumentos);
  yield takeLatest(GET_DOC_PROC, getDocumentosProc);
  yield takeLatest(GET_DOC_FALL, getDocumentosFallidos);
  yield takeLatest(DOWNLOAD_DOC, downloadDoc);
  yield takeLatest(SHOW_DOC_PROC, mostrarDocumentosProcesados);
  yield takeLatest(DOCUMENT_FORM, traerConfFormDocumentos);
  yield takeLatest(GUARDAR_DOCUMENT_FORM, guardarFormularioDocumentos);





}
