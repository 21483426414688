import React, { useState, useEffect } from 'react';
import "./style.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Paper } from "@material-ui/core";
import { Button } from "@material-ui/core";
import RectangleOCR from './rectangle';
import DatoOcr from './datoOcr';
import { mostrarDocumentosProcesados } from '../../../../controller/utilidades';
import { INIT_CHARGE_OCR_DOCUMENTS_PROCESS, SAVE_STATE_HOVER_OCR_DOC } from './constants';
import { useSelector, useDispatch, connect } from 'react-redux';
import { getRecuadrosEtiquetaData, getStateHoverOcrDoc, getUrlPdf, getPersonasDocumentos } from './selectors';

const Component = ({ pdfUrl, handlerClose, data, idDoc }) => {

  const dispatch = useDispatch();

  const [recuadrosEtiquetaData, setRecuadrosEtiquetaData] = React.useState(false);
  const [urlPdf, setUrlPdf] = React.useState(false);

  const recuadrosIframeEtiquetaData = useSelector(getRecuadrosEtiquetaData);
  const stateHoverOcrDoc = useSelector(getStateHoverOcrDoc);
  const urlPdfSelector = useSelector(getUrlPdf);
  const personasDocumentos = useSelector(getPersonasDocumentos);

  const initChargeOcr = (value) => dispatch({ type: INIT_CHARGE_OCR_DOCUMENTS_PROCESS, value });
  const setHoverState = (value) => dispatch({ type: SAVE_STATE_HOVER_OCR_DOC, value });



  // ...
  useEffect(() => {

    if (!recuadrosEtiquetaData && recuadrosIframeEtiquetaData) {
      setRecuadrosEtiquetaData(recuadrosIframeEtiquetaData.recuadrosEtiqueta);
      setUrlPdf(urlPdfSelector)
    }

    if (idDoc && idDoc.recuadrosEtiqueta && !recuadrosEtiquetaData) {
      setRecuadrosEtiquetaData(idDoc.recuadrosEtiqueta)
      setUrlPdf(pdfUrl)
    }
  })
  // Update the document title using the browser API
  function inicio() {
    /* 
      ¿Cómo va a ser llamado desde el iframe? ¿Cómo mandan los datos desde comfe, para que se pueda llamar desde el "iFrame"
      Si trae parametros, significa que esta siendo llamado desde un "iframe" y por los parametros del PDFviewer 
      deben venir en la url o un dato que sirva para hacer la consulta a firebase

      http://localhost:3000/documentOcr?id=kJdpKgggN45eUDcFOBDS
    */
    const paramUrl = (window.location.search.slice(1)).split('&').reduce((acc, s) => {
      const [k, v] = s.split('=')
      return Object.assign(acc, { [k]: v })
    }, {})

    if (paramUrl && paramUrl.id) {
      /* Si llegan parametros es porque viene desde otra aplicación 
      retorna "true" para bajar la info del documento, se debe enviar el id o algo para poder descargar
      */
      if (!recuadrosIframeEtiquetaData)
        initChargeOcr(paramUrl.id)
    }
  }

  const initial = inicio();

  function renderizarValor(valor) {
    if (Array.isArray(valor)) {
      return (
        <div>
          {valor.map((item, index) => (
            <div key={index}>
              {renderizarValor(item)}
            </div>
          ))}
        </div>
      );
    } else if (typeof valor === 'object') {
      return renderizarObjeto(valor);
    } else if (typeof valor === 'boolean') {
      return <input type="checkbox" checked={valor} disabled={valor} />;
    } else {
      return valor;
    }
  }

  function renderizarObjeto(objeto) {

    if (!objeto) {
      return null;
    }
    if (objeto.recuadrosEtiqueta) {
      // delete objeto.recuadrosEtiqueta
    }
    const elementosRenderizados = [];
    Object.entries(objeto).forEach(([clave, valor]) => {
      // Verificar si la clave es "checkbox", "texto" o "campoUnico" y omitirla
      if (clave !== 'checkbox' && clave !== 'texto' && clave !== 'campoUnico') {
        elementosRenderizados.push(
          <div className={`${clave} ${stateHoverOcrDoc && stateHoverOcrDoc.campo === clave && !stateHoverOcrDoc.state ? 'underline-ocr-change-color' : ''}`} key={clave}>
            {/* /<div className={clave} key={clave}> */}
            <b>{clave}:</b> {renderizarValor(valor)}
          </div >
        );
      } else {
        elementosRenderizados.push(renderizarValor(valor));
      }
    });

    return (
      <div>
        {elementosRenderizados}
        <br /> {/* Agregar un salto de línea */}
      </div>
    );
  }

  function renderizarDatos(valor) {
    let elementosDatos = [];
    if (valor && valor.length > 0)
      for (const index in valor) {
        // Acceder a la propiedad
        let element = valor[index];
        const propertyNames = Object.keys(element);

        for (let i = 0; i < propertyNames.length; i++) {
          const e = propertyNames[i];
          if (i === 0) {
            elementosDatos.push(
              <DatoOcr
                e={e}
                i={index}
                valor={element[e]}
                setHoverState={setHoverState}
                stateHoverOcrDoc={stateHoverOcrDoc}
              />
            )
          }
        }

      }
    return elementosDatos;
  }

  function renderizarRectangle(recuadrosEtiquetaData) {
    let elementosRectangle = [];
    let myImageWidth = (1760 / 2) * 0.75;
    let myImageHeight = (2275 / 2) * 0.72;
    if (recuadrosEtiquetaData && recuadrosEtiquetaData.length > 0)
      for (const index in recuadrosEtiquetaData) {
        // Acceder a la propiedad
        let element = recuadrosEtiquetaData[index];
        const propertyNames = Object.keys(element);
        for (let i = 0; i < propertyNames.length; i++) {
          const e = propertyNames[i];
          if (i === 0) {
            let startX = Math.round(myImageWidth * element.coordenadas[0].x + 20);
            let startY = Math.round(myImageHeight * element.coordenadas[0].y);
            let endX = Math.round(myImageWidth * element.coordenadas[2].x + 20 );
            let endY = Math.round(myImageHeight * element.coordenadas[2].y);

            let width = endX - startX;
            let height = endY - startY;

            elementosRectangle.push(
              <RectangleOCR
                e={e}
                i={index}
                startX={startX}
                startY={startY}
                width={width}
                height={height}
                setHoverState={setHoverState}
                stateHoverOcrDoc={stateHoverOcrDoc}
              />
            )
          }
        }

      }
    return elementosRectangle;
  }

  function personasDeclarancionJuramentada(personas) {
    let personsB = []
    let personsD = []
    let beneficiarios = {}

    if (personas) {

      for (var per of personas) {
        var aux = false;
        var tem = false;
        for (var key in per) {
          if (key.includes("Declarante") || key.includes("Tipo_Id")) {
            aux = true;
          }
          if (key.includes("Anho") || key.includes("Mes") || key.includes("Dia") || key.includes("Ciudad")) {
            tem = true;
          }
        }
        if (aux && !tem) {
          personsD.push(per)
        } else if (!aux && !tem) {
          personsB.push(per)
        }
      }
    }
    return { personsB, personsD }
  }

  const respuestaPerson = recuadrosEtiquetaData ? personasDeclarancionJuramentada(recuadrosEtiquetaData) : "";

  //let respuestaPerson = pers ? personasDeclarancionJuramentada(pers) : "";

  const datorRBene = respuestaPerson.personsB ? renderizarDatos(respuestaPerson.personsB) : "";
  const datorRDecl = respuestaPerson.personsD ? renderizarDatos(respuestaPerson.personsD) : "";

  const datosRenderizados = recuadrosEtiquetaData ? renderizarDatos(recuadrosEtiquetaData) : "";
  const elementosRectangle = recuadrosEtiquetaData ? renderizarRectangle(recuadrosEtiquetaData) : "";

  let isPdf = true;
  if (urlPdf) {
    const ultimaParte = urlPdf.split('.').at(-1);
    if (ultimaParte != "pdf") {
      isPdf = false;
    }
  }

  return (
    <div className="App borrador-container modal-wrapper-bot" >
      {!recuadrosEtiquetaData &&

        <div className="loading-wrapper">
          <CircularProgress color="secondary"></CircularProgress>
        </div>
      }
      <div className="modal-content-ocr">
        <div className="button-ocr-left">
          <Button onClick={() => handlerClose()} color='primary' variant="contained" style={{ "marginBottom": "10px" }}>Cerrar Documento</Button>
        </div>
        <Paper elevation={10} className="container-pdf">
          {isPdf ?
            <iframe
              id="ocrImg"
              src={urlPdf}
              title="PDF Viewer"
              width="100%"
              height="1000px"
            ></iframe>
            :
            <img src={urlPdf} width="100%" alt='Documento ocr'></img>
          }
          {isPdf ?
            <div className="rectangulos" style={{ position: "absolute", width: "100%", height: "100%", marginTop: "65px" }}>
              <svg className="svgImage" id="svgImage" style={{ position: "relative", width: "100%", height: "100%" }}>
                {elementosRectangle}
              </svg>
            </div>
            : 
            <div className="rectangulos" style={{ position: "absolute", width: "100%", height: "100%"}}>
            <svg className="svgImage" id="svgImage" style={{ position: "relative", width: "100%", height: "100%" }}>
              {elementosRectangle}
            </svg>
          </div>

          }
          <div className="datos-pdf">
            <div className='respuestas-container'>
              <b>-BENEFICIARIOS-</b>
              {datorRBene}
              <b>-DECLARANTE-</b>
              {datorRDecl}
              <b>-TODOS LOS DATOS-</b>
              {datosRenderizados}
            </div>
          </div>
        </Paper>
      </div>

    </div>
  );

}

//export default PDFViewer;


export const PDFViewer = connect(
  null,
  null
)(Component);