import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import "./style.css";

import reducer from "./reducer";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';

// import { AuthProvider } from '../../../components/authprovider/auth';
// import { getFromSession } from '../../../controller/session';
import { Redirect } from 'react-router-dom';
import { PDFViewer } from './vistaPreviaDoc';
import { watchUsers } from './saga';
import { getFromSession } from '../../../../controller/session';
import { AuthProvider } from '../../../../components/authprovider/auth';

const rootReducer = combineReducers({
    form: formReducer,
    localreducer:reducer
});

//const formStore = createStore(rootReducer);

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchUsers);

export default class PdfViewer extends Component {
    render() {
        const currentUser = getFromSession('currentUser');
        //console.log("currentUser misDocumentosPage",currentUser)
        return (
            <AuthProvider>
                <Provider store={store}>
                    <Container className='main-wrapper inicio-planes' >
                        
                                <PDFViewer
                                    pdfUrl={this.props.pdfUrl}
                                    handlerClose={this.props.handlerClose}
                                    data={this.props.data}
                                    idDoc={this.props.idDoc}
                                    setHoverState={this.props.setHoverState}
                                    stateHoverOcrDoc={this.props.stateHoverOcrDoc}
                                    prueba
                                /> 
                        
                    </Container>
                </Provider>
            </AuthProvider>
        );
    }
}