import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { menuItemsOCR } from "../../../Constants";
import { Box, Tabs, Tab } from "@material-ui/core";
import SideBar from "../../../components/sidebar/sidebar";
import ComponentDocumentosFallidos from "./documentosFallidos";
import ComponentDocumentosCruzados from "../cruce/componenteCruce";
import ComponentDocumentosCargados from "./documentosProcesados";
import ComponentMisDocumentosCruzados from "./documentosCruzados";


import { CROSS_DOC, DOWNLOAD_DOC, GET_DOC_PROC, GET_OCR_DOC, SHOW_DOC_PROC } from "./constants";
import { getFromSession } from "../../../controller/session";
import { selectorLoadingCircular, selectorProccessDocument, selectorShowCross, selectorShowDocument, selectordocumentData } from "./selectors";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Button } from "@material-ui/core";
import { COMPANY_DATA, GET_DOC_FALL } from "./constants";
import { Paper } from "@material-ui/core";
import { getDataCompanySelector, selectorFailDocument } from "./selectors";
import PdfViewer from "./ocrTable";



class ComponentMisDocumentos extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout, tipoDoc } = this.props;
        const renderDoc = tipoDoc ? 'fallido' : 'procesados'
        this.state = {
            activePath: '/',
            tipo: renderDoc,
            pdfData: false,
            dataTableProcesados: false,
            dataTableFallidos: false,
            cruzarDocumento: false,
            error: false,
        }

    }
    handlerClose = () => {
        this.setState({ pdfData: false });

    }

    handlerCloseCruce = () => {
        this.setState({ cruzarDocumento: false });

    }

    selectTab = (e, newValue) => {
        this.setState({ tipo: newValue });
    }

    componentWillMount() {
        const { getDocumentosProc,
            getDocumentos,
            companyData,
            cargarDocumento,
            getFailDocument,
            proccessDocumentSelector,
            getDocumentosFallidos,
            documentosProcesadosSelector,
        } = this.props;
        //getOCRDocumentos();

        companyData();
        if (cargarDocumento) {

            getDocumentos();
            getFailDocument();
            this.setState({ dataTableProcesados: documentosProcesadosSelector });



        } else {

            getDocumentosProc();
            getDocumentosFallidos();
            this.setState({ dataTableProcesados: proccessDocumentSelector });

        }

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {


    }


    pdfData = (newData) => {
        this.setState({ pdfData: newData });
    }

    onModalCancelError = () => {
        this.setState({ error: false });
    }
    onCerrarCruce = () => {
        this.setState({ cruzarDocumento: false });

    }

    cruceData = (newData) => {
        //showDocument
        const {
            crossDocumentSelector,
            crossDoc,
            cargarDocumento,
            cruzarDoc
        } = this.props;
        if (cargarDocumento) {

            crossDoc(newData)
        } else {

            cruzarDoc(newData)
        }

        // if (!crossDocumentSelector) {
        //     this.setState({ error: true });
        // } else {
        // }
        this.setState({ cruzarDocumento: newData.name });
    }


    render() {
        const {
            mostrarDocumentos,
            showDocumentSelector,
            loading,
            download,
            failDocumentSelector,
            loadingCircular,
            cargarDocumento,
            crossDocumentSelector,
            volverADocPro,
            mostrarDocumentosProcesados,
            crossDoc,
            downloadDoc,
            showDocument,
            selectorDataCompany,
            showCrossSelector,
            proccessDocumentSelector,
            documentosProcesadosSelector,
            showCrossDocumentSelector,
            loadingProc,
            selectorDatosDocPrincipal,
            cruzarDoc,
            loadingFall,
            getFailDocument,
        } = this.props;
        const user = getFromSession("company_id")
        return (
            <React.Fragment>
                {menuItemsOCR && <SideBar logo={selectorDataCompany.logo} items={menuItemsOCR}></SideBar>}
                {cargarDocumento ?
                    this.state.cruzarDocumento ?
                        <ComponentDocumentosCruzados
                            selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                            onCerrarCruce={this.onCerrarCruce}
                            mostrarDocumentos={mostrarDocumentos}
                            cruzarDocu={crossDoc}
                            showDocument={showDocumentSelector}
                            showCrossDocumentSelector={showCrossDocumentSelector}
                            handlerCloseCruce={this.handlerCloseCruce}
                        />

                        : this.state.pdfData ?
                    
                            <PdfViewer

                                handlerClose={this.handlerClose}
                                pdfUrl={this.state.pdfData}
                                data={documentosProcesadosSelector}
                                idDoc={cargarDocumento ? showDocumentSelector : showDocument}
                            />
                            :
                            <React.Fragment>
                                <div className="align-button-ocr">
                                    <div className="button-ocr-left">
                                        <Button style={{ "marginTop": "10px" }} onClick={() => volverADocPro()} color='primary' variant="contained">Volver</Button>
                                    </div>
                                </div>

                                <div className="titulo-documentos">
                                    <Box sx={{ width: '100%' }}
                                        variant="fullWidth"
                                    >
                                        <Tabs className="tabs-container-ocr"
                                            value={this.state.tipo}
                                            style={{ display: 'initial' }}
                                            onChange={this.selectTab}
                                            centered
                                        >
                                            <Tab className="tab" value="procesados" style={{ "fontSize": "24px", "fontWeight": "bold" }}
                                                sx={{ width: 200 }}
                                                label="Procesados"

                                            />
                                            <Tab className="tab" value="fallido" style={{ "fontSize": "24px", "fontWeight": "bold" }}
                                                sx={{ width: 200 }}
                                                label="Fallidos"

                                            />
                                        </Tabs>
                                    </Box>
                                </div>

                                <div className="subs-containere">
                                    {this.state.tipo === 'procesados' ?

                                        <ComponentDocumentosCargados
                                            crossDoc={crossDoc}
                                            cruzarDocumento={this.state.dataTableProcesados}
                                            downloadDoc={cargarDocumento ? download : downloadDoc}
                                            data={documentosProcesadosSelector}
                                            mostrarDocumentosProcesados={mostrarDocumentos}
                                            showDocumentSelector={showDocumentSelector}
                                            pdfData={this.pdfData}
                                            cruceData={this.cruceData}
                                            user={user} />

                                        :
                                        <ComponentDocumentosFallidos
                                            data={this.state.dataTableFallidos} />
                                    }
                                </div>
                            </React.Fragment>

                    :

                    <Paper elevation={10} className="main-wrapper">



                        {this.state.cruzarDocumento ?
                            <Paper elevation={10} className="main-wrapper home container">

                                <ComponentMisDocumentosCruzados
                                    user={user}
                                    pdfData={this.pdfData}
                                    selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                                    onCerrarCruce={this.onCerrarCruce}
                                    mostrarDocumentosProcesados={cargarDocumento ? mostrarDocumentos : mostrarDocumentosProcesados}
                                    showDocument={showDocumentSelector}
                                    showCrossDocumentSelector={cargarDocumento ? showDocumentSelector : showCrossSelector}
                                    handlerCloseCruce={this.handlerCloseCruce}
                                />
                            </Paper>
                            :
                            <>
                                {this.state.pdfData ?
                                    <Paper elevation={10} className="main-wrapper home container">
                                        <PdfViewer
                                            handlerClose={this.handlerClose}
                                            pdfUrl={this.state.pdfData}
                                            data={documentosProcesadosSelector}
                                            idDoc={cargarDocumento ? showDocumentSelector : showDocument}

                                        />
                                    </Paper>

                                    :
                                    <Paper elevation={10} className="main-wrapper home container">
                                        <div className="titulo-documentos">
                                            <Box sx={{ width: '100%' }}
                                                variant="fullWidth"
                                            >
                                                <Tabs className="tabs-container-ocr"
                                                    value={this.state.tipo}
                                                    style={{ display: 'initial' }}
                                                    onChange={this.selectTab}
                                                    centered
                                                >
                                                    <Tab className="tab" value="procesados" style={{ "fontSize": "24px", "fontWeight": "bold" }}
                                                        sx={{ width: 200 }}
                                                        label="Procesados"

                                                    />
                                                    <Tab className="tab" value="fallido" style={{ "fontSize": "24px", "fontWeight": "bold" }}
                                                        sx={{ width: 200 }}
                                                        label="Fallidos"

                                                    />
                                                </Tabs>
                                            </Box>
                                        </div>

                                        {/* 
                                            */}
                                        <div className="subs-containere">
                                            {this.state.tipo === 'procesados' ?
                                                <>

                                                    <ComponentDocumentosCargados
                                                        selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                                                        handlerClose={this.handlerClose}
                                                        cruzarDocumento={this.state.dataTableProcesados}
                                                        downloadDoc={cargarDocumento ? download : downloadDoc}
                                                        data={proccessDocumentSelector}
                                                        mostrarDocumentosProcesados={cargarDocumento ? mostrarDocumentos : mostrarDocumentosProcesados}
                                                        showDocumentSelector={cargarDocumento ? showDocumentSelector : showCrossSelector}
                                                        pdfData={this.pdfData}
                                                        cruceData={this.cruceData}
                                                        user={user} />

                                                </>
                                                :
                                                <ComponentDocumentosFallidos
                                                    data={this.state.dataTableFallidos} />
                                            }
                                        </div>
                                    </Paper>
                                }
                            </>
                        }

                    </Paper>
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        proccessDocumentSelector: selectorProccessDocument(state),
        loadingCircular: selectorLoadingCircular(state),
        selectorDataCompany: getDataCompanySelector(state),
        failSelector: selectorFailDocument(state),
        showDocument: selectorShowDocument(state),
        showCrossSelector: selectorShowCross(state),

    };
};

const mapDispachToProps = dispatch => {
    return {
        getDocumentosProc: (value) => dispatch({ type: GET_DOC_PROC, value }),
        getDocumentosFallidos: (value) => dispatch({ type: GET_DOC_FALL, value }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),
        mostrarDocumentosProcesados: (value) => dispatch({ type: SHOW_DOC_PROC, value }),
        downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value }),
        cruzarDoc: (value) => dispatch({ type: CROSS_DOC, value }),




    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentMisDocumentos);