import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, menuItemsOCR, PATH_API } from '../../../Constants';
import { firebaseDatabase, firebaseField, storage } from "../../../controller/firebase";
import moment from "moment";

import {
  COMPANY_DATA,
  DATA_DOC_CARGADOS,
  DOWNLOAD_DOC,
  GET_DOC_FALL,
  GET_DOC_PROC,
  GET_OCR_DOC,
  GET_USERS, LINE_CHART, LOAD_MENU, SHOW_DOC_PROC,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
  companyDataSuccess,
  getOCRDocumentosSuccess,
  documentosProcesadosSuccess,
  documentosFallidosSuccess,
  downloadDocuSuccess,
  showDocumentosProcesadosSuccess,
  dataDocumentSuccess,
  obtenerDatosGraficaLineChartSuccess,
} from './actions';

import { getFromSession } from "../../../controller/session";
import { obtenerRangoDeFechas } from "../../../controller/manejoRangoFechas";
import { traerDatosGraficasBigQuery } from "../../../controller/estadisticas";

function* getUsers() {
  const urlUsers = `${PATH_API}${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
    method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if (fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const { data } = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }

  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}

function* loadMenuImageOCR(action) {
  let list = [];
  let company = getFromSession("company_id");
  //let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItemsOCR.forEach((menu) => {
    if (menu.roles.includes(rol)) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}

function* companyData(action) {
 
  const user = getFromSession("currentUser");
  let resp = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  yield put(companyDataSuccess(resp));
  return resp
}


function* dataDocumentParaGrafica(action) {
  let rango = yield obtenerRangoDeFechas(action.value.fechaRango);
  //console.log("fecha GRAFICAA", rango)
  const user = getFromSession("currentUser");
  let resp = yield traerDatosGraficasBigQuery(rango, "traerDatosDocumentosOCR");
  let convGrafica = [];
  let labels = [];
  let resultados = [];
  let titulo = 'Estado de los documentos';
  let tooltip = 'Gráfico que muestra el estado de cada documentos'
  console.log("resp******* " , resp); 
  if(resp){

    resp.forEach(doc => {
      //  console.log("CADA ELEMENTO DE RESP", doc)
      labels.push(doc.label)
      resultados.push(doc.estadoDocumento)
    });
    
  }
  let payload = {
    'labels': labels,
    'titulo': titulo,
    'tooltip': tooltip
  }

  convGrafica.push(labels)
  convGrafica.push(resultados)
  payload['numeros'] = resultados
  payload['graficas'] = convGrafica

 // console.log("PALOAD", payload)

  // let fechaIni = rango.fechaIni
  // let fechaFin = rango.fechaFin
  // let lote = false;
  // const user = getFromSession("currentUser");
  // let resp = false;
  // let procOk = 0;
  // let procErr = 0;
  // let resueltas = []
  // let convGrafica = [];

  // try {
  //   yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
  //     docs.forEach(doc => {
  //       resp = doc.data();
  //     });
  //   });
  //   lote = yield dataLoteParaGrafica(rango)
  //   // console.log("LOTE", lote)
  //   for (const doc of lote) {
  //     yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${doc.id}/Documentos`)
  //       .where('datosDocumento.fecha_cargue', '>', fechaIni).
  //       where('datosDocumento.fecha_cargue', "<", fechaFin)

  //       .get()
  //       .then(querySnapshot => {
  //         querySnapshot.forEach(query => {
  //           // console.log("DOC DATA ESTADO 11", query.data())
  //           if (query.data().datosDocumento.estado === 3) {
  //             procOk = procOk + 1;
  //             //  console.log("DOC DATA ESTADO", query.data().datosDocumento.estado)
  //           }
  //           else if (query.data().datosDocumento.estado !== 4) {
  //             procErr = procErr + 1;
  //             //  console.log("DOC DATA ESTADO", query.data().datosDocumento.estado)
  //           }
  //         });
  //       });
  //   }




  // resueltas.push(procOk);
  // resueltas.push(procErr);


  // convGrafica.push(labels)
  // convGrafica.push(resueltas)
  // payload['numeros'] = resueltas
  // payload['graficas'] = convGrafica



  // } catch (error) {
  //   //console.log('uploadImage', error)
  //   throw error;
  // }
  yield put(dataDocumentSuccess(payload));
}

const setBg = () => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

function* obtenerDatosGraficaLineChart(action) {

  let respuesta = {};
  // const user = getFromSession("currentUser");
  let rango = yield obtenerRangoDeFechas(action.value.fechaRango);
  let resp = yield traerDatosGraficasBigQuery(rango, "traerDatosDocumentosOCR");
  let labels = [];
  let datasets = [];
  let data = [];
  let colors = [];
  
  for (let index = 0; index < resp.length; index++) {
    const element = resp[index];
    labels.push(element.label);
    data.push(element.totalDocs);
  }
  
  labels.forEach(element => colors.push(setBg()))
  datasets.push({data , backgroundColor: colors});

  respuesta = {
    pie: {labels, datasets},
    resp
  }

  yield put(obtenerDatosGraficaLineChartSuccess(respuesta))
}




function* dataLoteParaGrafica(rango) {

  let documentos = false;
  const user = getFromSession("currentUser");
  let resp = false;
  let loteArray = [];
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`).get().then((querySnapshot) => {
      querySnapshot.forEach((lote) => {
        loteArray.push(lote.data())
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  return loteArray
}















export function* watchUsers() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(LOAD_MENU, loadMenuImageOCR);
  yield takeLatest(COMPANY_DATA, companyData);
  yield takeLatest(DATA_DOC_CARGADOS, dataDocumentParaGrafica);
  yield takeLatest(LINE_CHART, obtenerDatosGraficaLineChart);








}
