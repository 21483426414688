import { CHARGE_OCR_DOCUMENTS_SUCCESS, INIT_CHARGE_OCR_DOCUMENTS_PROCESS, SAVE_STATE_HOVER_OCR_DOC, SAVE_URL_PDF } from "./constants";

export const initialState = {
  recuadrosEtiquetaData: false,
  stateHoverOcrDoc: false,
  urlPdf: false,
  personasDocumentos: false,

};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type, value } = action;

  newState.showTable = false;
  switch (type) {
    case CHARGE_OCR_DOCUMENTS_SUCCESS:
      newState.recuadrosEtiquetaData = payload;
      newState.personasDocumentos = payload;
     
      
      return { ...newState };

    case SAVE_STATE_HOVER_OCR_DOC:
      newState.stateHoverOcrDoc = value;
      return {
        ...newState
      };

    case SAVE_URL_PDF:
      newState.urlPdf = payload;
      return {
        ...newState
      };

    default:
      return {
        ...newState
      };
  }
};

export default reducer;
