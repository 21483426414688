import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getDataCompanySelector, getMenuItemsChatBot, getMenuItemsOCR, getUploadBucketSelector, getUsers, selectLoading, selectLoadingCircular, selectLoadingCircularFall, selectLoadingProc, selectorAlert, selectorContador, selectorCrossDocument, selectorDatosDocPrincipal, selectorDocumetosProcesados, selectorFailDocument, selectorFilter, selectorLongitud, selectorName, selectorOcrActivo, selectorOcrCreate, selectorPlanes, selectorProDocument, selectorProcesarDocumentos, selectorShowCrossDocument, selectorShowDocument, selectorShowTable, selectorShowToast, selectorSize, selectorToastDetail, selectordocumentData, selectorfinSuscribcion } from "./selectors";
import { Spinner } from 'react-bootstrap';
import {
    COMPANY_DATA,
    COMPARAR_ARRAY,
    CROSS_DOC,
    DOCUMENTOS_CARGADOS_SUCCESS,
    DOWNLOAD_DOC,
    EMPTY_LIST,
    FIN_SUSCRIBCION,
    GET_DOC_FALL,
    GET_DOC_PROC,
    GET_OCR_DOC,
    GET_OCR_SIZE,
    GET_PLANES,
    GET_TRANSACCION,
    GET_USERS, LOAD_MENU, OCR_ACTIVO, OCR_CREATE, PRO_OCR_DOC, SHOW_DOC_PROC, UPLOAD_BUCKET
} from './constants';
import SideBar from "../../components/sidebar/sidebar";
import CustomCard from "../../components/customcard/customcard";
import { CARD_ITEMS } from "../inicio/constants";
import { menuItems } from "../agentPanel/selectors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getFromSession } from "../../controller/session";
import { Box, Button, Card, TextField, Paper } from "@material-ui/core";
import { InputText } from "primereact/inputtext";
import File from "./fileUpload";
import ComponentWalcome from "./walcomeOCR";
import ComponentError from "./errorPopUpComponent";
import { Toast } from "primereact/toast";
import ComponentTable from "./ocrTable/componentTable";
import ComponentMisDocumentos from "./misDocumentos/component";
import ComponentPlanes from "../planes/component";
import ComponentConfirmar from "./lotes pendientes/modalConfirmar"
import Alert from '@material-ui/lab/Alert';


class ComponentImageOCR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorUpload: false,
            filtroTable: [],
            table: false,
            cargarDocumento: true,
            errorTitle: false,
            proDoc: false
        }
    }
    // intervalId = setInterval(() => {
    //     const { documentDataSelector,
    //         procesarDocumentoSelector } = this.props
    //     console.log('comparando')
    //     const compareEstados = this.compararArray(documentDataSelector, procesarDocumentoSelector, 'estado')
    // }, 5000);

    // componentwillUnmount() {
    //     clearInterval(this.intervalId);
    // }

    componentDidMount() {
        const { companyData, ocrActivoSelector, procesarDocumentoSelector, documentDataSelector, getOCRDocumentos, sizeSelector, getMaxSize, isOcrEnabled } = this.props;
        //console.log("sizeSelector", sizeSelector)
        isOcrEnabled();
        companyData();
        getMaxSize();


        // const intervalId = setInterval(() => {
        //     const { documentDataSelector,
        //         procesarDocumentoSelector } = this.props
        //     //console.log('comparando')
        //     if(this.state.table){
        //         getOCRDocumentos();
        //     }
        // }, 10000);

    }


    componentWillMount() {
        const { getUsers, finSuscribcion, documentDataSelector, finSuscribcionSelector, loadMenuImageOCR, getOCRDocumentos, getMaxSize, showTableSelector, sizeSelector, ocrActivoSelector, isOcrEnabled, uploadToBucket } = this.props;
        getMaxSize();
        getUsers();
        isOcrEnabled();
        loadMenuImageOCR();
        //getOCRDocumentos();


        //console.log("documentDataSelector", documentDataSelector)
        finSuscribcion();
        // if (finSuscribcionSelector.fecha_prueba_gratis) {
        //     if (finSuscribcionSelector.fecha_prueba_gratis >= 40) {
        //         this.setState({ finSuscribcion: true })
        //         if (finSuscribcionSelector.tipo === 'Gratis') {
        //             this.setState({
        //                 actualizarPlanTitle: 'Tu prueba gratuita ha finalizado',
        //                 actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
        //             })

        //         }
        //     }
        // }
    }
    handlerOCR = (e) => {
        const { ocrActivoSelector, ocrCreate, isOcrEnabled } = this.props
        //console.log("ocrComponent", this.state.ocrComponent)
        ocrCreate();
        //console.log("selectorOcrActivo", selectorOcrActivo)
        this.setState({ ocrComponent: true });
        //console.log("ocrComponent", this.state.ocrComponent)
        isOcrEnabled()
    }
    filtrarTabla = (e) => {
        //console.log("VALUE FILTER TABLE", e.target.value)
        let texto = e.target.value
        const { documentDataSelector } = this.props;
        // console.log("TEXTTIO", texto)
        //console.log("filter intentsData", intentsData)
        let listaFiltrada = documentDataSelector.filter(function (objeto) {
            var valorPropiedad = String(objeto.name).toLowerCase();
            var textoBusqueda = texto.toLowerCase();

            // Verificar si el valor de la propiedad contiene el texto de búsqueda
            return valorPropiedad.includes(textoBusqueda);
        });
        console.log("listaFiltrada", listaFiltrada)

        this.setState({
            filtroTable: listaFiltrada
        })
    }

    handleDataChange = (newData) => {
        const { uploadToBucket, sizeSelector } = this.props;
        console.log("sizeSelector", sizeSelector)
        if (newData.length === 0) {
            this.setState({
                bodyError: 'Por favor seleccione al menos un archivo para cargar',
                errorUpload: true
            });
            return;
        }

        for (const archivo of newData) {
            if (archivo.size >= sizeSelector.value) {
                //console.log("TRUEEEEEE 0", newData)
                this.setState({
                    bodyError: 'Los archivos deben ser menores a ' + sizeSelector.valueMB + 'MB',
                    errorUpload: true
                });
                return;
            }
        }

        uploadToBucket(newData)
        this.onOpenTableHandler()

    };

    onModalCancelError = () => {
        this.setState({ errorUpload: false });
    }

    onCloseConfirmar = () => {
        const { confirmarDocumentoCargado } = this.props
        confirmarDocumentoCargado()
    }


    onProcesarDoc = (payload) => {

        const { procesarDocs } = this.props;
        if (payload.length === 0) {
            this.setState({
                bodyError: 'Por favor seleccione al menos un archivo para cargar',
                errorUpload: true,
                errorTitle: "Error cargando archivos"
            });
            return;
        }
        for (const doc of payload) {
            if (doc.estado === 'Orden de procesamiento'
                || doc.estado === 'En procesamiento'
                || doc.estado === 'Procesado'
            ) {
                this.setState({
                    bodyError: 'Solo puedes procesar archivos con estado, Cargado en bucket y Procesado con error',
                    errorUpload: true,
                    errorTitle: "Error procesando archivos"
                });
                return;
            }
        }


        procesarDocs(payload)
        // this.onOpenDocPro()

    }
    onCloseTableHandler = () => {
        this.setState({ table: false })
    }

    onOpenTableHandler = () => {
        this.setState({ table: true })
    }
    onOpenDocPro = () => {
        this.setState({ proDoc: true })
    }
    onCloseDocPro = () => {
        this.setState({ proDoc: false })
    }

    // compararArray = (arr, arr2, propiedad) => {
    //     console.log("array1", arr)
    //     console.log("array2", arr2)
    //     console.log("propiedad", propiedad)

    //     if (arr.length !== arr2.length) {
    //         return false;
    //     }

    //     for (let i = 0; i < arr.length; i++) {
    //         console.log("arr[i][estado]", arr[i][propiedad])
    //         console.log("ar2r[i][estado]", arr2[i][propiedad])
    //         console.log("*******************************************************")
    //         if (arr[i][propiedad] !== arr2[i][propiedad]) {
    //             return false;
    //         }
    //     }

    //     return true;
    // }



    componentDidUpdate(prevProps) {

        const { showToastSelector,
            filterSelector,
            finSuscribcionSelector,
            ocrActivoSelector,
            showTableSelector,
            getOCRDocumentos,
            compararArray,
            documentProSelector,
            emptyList,
            documentDataSelector,
            toastDetailSelector,
            procesarDocumentoSelector
        } = this.props;
        // if (showTableSelector && this.state.table === false) {
        //     this.setState({ table: true })
        // }

        // if(documentProSelector && this.state.proDoc === false){
        //     this.setState({ proDoc: true })
        // }

        //console.log("selectorShowToast", showToastSelector)

        if (prevProps.finSuscribcionSelector.fecha_prueba_gratis !== finSuscribcionSelector.fecha_prueba_gratis) {
            if (finSuscribcionSelector.tipo === 'Gratis') {
                if (finSuscribcionSelector.fecha_prueba_gratis >= 15) {
                    console.log("GRATIS")
                    this.setState({ finSuscribcion: true })
                    if (finSuscribcionSelector.tipo === 'Gratis') {
                        this.setState({
                            actualizarPlanTitle: 'Tu prueba gratuita ha finalizado',
                            actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                        })

                    }
                }
            }
            if (finSuscribcionSelector.plan === 'Pymes') {
                if (finSuscribcionSelector.fecha_prueba_gratis >= 30) {
                    console.log("MENSUAL")
                    this.setState({ finSuscribcion: true })
                    if (finSuscribcionSelector.tipo === 'mensual') {
                        this.setState({
                            actualizarPlanTitle: 'Tu suscribción ha finalizado',
                            actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                        })

                    }
                }
            }
            if (finSuscribcionSelector.plan === 'Pymes') {
                if (finSuscribcionSelector.fecha_prueba_gratis >= 365) {
                    console.log("ANUAL")
                    this.setState({ finSuscribcion: true })
                    if (finSuscribcionSelector.tipo === 'anual') {
                        this.setState({
                            actualizarPlanTitle: 'Tu suscribción ha finalizado',
                            actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                        })

                    }
                }
            }


        }
        if (prevProps.showToastSelector !== showToastSelector && !this.state.proDoc) {
            console.log("showToastSelector", showToastSelector)
            this.toast.show({
                severity: toastDetailSelector.severity,
                summary: toastDetailSelector.summary,
                detail: toastDetailSelector.detail,
            });
            //getOCRDocumentos();
        }

        if (prevProps.documentDataSelector !== documentDataSelector) {
            // console.log("prevProps.documentDataSelector", prevProps.documentDataSelector )
            console.log("TABLA", documentDataSelector)
            //getOCRDocumentos();
            this.setState({
                filtroTable: [...documentDataSelector]
            });
        }

        // if (procesarDocumentoSelector) {
        //     // console.log("documentDataSelector", documentDataSelector)
        //     // console.log("actualizar estados", procesarDocumentoSelector)
        //     this.setState({
        //         filtroTable: [...procesarDocumentoSelector]
        //     });
        //     emptyList()
        // }



        // if (prevProps.documentDataSelector) {
        //     //console.log("prevProps.documentDataSelector", prevProps.documentDataSelector)
        //     if (procesarDocumentoSelector) {
        //         // console.log("documentDataSelector", documentDataSelector)
        //         const compareEstados = this.compararArray(documentDataSelector, procesarDocumentoSelector, 'estado')
        //         if (this.state.table) {
        //             if (compareEstados) {
        //                 console.log("SON IGUALES SI", compareEstados)
        //                 // this.setState({
        //                 //         filtroTable: [...documentDataSelector]
        //                 //     });
        //             } else {
        //                 console.log("DIFERENTES", compareEstados)
        //                 // getOCRDocumentos();
        //                 // this.setState({
        //                 //     filtroTable: [...procesarDocumentoSelector]
        //                 // });

        //             }
        //         }

        //     }
        // }


    }


    render() {
        const {
            getDocumentosProcesados,
            uploadToBucket,
            selectorDataCompany,
            menuItemsOCR,
            contadorSelector,
            logintudSelector,
            loading,
            loadingCircular,
            ocrActivoSelector,
            documentDataSelector,
            nombreSelector,
            documentosProcesadosSelector,
            showDocumentSelector,
            mostrarDocumentosProcesados,
            getDocumentosFallidos,
            downloadDoc,
            selectorDatosDocPrincipal,
            crossDoc,
            crossDocumentSelector,
            loadingProc,
            failDocumentSelector,
            loadingFall,
            alertSelector,
            getPlanes,
            planesSelector,
            showCrossDocumentSelector,
            getTransaccion
        } = this.props;

        //console.log("alertSelector", alertSelector)
        return (


            <Paper className="App home-container">
                {menuItemsOCR && <SideBar logo={selectorDataCompany.logo} items={menuItemsOCR}></SideBar>}

                {alertSelector &&
                    <ComponentConfirmar
                        title={'Procesamiento terminado'}
                        body={'Para visualizar los documentos, por favor haga clic en el botón "Documentos Procesados".'}
                        cerrarModal={this.onCloseConfirmar}
                        botonText={'Aceptar'} />
                }

                {loading &&

                    <div className="loading-wrapper-documentos">

                        <Alert severity="info">
                            <h4>
                                Cargando Archivo - {nombreSelector}
                            </h4>
                            <div>
                                <CircularProgress color="secondary"></CircularProgress>
                            </div>
                        </Alert>


                    </div>
                }

                {this.state.errorUpload ?
                    <ComponentError
                        onModalCancelError={this.onModalCancelError}
                        errorUpload={this.state.errorUpload}
                        errorTitle={'Error cargando archivos'}
                        body={this.state.bodyError}
                    /> : ''
                }
                {this.state.finSuscribcion ?
                    <div className="App home-container">
                        <div className="noPlan">
                            <h1 style={{ "padding": "20px" }}>
                                {this.state.actualizarPlanTitle}
                            </h1>
                            <h3 style={{ "padding": "20px" }}>
                                {this.state.actualizarPlanSubtitle}
                            </h3>
                        </div>

                        <ComponentPlanes
                            finSuscribcionOCR={true}
                            getPlanesOCR={getPlanes}
                            planesSelectorOCR={planesSelector}
                            getTransaccionOCR={getTransaccion} />

                    </div>
                    :


                    <>


                        {this.state.proDoc ?
                            <div className="paper-conversaciones-ocr">
                                {console.log("repitiendo 11")}
                                <ComponentMisDocumentos
                                    selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                                    volverADocPro={this.onCloseDocPro}
                                    crossDoc={crossDoc}
                                    crossDocumentSelector={crossDocumentSelector}
                                    showDocumentSelector={showDocumentSelector}
                                    download={downloadDoc}
                                    getFailDocument={getDocumentosFallidos}
                                    failDocumentSelector={failDocumentSelector}
                                    cargarDocumento={this.state.cargarDocumento}
                                    getDocumentos={getDocumentosProcesados}
                                    loading={loadingCircular}
                                    loadingFall={loadingFall}
                                    documentosProcesadosSelector={documentosProcesadosSelector}
                                    mostrarDocumentos={mostrarDocumentosProcesados}
                                    showCrossDocumentSelector={showCrossDocumentSelector}
                                    loadingProc={loadingProc}
                                />
                                <Toast ref={(el) => (this.toast = el)} />
                            </div>


                            :
                            <>
                                {this.state.table ?

                                    <div className="paper-conversaciones-ocr">
                                        <div className="align-button-ocr">

                                            <div className="button-ocr-left">
                                                <Button onClick={() => this.onCloseTableHandler()} color='primary' variant="contained">Volver</Button>
                                            </div>
                                            <div className="button-ocr-right">
                                                <Button onClick={() => this.onOpenDocPro()} color='primary' variant="contained">Documentos procesados</Button>
                                            </div>
                                        </div>
                                        <div className="search-container">

                                            <span className="p-input-icon-left">
                                                <i className="pi pi-search" />
                                                <InputText type="search" onChange={this.filtrarTabla} placeholder="Buscar documento..." />
                                            </span>

                                        </div>


                                        <ComponentTable
                                            documentDataSelector={this.state.table}
                                            //loadingTable={documentDataSelector}
                                            data={this.state.filtroTable}
                                            procesarDocs={this.onProcesarDoc} />
                                        <Toast ref={(el) => (this.toast = el)} />
                                    </div>
                                    :
                                    <>
                                        {ocrActivoSelector && !this.state.proDoc ?

                                            <div className="paper-conversaciones-ocr">
                                                {/* <div className="button-ocr-right">
                                                    <Button style={{ "marginTop": "20px" }} onClick={() => this.onOpenTableHandler()} color='primary' variant="contained">Documentos Cargados</Button>
                                                </div> */}
                                                <File
                                                    uploadToBucket={uploadToBucket}
                                                    dataDocument={this.handleDataChange} />

                                                <Toast ref={(el) => (this.toast = el)} />

                                            </div>

                                            :
                                            <>
                                                <ComponentWalcome
                                                    handlerOCR={this.handlerOCR}
                                                />
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </Paper>


        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
        menuItemsOCR: getMenuItemsOCR(state),
        selectorUploadBucket: getUploadBucketSelector(state),
        loading: selectLoading(state),
        loadingCircular: selectLoadingCircular(state),
        loadingProc: selectLoadingProc(state),
        showToastSelector: selectorShowToast(state),
        toastDetailSelector: selectorToastDetail(state),
        ocrCreateSelector: selectorOcrCreate(state),
        ocrActivoSelector: selectorOcrActivo(state),
        selectorDataCompany: getDataCompanySelector(state),
        contadorSelector: selectorContador(state),
        logintudSelector: selectorLongitud(state),
        showTableSelector: selectorShowTable(state),
        documentDataSelector: selectordocumentData(state),
        filterSelector: selectorFilter(state),
        documentProSelector: selectorProDocument(state),
        sizeSelector: selectorSize(state),
        nombreSelector: selectorName(state),
        documentosProcesadosSelector: selectorDocumetosProcesados(state),
        showDocumentSelector: selectorShowDocument(state),
        failDocumentSelector: selectorFailDocument(state),
        crossDocumentSelector: selectorCrossDocument(state),
        loadingFall: selectLoadingCircularFall(state),
        planesSelector: selectorPlanes(state),
        showCrossDocumentSelector: selectorShowCrossDocument(state),
        finSuscribcionSelector: selectorfinSuscribcion(state),
        alertSelector: selectorAlert(state),
        selectorDatosDocPrincipal: selectorDatosDocPrincipal(state),
        procesarDocumentoSelector: selectorProcesarDocumentos(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        loadMenuImageOCR: (value) => dispatch({ type: LOAD_MENU, value }),
        uploadToBucket: (value) => dispatch({ type: UPLOAD_BUCKET, value }),
        ocrCreate: (value) => dispatch({ type: OCR_CREATE, value }),
        isOcrEnabled: (value) => dispatch({ type: OCR_ACTIVO, value }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),
        getOCRDocumentos: (value) => dispatch({ type: GET_OCR_DOC, value }),
        procesarDocs: (value) => dispatch({ type: PRO_OCR_DOC, value }),
        getMaxSize: (value) => dispatch({ type: GET_OCR_SIZE, value }),
        getDocumentosProcesados: (value) => dispatch({ type: GET_DOC_PROC, value }),
        getDocumentosFallidos: (value) => dispatch({ type: GET_DOC_FALL, value }),
        mostrarDocumentosProcesados: (value) => dispatch({ type: SHOW_DOC_PROC, value }),
        downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value }),
        crossDoc: (value) => dispatch({ type: CROSS_DOC, value }),
        getTransaccion: (referencia, id, tipo, producto, data) => dispatch({ type: GET_TRANSACCION, referencia, id, tipo, producto, data }),
        getPlanes: (value) => dispatch({ type: GET_PLANES, value }),
        emptyList: (value) => dispatch({ type: EMPTY_LIST, value }),
        finSuscribcion: () => dispatch({ type: FIN_SUSCRIBCION, value: 1 }),

        confirmarDocumentoCargado: () => dispatch({ type: DOCUMENTOS_CARGADOS_SUCCESS, payload: false })
        //compararArray: (array1, array2, estado) => dispatch({ type: COMPARAR_ARRAY, array1, array2, estado }),






    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentImageOCR);