import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, menuItemsOCR, PATH_API } from '../../../Constants';
import { firebaseDatabase, firebaseField, storage } from "../../../controller/firebase";
import moment from "moment";

import {
  COMPANY_DATA,
  CROSS_DOC,
  DOWNLOAD_DOC,
  GET_DOC_FALL,
  GET_DOC_PROC,
  GET_OCR_DOC,
  GET_USERS, LOAD_MENU, PAGINAR_DOCUMENTOS_PROCESADOS, SHOW_DOC_PROC,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
  companyDataSuccess,
  getOCRDocumentosSuccess,
  documentosProcesadosSuccess,
  documentosFallidosSuccess,
  downloadDocuSuccess,
  showDocumentosProcesadosSuccess,
  crossSuccess,
} from './actions';

import { getFromSession, saveInSession } from "../../../controller/session";

function* getUsers() {
  const urlUsers = `${PATH_API}${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
    method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if (fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const { data } = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }

  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}

function* loadMenuImageOCR(action) {
  let list = [];
  let company = getFromSession("company_id");
  //let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItemsOCR.forEach((menu) => {
    if (menu.roles.includes(rol)) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}

function* companyData(action) {

  const user = getFromSession("currentUser");
  let resp = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  yield put(companyDataSuccess(resp));
  return resp
}



function* getOCRDocumentos(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  let estado = false;
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      //.where('estado', '==', 0)
      .get();

    documentosSnapshot.forEach(doc => {
      const idDoc = doc.id
      documentos.push(idDoc);
    });

    for (const id of documentos) {
      const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        //.where('datosDocumento.estado', '==', 1)
        .get();

      documentosOCRSnapshotCargados.forEach(doc => {
        const documentData = doc.data();
        if (documentData.datosDocumento.estado === 0) {
          estado = 'Cargado en bucket'
        } else if (documentData.datosDocumento?.estado === 1) {
          estado = 'Orden de procesamiento'
        } else if (documentData.datosDocumento?.estado === 2) {
          estado = 'En procesamiento'
        } else if (documentData.datosDocumento?.estado === 3) {
          estado = 'Procesado'
        } else if (documentData.datosDocumento?.estado === 4) {
          estado = 'Procesado con error'
        }
        const dataDocument = {
          lote: id,
          fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
          name: documentData.datosDocumento.name,
          size: documentData.datosDocumento.tamano,
          id: doc.id,
          resumen: documentData.resumen ? documentData.resumen : '',
          estado: estado
        };
        consultaData.push(dataDocument);
      });

      const documentosOCRSnapshotError = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .where('datosDocumento.estado', '==', 4).get();

      documentosOCRSnapshotError.forEach(doc => {
        const documentData = doc.data();
        if (documentData.datosDocumento.estado === 0) {
          estado = 'Cargado en bucket'
        } else if (documentData.datosDocumento?.estado === 1) {
          estado = 'Orden de procesamiento'
        } else if (documentData.datosDocumento?.estado === 2) {
          estado = 'En procesamiento'
        } else if (documentData.datosDocumento?.estado === 3) {
          estado = 'Procesado'
        } else if (documentData.datosDocumento?.estado === 4) {
          estado = 'Procesado con error'
        }
        const dataDocument = {
          lote: id,
          fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
          name: documentData.datosDocumento.name,
          size: documentData.datosDocumento.tamano,
          id: doc.id,
          resumen: documentData.resumen ? documentData.resumen : '',
          estado: estado
        };
        consultaData.push(dataDocument);
      });



    }
  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  yield put(getOCRDocumentosSuccess(consultaData));

}

function* getDocumentosProc(action) {

  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const companyOcr = `${resp.tipo_documento + resp.num_documento}`
    saveInSession('companyOcr', companyOcr);

    const documentosOCRSnapshot = yield firebaseDatabase.collectionGroup(`Documentos`)
      .where('datosDocumento.company', '==', companyOcr)
      .where('datosDocumento.estado', '==', 3)
      .orderBy("datosDocumento.fecha_cargue", "desc")
      .limit(10)
      .get();


    documentosOCRSnapshot.forEach(doc => {

      const documentData = doc.data();

      const dataDocument = {
        lote: doc._delegate._document.key.path.segments[8],
        fecha_cargue: documentData?.datosDocumento?.fecha_cargue ? moment(documentData?.datosDocumento?.fecha_cargue.toDate()).format("ll, h:mm:ss a") : '',
        name: documentData?.datosDocumento?.name,
        size: documentData?.datosDocumento?.tamano,
        id: doc.id,
        resumen: documentData?.respuesta?.texto_Full ? documentData?.respuesta?.texto_Full : '',
        fecha_proc: moment(documentData?.respuesta?.fecha_de_procesamiento?.toDate()).format("ll, h:mm:ss a")
      };
      consultaData.push(dataDocument);


    });

    // const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
    //   // .where('estado', '==', 1)
    //   .limit(5)
    //   .get();

    // documentosSnapshot.forEach(doc => {
    //   const idDoc = doc.id
    //   documentos.push(idDoc);
    // });

    // for (const id of documentos) {
    //   const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
    //     .where('datosDocumento.estado', '==', 3).get();

    //   documentosOCRSnapshot.forEach(doc => {
    //     const documentData = doc.data();
    //     const dataDocument = {
    //       lote: id,
    //       fecha_cargue: documentData?.datosDocumento?.fecha_cargue ? moment(documentData?.datosDocumento?.fecha_cargue.toDate()).format("ll, h:mm:ss a") : '',
    //       name: documentData?.datosDocumento?.name,
    //       size: documentData?.datosDocumento?.tamano,
    //       id: doc.id,
    //       resumen: documentData?.respuesta?.texto_Full ? documentData?.respuesta?.texto_Full : '',
    //       fecha_proc: moment(documentData?.respuesta?.fecha?.toDate()).format("ll, h:mm:ss a")
    //     };
    //     consultaData.push(dataDocument);


    //   });

    // }

  } catch (error) {
    //console.log('intents', error)
    throw error;
  }

  yield put(documentosProcesadosSuccess(consultaData))


}

function* getDocumentosFallidos(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      .where('estado', '==', 1)
      .where('estado', '==', 2)
      .where('estado', '==', 4).get();

    documentosSnapshot.forEach(doc => {
      const idDoc = doc.id
      documentos.push(idDoc);
    });

    for (const id of documentos) {
      const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .where('datosDocumento.estado', '==', 4).get();

      documentosOCRSnapshot.forEach(doc => {
        const documentData = doc.data();
        const dataDocument = {
          lote: id,
          fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
          name: documentData.datosDocumento.name,
          size: documentData.datosDocumento.tamano,
          id: doc.id,
          resumen: documentData.respuesta.texto_Full ? documentData.respuesta.texto_Full : ''
        };
        consultaData.push(dataDocument);
      });
    }
  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  yield put(documentosFallidosSuccess(consultaData));
}

function* mostrarDocumentosProcesados(action) {

  const { lote, name,id } = action.value;
  let resp = false;
  let dataDocument = false;
  let datosRender = false;
  let DataBD = false;
  const resultado = {};
  let company = false;
  
  let nameFile = "";
  let dataFile = false;

  try {
    yield firebaseDatabase.collectionGroup('Documentos').where('datosDocumento.id', '==', id).orderBy('datosDocumento.fecha_cargue', 'desc').get().then(docs => {
      docs.forEach(docs => {
        resp = docs._delegate._key.path.segments;
        nameFile = docs.data().datosDocumento.name;
        dataFile = docs.data();
      });
    });
  } catch (error) {
    console.log('saga.mostrarDocumentosProcesados error1', error)
    throw error;
  }


  if (resp) {
    company = resp[6];
    

    try {
      const documentRef = yield firebaseDatabase.collection('company').doc(`${company}`).collection('configuration').doc('DOCUMENT_FORM');

      // Obtén los datos del documento
      yield documentRef.get()
        .then((doc) => {
          if (doc.exists) {
            DataBD = doc.data().value;


          } else {
            console.log('El documento no existe');
          }
        })
    } catch (error) {
      console.log('saga.mostrarDocumentosProcesados error2', error)
      throw error;
    }
    let personasDocumentos = {}
    let arrayCheckbox = []
    let arrayText = []
    let arraysolo = []
    let arregloNew = {};
    let arregloNew2 = {};
    let arregloNew3 = {};
    let textoFull = false;
    try {
      // yield firebaseDatabase.collection(`company/${company}/documentosOCR/${lote}/Documentos`)

      //     .get().then(docs => {
      //         docs.forEach(doc => {

      if (dataFile) {

        personasDocumentos = yield declaracionJuramentadaOrden(dataFile);
        dataDocument = dataFile?.respuesta.pagina
        textoFull = dataFile?.respuesta.texto_Full


        let separador2 = '-' 
        let separador = '/' 
        dataDocument.forEach((objeto) => {

          if (objeto.etiqueta.includes(separador)) {
            const etiqueta = objeto.etiqueta.split("/")[0];
            const nombreEtiqueta = objeto.etiqueta.split("/")[1];

            if (etiqueta) {

              arregloNew[etiqueta] = arregloNew[etiqueta] || [];

              for (const propiedad in objeto.value) {

                if (propiedad === 'booleanValue') {
                  arregloNew[etiqueta].push({
                    [nombreEtiqueta]: objeto.value.booleanValue,
                    coordenadas: objeto.value.coordenadas
                  });
                }
              }

            }
          }
          else if (objeto.etiqueta.includes(separador2)) {
            const etiqueta = objeto.etiqueta.split("-")[0];
            if (etiqueta) {
              arregloNew2[etiqueta] = arregloNew2[etiqueta] || [];

              if (objeto.value.subdatos) {
                arregloNew2[etiqueta].push(
                  objeto.value.subdatos.map((subdato) => ({
                    [subdato.Type]: subdato.mentiontext,
                    coordenadas: subdato.coordenadas // Esta linea es para agregar las coordenadas a la respuesta. Debo quitar las coordenadas del front
                  }))
                );
              }
            }
          } else {
            const etiqueta = objeto.etiqueta;
            if (objeto.value.texto !== '') {
              // arregloNew3[etiqueta] = arregloNew3[etiqueta] || [];
              arregloNew3["Datos"] = arregloNew3["Datos"] || [];
              arregloNew3["Datos"].push({ [etiqueta]: objeto.value.texto, coordenadas: objeto.value.coordenadas }) // Aqui falta sacar el subdato de la coordenada
            }
          }
        });

        let recuadrosEtiqueta = [];
        if (arregloNew.Tipo_Id){
          arregloNew.Tipo_Id = eliminarNull(arregloNew.Tipo_Id);
          
          
          arregloNew.Tipo_Id.forEach((element) => {
            const firstKey = Object.keys(element)[0];
            recuadrosEtiqueta.push({ Tipo_Id: firstKey, coordenadas: element.coordenadas });
          });
        }
          
        for (let prop in arregloNew2) {
          arregloNew2[prop].forEach((element) => {
            element.forEach((element2) => {
              recuadrosEtiqueta.push(element2);
            });
          });
        };

        arregloNew3.Datos.forEach((element) => {
          recuadrosEtiqueta.push(element);
        });

        resultado.checkbox = arregloNew;
        resultado.texto = arregloNew2;
        resultado.campoUnico = arregloNew3;
        resultado.textoFull = textoFull;
        resultado.recuadrosEtiqueta = recuadrosEtiqueta;
        resultado.personasDocumentos = personasDocumentos;

        if (DataBD) {
          
          if (DataBD.length !== 0) {
            DataBD.forEach((element) => {

              if (dataFile.respuesta?.tipoDeDocumento === element.tipoDocumento) {

                element.campos.forEach((e) => {


                  let arraySubdatos = [];
                  for (const propiedad in resultado.texto) {
                    if (typeof resultado.texto[propiedad] === "object") {
                      for (const propiedadSub in resultado.texto[propiedad]) {
                        // Imprime el elemento de la matriz
                        resultado.texto[propiedad][propiedadSub].forEach((data) => {

                          if (e.value === Object.keys(data).toString()) {
                            //datosRender[propiedadSub] =  Object.values(data).toString();
                            // Agregamos una variable para almacenar los valores del arreglo
                            let valores = Object.values(data);

                            arraySubdatos.push(valores)

                          }
                        })
                      }
                    } else {
                      // Imprime el valor del objeto
                    }
                  }

                  datosRender[e.value] = arraySubdatos
                  for (const propiedad in resultado) {

                    if (typeof resultado[propiedad] === "object") {
                      for (const propiedadSub in resultado[propiedad]) {
                        if (e.value === propiedadSub) {
                          datosRender[propiedadSub] = resultado[propiedad][propiedadSub];
                        }

                      }
                    } else if (e.value === propiedad) {
                      datosRender[propiedad] = resultado[propiedad];
                    }
                  }

                });

              }
            })
          }
        }
        if (Object.keys(datosRender).length === 0) {
          datosRender = resultado;

        }
        
        yield put(showDocumentosProcesadosSuccess(datosRender))
      }

    } catch (error) {
      //console.log('uploadImage', error)

      throw error;
    }
  }



}

async function declaracionJuramentadaOrden(action) {

  var paginaCruda = action.respuesta.respuesta_Cruda
  var declarante = {}
  var conyugue = {}
  var beneficiarios = []
  for (const entity in paginaCruda) {
    if (!paginaCruda[entity].properties.length > 0) {
      if (paginaCruda[entity].confidence > 0.1) {
        const tipos_doc = ["Tipo_Id/CC", "Tipo_Id/CE", "Tipo_Id/PA", "Tipo_Id/PE", "Tipo_Id/PPT", "Tipo_Id/TI"];
        if (tipos_doc.includes(paginaCruda[entity].type)) {
          var tipo_doc = paginaCruda[entity].type;
        }

        switch (paginaCruda[entity].type) {
          case "Nombre_Declarante":
            declarante.nombre = paginaCruda[entity].mentionText
            break;
          case "ID_Declarante":
            declarante.id = paginaCruda[entity].mentionText
            break;
          case tipo_doc:
            declarante.tipo_doc = paginaCruda[entity].type.split("/")[1]

          default:
            break;
        }
      }

    } else {

      var beneficiario = {}
      for await (const property of paginaCruda[entity].properties) {
        if (property.confidence > 0.1) {
          var llave = property.type
          const nombre_beneficiario = llave.match(/^Nombre_(Ben|Beneficiario|ben)$/) ? llave : undefined;
          const id_beneficiario = llave === "Id_Ben" || llave === "Id_Beneficiario" ? llave : undefined;
          const tipo_Id_Ben = llave === "Tipo_Id_Ben" || llave === "Tipo_ID_Ben" ? llave : undefined
          const parentesco = llave === "Parentesco" ? llave : undefined

          // se pone así, porque es el unico que no tiene campo parentesco

          if (paginaCruda[entity].type == "Union_Libre") {
            if (nombre_beneficiario) {
              conyugue.nombre = property.mentionText
              conyugue.parentesco = "Conyugue"
            }
            if (id_beneficiario) {
              conyugue.id = property.mentionText
            }
            if (tipo_Id_Ben) {
              conyugue.tipo_doc = property.mentionText
            }

          } else {

            if (nombre_beneficiario && !beneficiario.nombre) {
              beneficiario.nombre = property.mentionText
            }
            if (beneficiario.nombre && id_beneficiario) {
              beneficiario.id = property.mentionText
            }
            if (beneficiario.nombre && tipo_Id_Ben) {
              beneficiario.tipo_doc = property.mentionText
            }
            if (beneficiario.nombre && parentesco) {
              beneficiario.parentesco = property.mentionText
            }
            if (beneficiario.nombre && beneficiario.parentesco) {
              beneficiarios.push(beneficiario)
              beneficiario = {}
            }
          }
        }
      }

    }

  }
  beneficiarios.push(conyugue)

  beneficiarios = beneficiarios.filter((beneficiario) => {
    return Object.keys(beneficiario).length > 0;
  });

  var personas = {
    nombreDoc: action.datosDocumento.name,
    declarante: declarante,
    beneficiarios: beneficiarios,
  }
  return personas;

}




// Función para eliminar un objeto que contenga null, dentro de un array.
function eliminarNull(obj) {
  return obj.filter((objeto) => {
    for (const propiedad in objeto) {
      if (objeto[propiedad] === null) {
        return false;
      }
    }
    return true;
  });
  
}

function* downloadDoc(action) {
  try {
    // Obtén una referencia al almacenamiento de Firebase
    const storageRef = storage.refFromURL(action.value);

    // Obtén la URL de descarga del documento
    const downloadUrl = yield storageRef.getDownloadURL();

    // Crea un enlace temporal para descargar el documento
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'documento.pdf'; // Establece el nombre del archivo
    link.target = '_blank'; // Abre el enlace en una nueva pestaña

    // Simula un clic en el enlace para iniciar la descarga
    link.click();
  } catch (error) {
    console.error('Error al descargar el documento:', error);
  }

  yield put(downloadDocuSuccess())
}

function* cruzarDoc(action) {

  let user = getFromSession("currentUser");
  let cruzar = false;
  let resp = false
  let cruce = false;
  let documentos = [];
  let consultaData = [];
  let showDocument = false;
  let valoresParaCruce = [];
  let DataBD = false;
  let valorCorrespondiente = false;
  let datosDocPrincipal = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  try { //.collection(`company/${company}/configuration`).doc(`${document}`)
    const documentRef = yield firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('configuration').doc('CRUCE_CONF');

    // Obtén los datos del documento
    yield documentRef.get()
      .then((doc) => {
        if (doc.exists) {
          DataBD = doc.data().value;
        }
      })
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }


  try {
    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)
      .doc(action.value.id)
      .get().then(doc => {
        datosDocPrincipal = {
          nombre: doc.data().datosDocumento.name,
          tipoDocumento: doc.data().respuesta?.tipoDeDocumento
        }
        const BdTipoDocumento = doc.data().respuesta?.tipoDeDocumento;

        if (DataBD) {

          DataBD.forEach((element) => {


            Object.values(element).forEach((resp) => {

              valoresParaCruce.push(resp)
              if (resp === BdTipoDocumento) {
                cruzar = true;
              }

            })
          })
        }
      });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  valoresParaCruce = valoresParaCruce.filter((valor, indice, self) => self.indexOf(valor) === indice);



  const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
    //.where('estado', '==', 1)
    .get();

  documentosSnapshot.forEach(doc => {
    const idDoc = doc.id
    documentos.push(idDoc);
  });

  if (cruzar) {
    for (const id of documentos) {
      const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        //.where('respuesta.extraccion.id', '==', cruce)
        .get();

      documentosOCRSnapshot.forEach(doc => {
        const documentData = doc.data();
        if (documentData.respuesta) {
          documentData.respuesta.pagina.forEach((resp) => {
            valoresParaCruce.forEach((val) => {
              if (resp.etiqueta === val) {

                valorCorrespondiente = resp.value.texto;
              }
              if (resp.value.subdatos) {
                resp.value.subdatos.forEach((subadto) => {
                  Object.values(subadto).forEach((dato) => {


                    if (dato === val) {

                      valorCorrespondiente = subadto.mentiontext;
                    }
                  })
                })
              }
            })
          })
        }

      });
    }



    for (const id of documentos) {
      const ref = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .get().then((snapshot) => {
          snapshot.forEach(doc => {

            const datos = doc.data();

            if (datos?.respuesta?.pagina) {
              const elementos = datos.respuesta.pagina.map((pagina) => {


                if (pagina.value.texto === valorCorrespondiente) {
                  consultaData.push({
                    lote: id,
                    fecha_cargue: datos?.respuesta?.fecha_de_procesamiento ? moment(datos?.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                    name: datos?.datosDocumento.name,
                    size: datos?.datosDocumento.tamano,
                    id: doc.id,
                    resumen: datos?.respuesta?.texto_Full ? datos?.respuesta?.texto_Full : '',
                    estado: datos?.datosDocumento.estado
                  });
                }
                Object.values(pagina).forEach((dato) => {
                  if (dato.subdatos) {
                    dato.subdatos.forEach((subdato) => {
                      if (subdato.mentiontext === valorCorrespondiente) {
                        consultaData.push({
                          lote: id,
                          fecha_cargue: datos?.respuesta?.fecha_de_procesamiento ? moment(datos?.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                          name: datos?.datosDocumento.name,
                          size: datos?.datosDocumento.tamano,
                          id: doc.id,
                          resumen: datos?.respuesta?.texto_Full ? datos?.respuesta?.texto_Full : '',
                          estado: datos?.datosDocumento.estado
                        });
                      }
                    })
                  }

                })
              });
            }
          })
        });
      // const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
      //   .where('respuesta.extraccion.id', '==', valorCorrespondiente)
      //   .get();
      let documentosOCRSnapshot = false;



      //for (let valor of valoresParaCruce) {
      //let estado = false;
      const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .get()
        .then((docs) => {
          const documentosFiltrados = [];
          docs.forEach(doc => {
            const data = doc.data();

            const pagina = data?.respuesta?.pagina;

            for (let valor of valoresParaCruce) {
              if (valor === data?.respuesta?.tipoDeDocumento) {
                if (pagina && Array.isArray(pagina)) {
                  // Buscamos en todos los elementos de respuesta.pagina
                  for (const item of pagina) {
                    if (item.value && item.value.subdatos && Array.isArray(item.value.subdatos)) {
                      const subdato = item.value.subdatos.find(sub => sub.mentiontext === valorCorrespondiente);
                      if (subdato) {
                        consultaData.push({
                          lote: id,
                          fecha_cargue: data.respuesta?.fecha_de_procesamiento ? moment(data.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                          name: data.datosDocumento.name,
                          size: data.datosDocumento.tamano,
                          id: doc.id,
                          resumen: data.respuesta?.texto_Full ? data.respuesta?.texto_Full : '',
                          estado: data.datosDocumento.estado
                        });
                        //break; // Terminamos la búsqueda en esta página al encontrar una coincidencia
                      }
                    }
                  }
                }
              }
            }

          });
          // documentosFiltrados contiene los documentos que cumplen con la condición
        }).catch((error) => {
          console.log("error", error)
        });

      // const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
      // //.where('datosDocumento.estado', '==', 1)
      // .get();




      // yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`).where("pagina.value.subdatos", "array-contains", { Type: 'Nombre_Menor', mentiontext: valorCorrespondiente })
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       // Aquí puedes acceder a los documentos que cumplen con la condición
      //       console.log("Documento encontrado:", doc.id, doc.data());
      //     });
      //   })
      //   .catch((error) => {
      //     console.error("Error al realizar la consulta:", error);
      //   });






      // Recorremos el campo pagina
      // Comparamos el elemento con el valor almacenado en la variable
      //return pagina === valor;

      // Imprimimos el resultado de la comparación

      //}




      // documentosOCRSnapshot.forEach(doc => {
      //   const documentData = doc.data();
      //   const dataDocument = {
      //     lote: id,
      //     fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
      //     name: documentData.datosDocumento.name,
      //     size: documentData.datosDocumento.tamano,
      //     id: doc.id,
      //     resumen: documentData.resumen ? documentData.resumen : ''
      //   };
      //   consultaData.push(dataDocument);
      //   if (consultaData.length !== 0) {
      //     showDocument = true
      //   }

      //   //console.log("documentData", documentData)
      // });
    }
  }

  //console.log("consultaData", consultaData)

  if (consultaData.length !== 0) {
    showDocument = true
  }




  yield put(crossSuccess({
    consultaData: consultaData,
    showDocument: showDocument,
    datosDocPrincipal: datosDocPrincipal
  }))
}
function generarQueryConsultaPaginada(reference, first, fecha, nextPage, rows) {

  let consulta = reference
  if (first == 0) {
    if (fecha) {
      consulta = reference.limit(rows)
    } else if (!nextPage) {
      consulta = reference.limit(rows)
    } else {
      consulta = false
    }

  } else if (first > 0) {
    if (nextPage) {
      consulta = reference.startAfter(fecha).limit(rows)
    } else {
      consulta = reference.endBefore(fecha).limitToLast(rows)
    }
  }
  return consulta;
}


function* paginarDocumentosProcesados(action) {

  try {
    const { first, rows } = action.value.event;
    const { ultimoDocProc, nextPage, seleccionRangoBusqueda } = action.value;

    const companyOcr = getFromSession('companyOcr');

    let ultimoDocProcFecha = new Date(ultimoDocProc.fecha_cargue)



    let reference = yield firebaseDatabase.collectionGroup(`Documentos`)
      .where('datosDocumento.company', '==', companyOcr)
      .where('datosDocumento.estado', '==', 3)
      .orderBy("datosDocumento.fecha_cargue", "desc")


    let query = generarQueryConsultaPaginada(reference, first, ultimoDocProcFecha, nextPage, rows);

    let resultadoConsulta = []
    let esPrimeraPagina = false;
    let consultaData = [];
    if (query) {
      //const documentosOCRSnapshot = yield query.get();
      yield query.get().then(response => {
        response.docs.map((doc, i) => {
          const documentData = doc.data();
          const dataDocument = {
            lote: doc._delegate._document.key.path.segments[8],
            fecha_cargue: documentData?.datosDocumento?.fecha_cargue ? moment(documentData?.datosDocumento?.fecha_cargue.toDate()).format("ll, h:mm:ss a") : '',
            name: documentData?.datosDocumento?.name,
            size: documentData?.datosDocumento?.tamano,
            id: doc.id,
            resumen: documentData?.respuesta?.texto_Full ? documentData?.respuesta?.texto_Full : '',
            fecha_proc: moment(documentData?.respuesta?.fecha_de_procesamiento?.toDate()).format("ll, h:mm:ss a")
          };
          consultaData.push(dataDocument);
        });

      })
      // documentosOCRSnapshot.forEach(doc => {
      //   const documentData = doc.data();
      //   const dataDocument = {
      //     lote: "56446545464564",
      //     fecha_cargue: documentData?.datosDocumento?.fecha_cargue ? moment(documentData?.datosDocumento?.fecha_cargue.toDate()).format("ll, h:mm:ss a") : '',
      //     name: documentData?.datosDocumento?.name,
      //     size: documentData?.datosDocumento?.tamano,
      //     id: doc.id,
      //     resumen: documentData?.respuesta?.texto_Full ? documentData?.respuesta?.texto_Full : '',
      //     fecha_proc: moment(documentData?.respuesta?.fecha?.toDate()).format("ll, h:mm:ss a")
      //   };
      //   consultaData.push(dataDocument);


      // });

    } else {
      esPrimeraPagina = true
    }


    yield put(documentosProcesadosSuccess([...consultaData]))
    // yield put(paginarDatosConvsChatbotSuccess(
    //   {
    //     "dataPaginada": data,
    //     "esPrimeraPagina": esPrimeraPagina
    //   }
    // ))
  } catch (error) {

    throw error;

  }
}


export function* watchUsers() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(LOAD_MENU, loadMenuImageOCR);
  yield takeLatest(COMPANY_DATA, companyData);
  yield takeLatest(GET_OCR_DOC, getOCRDocumentos);
  yield takeLatest(GET_DOC_PROC, getDocumentosProc);
  yield takeLatest(GET_DOC_FALL, getDocumentosFallidos);
  yield takeLatest(DOWNLOAD_DOC, downloadDoc);
  yield takeLatest(SHOW_DOC_PROC, mostrarDocumentosProcesados);
  yield takeLatest(CROSS_DOC, cruzarDoc);
  yield takeLatest(PAGINAR_DOCUMENTOS_PROCESADOS, paginarDocumentosProcesados);


}
