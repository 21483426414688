import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getFromSession } from "../../../controller/session";
import { Box, Button, Card, Paper, TextField } from "@material-ui/core";
import ComponentTable from "../intentTable/componentTable";
import { Helmet } from "react-helmet";
import DeleteIcon from '@material-ui/icons/Delete';
import ComponentChange from "../../authentication/componentChange";
import { FileUpload } from 'primereact/fileupload';
import { getAuthError, getAuthForm, getLogSuccess, getLogo, getMessage, getRecoveryPass, getSelectedForm, getSelectedTab } from "../../authentication/selectors"
import { AUTH_LOAD_PARAMS, LOG_USER, RECOVERY_PASS, SELECT_TAB, UPDATE_FIELD_ATTRIBUTE } from "../../authentication/constants";
import { GET_BOTS, UPDATE_PROFILE, UPLOAD_ICON, UPLOAD_IMAGE } from "../constants";
import { selectGetBots } from "../selectors";
import AdminPanelPage from "../../adminPanel";
import ComponentPublicar from "./publicar";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { firebaseDatabase, storage } from "../../../controller/firebase";

class ComponentConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombreBot: '',
            cambCon: false,
            icono: false,
            admin: false,
            botonChat: false,
            avatar: false,
            botonChatRender: false,
            avatarRender: false,
            logo: false,
            logoRender: false,
            publicar: false

        }

    }

    onNombreHandler = (e) => {
        this.setState({ nombreBot: e.target.value });
    }

    onPassHandler = () => {
        this.setState({ cambCon: true });
    }
    onAdminPanelHandler = () => {
        this.setState({ admin: true });
    }
    onPublicarHandler = () => {
        this.setState({ publicar: true });
    }
    onPublicarCloseHandler = () => {
        this.setState({ publicar: false });
    }


    onReturnConfiguration = () => {
        this.setState({ admin: false });
        console.log("onReturnConfiguration", this.state.admin)
    }

    // hideWidgetChat = () => {
    //     var widgetChatElement = document.getElementById("react-widget-frame");
    //     widgetChatElement.classList.remove("react-widget-show");
    //     widgetChatElement.classList.add("react-widget-hide");
    // }
    componentDidMount() {
        const { uploadImage, company } = this.props
        //this.hideWidgetChat();
        uploadImage();
    }
    handleRemoveAvatar = () => {
        const {
            company,
        } = this.props;
        //console.log("PROPSS HANDLWREMOVE", props)
        if (company) {
            var storageRef = storage.refFromURL(company?.avatar);
            const user = getFromSession("currentUser");
            const docRef = firebaseDatabase.collection('company').doc(`${company.tipo_documento}${company.num_documento}`);

            const updateData = {
                avatar: ''
            };

            docRef.update(updateData)
                .then(() => {
                    
                })
                .catch((error) => {
                    console.error('Error al actualizar el elemento:', error);
                });

            // Delete the file
            storageRef.delete().then(function () {
                console.log("IMAGE DELETED")
            }).catch(function (error) {
                console.log("IMAGE ERROR", error)
            });

            this.setState({ avatarRender: `${process.env.REACT_APP_NOPICTURE}` });
            this.setState({ avatar: `${process.env.REACT_APP_NOPICTURE}` });
        }
    }

    handleRemoveBtnChat = () => {
        const {
            company,
        } = this.props;
        //console.log("PROPSS HANDLWREMOVE", props)
        if (company) {
            var storageRef = storage.refFromURL(company?.logoNombre);
            const user = getFromSession("currentUser");
            const docRef = firebaseDatabase.collection('company').doc(`${company.tipo_documento}${company.num_documento}`);

            const updateData = {
                logoNombre: ''
            };

            docRef.update(updateData)
                .then(() => {
                    
                })
                .catch((error) => {
                    console.error('Error al actualizar el elemento:', error);
                });

            // Delete the file
            storageRef.delete().then(function () {
                console.log("IMAGE DELETED")
            }).catch(function (error) {
                console.log("IMAGE ERROR", error)
            });

            this.setState({ botonChatRender: `${process.env.REACT_APP_NOPICTURE}` });
            this.setState({ boton: `${process.env.REACT_APP_NOPICTURE}` });
        }
    }


    handleRemoveLogo = () => {
        const {
            company,
        } = this.props;
        //console.log("PROPSS HANDLWREMOVE", props)
        if (company) {
            var storageRef = storage.refFromURL(company?.logo);
            const user = getFromSession("currentUser");
            const docRef = firebaseDatabase.collection('company').doc(`${company.tipo_documento}${company.num_documento}`);

            const updateData = {
                logo: ''
            };

            docRef.update(updateData)
                .then(() => {
                    
                })
                .catch((error) => {
                    console.error('Error al actualizar el elemento:', error);
                });

            // Delete the file
            storageRef.delete().then(function () {
                console.log("IMAGE DELETED")
            }).catch(function (error) {
                console.log("IMAGE ERROR", error)
            });

            this.setState({ logoRender: `${process.env.REACT_APP_NOPICTURE}` });
            this.setState({ logo: `${process.env.REACT_APP_NOPICTURE}` });
        }
    }



    componentWillMount() {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
        const {
            getBots,
            company,
            getBotsSelector
        } = this.props;
        getBots()
        this.setState({ nombreBot: getBotsSelector.nombreBotUpd });


        if (company.logoNombre) {
            this.setState({ botonChatRender: company.logoNombre });
        } else {
            this.setState({ botonChatRender: `${process.env.REACT_APP_NOPICTURE}` });
        }

        if (company.avatar) {
            this.setState({ avatarRender: company.avatar });
            // console.log("avatarRENDER WIILLL", this.state.avatarRender)
        } else {
            this.setState({ avatarRender: `${process.env.REACT_APP_NOPICTURE}` });
            //console.log("avatarRENDER WIILLL", this.state.avatarRender)
        }

        if (company.logo) {
            console.log("logoRender", company.logo)
            this.setState({ logoRender: company.logo });
            // console.log("avatarRENDER WIILLL", this.state.avatarRender)
        } else {
            this.setState({ logoRender: `${process.env.REACT_APP_NOPICTURE}` });
            //console.log("avatarRENDER WIILLL", this.state.avatarRender)
        }

        //document.getElementById('chatbot-avanti').classList.remove("ver-pluggin");
    }


    onUploadIcono = (event) => {
        const file = event.files[0];
        this.setState({
            botonChatRender: file.objectURL,
            botonChat: file
        });
        //console.log("UPLLOAD ICONO", file.objectURL)
        //const { uploadIcon } = this.props;
        //uploadIcon(file)
    }
    onUploadAvatar = (event) => {

        const file = event.files[0];
        this.setState({
            avatarRender: file.objectURL,
            avatar: file
        });
        //console.log("UPLLOAD Avatar", file)
        //const { uploadIcon } = this.props;
        //uploadIcon(file)
    }
    onUploadLogo = (event) => {

        const file = event.files[0];
        this.setState({
            logoRender: file.objectURL,
            logo: file
        });
        //console.log("UPLLOAD Avatar", file)
        //const { uploadIcon } = this.props;
        //uploadIcon(file)
    }

    onGuardarHandler = () => {
        const { uploadImage,
            updateProfile,
            onConfigurationHandlerClose,
            onUpdateProfilePopUp
        } = this.props;

        let respuesta = false;
        if (this.state.nombreBot !== false && this.state.botonChat && this.state.avatar === false && this.state.logo === false) {
            respuesta = {
                nombreChatBot: this.state.nombreBot,
                icono: this.state.botonChat
            }
        }
        else if (this.state.nombreBot !== false && this.state.avatar && this.state.botonChat === false && this.state.logo === false) {
            respuesta = {
                nombreChatBot: this.state.nombreBot,
                avatar: this.state.avatar
            }
        }
        else if (this.state.nombreBot !== false && this.state.avatar === false && this.state.botonChat === false && this.state.logo) {
            respuesta = {
                nombreChatBot: this.state.nombreBot,
                logo: this.state.logo
            }
        }

        else if (this.state.nombreBot !== false && this.state.botonChat === false && this.state.avatar === false && this.state.logo === false) {
            respuesta = {
                nombreChatBot: this.state.nombreBot
            }
        }

        else {
            respuesta = {
                nombreChatBot: this.state.nombreBot,
                avatar: this.state.avatar,
                icono: this.state.botonChat,
                logo: this.state.logo
            }
        }

        //console.log("RESPUESTA", respuesta)

        updateProfile(respuesta)
        onConfigurationHandlerClose()
        onUpdateProfilePopUp()
        setTimeout(async () => {
            const credential = uploadImage()
        }, 3000)

    }

    render() {
        const {
            returnToNombreBot,

            company
        } = this.props;
        const user = getFromSession("currentUser");
        return (

            <div className='chatbot-wrapper'>

                {this.state.admin ?
                    <>
                        <div className="boton-volver">
                            <Button className="boton" color='primary' variant="contained" onClick={() => { this.onReturnConfiguration() }}>
                                Volver
                            </Button>
                        </div>
                        
                    <div className="subs-containere">
                        <AdminPanelPage />
                    </div>
                    </>
                    :
                    <>
                        {this.state.publicar ?


                            <ComponentPublicar
                                onPublicarCloseHandler={this.onPublicarCloseHandler} 
                                company={company}
                                />
                            :
                            <>


                                {this.state.cambCon ?
                                    <ComponentChange />
                                    :

                                    <div className='chatbot-wrapper'>
                                        <div className="titulo">
                                            <h1>
                                                Configuración
                                            </h1>
                                        </div>


                                        <div className='crear-intent'>
                                            <Paper elevation={10} className="paper-conversaciones" >

                                                <div className="boton-volver">

                                                    <Button className="boton" color='primary' variant="contained" onClick={() => { returnToNombreBot() }}>
                                                        Volver
                                                    </Button>
                                                </div>
                                                <div className="align-button">
                                                    <Button color='primary' variant="contained" onClick={() => this.onPublicarHandler()}>Publicar</Button>
                                                </div>
                                                <div className="align-button">
                                                    <Button color='primary' variant="contained" onClick={() => this.onAdminPanelHandler()}>Más configuraciones</Button>
                                                </div>

                                                <div className="configuration-wrapper">
                                                    <div className="label-align-configuration">
                                                        <h3 style={{ "marginBottom": "20px" }}>
                                                            Nombre del Bot
                                                        </h3>
                                                        <TextField
                                                            placeholder=""
                                                            label="Nombre del Bot"
                                                            variant="outlined"
                                                            value={this.state.nombreBot}
                                                            onChange={this.onNombreHandler} />
                                                    </div>


                                                    <div className="respuestas-container-configuration">
                                                        <div className="previa">
                                                            <p style={{ "fontSize": "14px" }}>
                                                                Logo de la empresa
                                                            </p>

                                                            <img src={this.state.logoRender} width="54" height="54" />
                                                        </div>

                                                        <DeleteIcon style={{ "cursor": "pointer" }} onClick={() => this.handleRemoveLogo()} />

                                                        <div className="boton-configuration">
                                                            <FileUpload mode="basic"
                                                                name="demo[]"
                                                                url={"/api/upload"}
                                                                customUpload
                                                                accept="image/*"
                                                                maxFileSize={1000000}
                                                                chooseLabel={'Logo Empresa'}
                                                                auto
                                                                uploadHandler={this.onUploadLogo} />
                                                            Es la imagen que te representa a ti o a tu empresa.
                                                            La puedes ver en la parte superior izquierda
                                                        </div>
                                                    </div>


                                                    <div className="respuestas-container-configuration">
                                                        <div className="previa">
                                                            <p style={{ "fontSize": "14px" }}>
                                                                Logo del Chatbot
                                                            </p>

                                                            <img src={this.state.botonChatRender} width="54" height="54" />
                                                        </div>

                                                        <DeleteIcon style={{ "cursor": "pointer" }} onClick={() => this.handleRemoveBtnChat()} />

                                                        <div className="boton-configuration">
                                                            <FileUpload mode="basic"
                                                                name="demo[]"
                                                                url={"/api/upload"}
                                                                customUpload
                                                                accept="image/*"
                                                                maxFileSize={1000000}
                                                                chooseLabel={'Logo del Chat'}
                                                                auto
                                                                uploadHandler={this.onUploadIcono} />
                                                            Es el banner de tu Bot, la podrás ver en la parte superior del bot
                                                        </div>
                                                    </div>
                                                    <div className="respuestas-container-configuration">
                                                        <div className="previa">

                                                            <p style={{ "fontSize": "14px" }}>
                                                                Avatar del bot
                                                            </p>

                                                            <img src={this.state.avatarRender} width="54" height="54" />
                                                        </div>
                                                        <DeleteIcon style={{ "cursor": "pointer", "margin-left": "50 px" }} onClick={() => this.handleRemoveAvatar()} />
                                                        <div className="boton-configuration">

                                                            <FileUpload mode="basic"
                                                                name="demo[]"
                                                                url={"/api/upload"}
                                                                customUpload
                                                                accept="image/*"
                                                                maxFileSize={1000000}
                                                                chooseLabel={'Avatar'}
                                                                auto
                                                                uploadHandler={this.onUploadAvatar} />


                                                            Es la imagen que aprece junto con la respuesta de tu bot
                                                        </div>
                                                    </div>


                                                    <div className="boton-bottom">
                                                        <Button color="primary" variant="contained" onClick={() => this.onGuardarHandler()} >
                                                            Guardar
                                                        </Button>
                                                    </div>

                                                </div>

                                                {/*
                                        <div className="right-widget-hide">
                                                
                                            {companyData.cuenta ?
                                                <iframe className="iframe" src={`${process.env.REACT_APP_CHAT_WIDGET}?companyId=${companyData.cuenta}`} />
                                                : <h1>La compañia selecciona no existe</h1>
                                            }

                                            <ComponentWidget
                                                company={companyData.cuenta} />
                                            
                                        </div>
                                                */}

                                            </Paper>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        getBotsSelector: selectGetBots(state),
    };
};

const mapDispachToProps = dispatch => {
    return {
        uploadIcon: (value) => dispatch({ type: UPLOAD_ICON, value }),
        getBots: (value) => dispatch({ type: GET_BOTS, value }),
        updateProfile: (value) => dispatch({ type: UPDATE_PROFILE, value }),
        uploadImage: () => dispatch({ type: UPLOAD_IMAGE, value: 1 }),


    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentConfiguration);