import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, menuItemsOCR, PATH_API } from '../../../Constants';
import { firebaseDatabase, firebaseField, storage} from "../../../controller/firebase";
import moment from "moment";

import {
  COMPANY_DATA,
  CROSS_DOC,
  DOWNLOAD_DOC,
  GET_DOC_FALL,
  GET_DOC_PROC,
  GET_OCR_DOC,
  GET_USERS, 
  LOAD_MENU, 
  SHOW_DOC_PROC,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
  companyDataSuccess,
  getOCRDocumentosSuccess,
  documentosProcesadosSuccess,
  documentosFallidosSuccess,
  downloadDocuSuccess,
  showDocumentosProcesadosSuccess,
  crossDocSuccess,
} from './actions';

import { getFromSession } from "../../../controller/session";

function* getUsers() {
  const urlUsers = `${PATH_API}${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
    method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if (fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const { data } = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }

  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}

function* loadMenuImageOCR(action) {
  let list = [];
  let company = getFromSession("company_id");
  //let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItemsOCR.forEach((menu) => {
    if (menu.roles.includes(rol)) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}

function* companyData(action) {

  const user = getFromSession("currentUser");
  let resp = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  yield put(companyDataSuccess(resp));
  return resp
}



function* getOCRDocumentos(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let respuesta = [];
  try {
    yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get().then(docs => {
        docs.forEach(doc => {
          resp = doc.data();
        });
      });


    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      .get().then(docs => {
        docs.forEach(doc => {
          const documentData = doc.data();
          if (documentData.estado === 1) {
            const idDoc = doc.id
            documentos.push(idDoc);
          }
        });
      });
    for (const id of documentos) {
      yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .get().then(docs => {
          docs.forEach(doc => {
            const documentData = doc.data();
            //console.log("documentData", documentData)
            const dataDocument = {
              lote: id,
              fecha: documentData.fecha_cargue ? moment(documentData.fecha.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
              name: documentData.datosDocumento.name,
              size: documentData.datosDocumento.tamano,
              id: doc.id,
              resumen: documentData.resumen ? documentData.resumen : ''
            }
            respuesta.push(dataDocument);
          });
        });
    }



  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  console.log("documentos", respuesta)

  yield put(getOCRDocumentosSuccess(respuesta));
  return respuesta
}


function* getDocumentosFallidos(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let respuesta = [];
  try {
    yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get().then(docs => {
        docs.forEach(doc => {
          resp = doc.data();
        });
      });


    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      .get().then(docs => {
        docs.forEach(doc => {
          const documentData = doc.data();
          if (documentData.estado === 3) {
            const idDoc = doc.id
            documentos.push(idDoc);
          }
        });
      });
      for (const id of documentos) {
        yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
          .get().then(docs => {
            docs.forEach(doc => {
              const documentData = doc.data();
              if(documentData.datosDocumento.estado === 2){
                const dataDocument = {
                  lote: id,
                  fecha: documentData.fecha_cargue ? moment(documentData.fecha.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                  name: documentData.datosDocumento.name,
                  size: documentData.datosDocumento.tamano,
                  id: doc.id,
                  resumen: documentData.resumen ? documentData.resumen : ''
                }
                respuesta.push(dataDocument);
              }
            });
          });
      }
  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  //console.log("documentos fallidos misDoc", respuesta)

  yield put(documentosFallidosSuccess(respuesta));
}

function* mostrarDocumentosProcesados(action) {
  console.log("ACTION MOSTRAR DOCUMENTOS SAGA CRUCE", action)
  let user = getFromSession("currentUser");
  let dataVistaPrevia = [];
  let resp = false
  let dataDocument = false;
  let datosRender = [];
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  try { //.collection(`company/${company}/configuration`).doc(`${document}`)
    const documentRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('configuration').doc('CLIENT_FORM');

    // Obtén los datos del documento
    documentRef.get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data().value;
         // console.log('datadata', data);
          for(const type in data){
            if (data.hasOwnProperty(type)) {
              const objetoInterno = data[type];
              //console.log(`Objeto externo: ${type}`);
              for (const claveInterna in objetoInterno) {
                if (objetoInterno.hasOwnProperty(claveInterna)) {
                  const valorInterno = objetoInterno[claveInterna];
                 // console.log(`Clave: ${claveInterna}, Valor: ${valorInterno}`);
                  dataVistaPrevia.push(valorInterno)
                }
              }
            //  console.log(`Clave: ${type}, Valor: ${valorInterno}`);
            }

          }
        } else {
          console.log('El documento no existe');
        }
      })
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  try {
    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`).get().then(docs => {
      docs.forEach(doc => {
        //console.log('datosDocumento', doc.data())
        if (doc.data().datosDocumento.name === action.value.name) {
          //console.log('datosDocumento if', doc.data().datosDocumento)
          dataDocument = doc.data().respuesta.extraccion

          for (const key in dataDocument) {
            if (dataDocument.hasOwnProperty(key)) { // Para asegurarte de que solo consideras las propiedades propias del objeto
              for (let i = 0; i < dataVistaPrevia.length; i++) {
                if (key === dataVistaPrevia[i]) {
                  //datosRender.push(key);
                  //console.log(`La clave ${key} coincide con el elemento ${dataVistaPrevia[i]} en el arreglo.`);
                  //console.log("dataDocument", dataDocument)
                  datosRender[key] = dataDocument[key];
                }
              }
            }
          }


        }
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
 // console.log("dataVistaPrevia",dataVistaPrevia)
 // console.log("dataDocument",dataDocument)
 // console.log("datosRender",datosRender)
 

  yield put(showDocumentosProcesadosSuccess(dataDocument))
}

function* downloadDoc(action) {
  console.log("URL PDF SAGA CRUCE" , action)
  try {
    // Obtén una referencia al almacenamiento de Firebase
    const storageRef = storage.refFromURL(action.value);

    // Obtén la URL de descarga del documento
    const downloadUrl = yield storageRef.getDownloadURL();

    // Crea un enlace temporal para descargar el documento
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'documento.pdf'; // Establece el nombre del archivo
    link.target = '_blank'; // Abre el enlace en una nueva pestaña

    // Simula un clic en el enlace para iniciar la descarga
    link.click();
  } catch (error) {
    console.error('Error al descargar el documento:', error);
  }

  yield put (downloadDocuSuccess())
}

function* crossDoc(action) {
  console.log("CRUCEEEE CRUCE")
  let user = getFromSession("currentUser");
  let dataVistaPrevia = [];
  let resp = false
  let cruce = false;
  let documentos = [];
  let consultaData = [];
  let showDocument = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  try { //.collection(`company/${company}/configuration`).doc(`${document}`)
    const documentRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('configuration').doc('ENCUESTA');

    // Obtén los datos del documento
    documentRef.get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data().value;
          // console.log('datadata', data);
          for (const type in data) {
            if (data.hasOwnProperty(type)) {
              const objetoInterno = data[type];
              //console.log(`Objeto externo: ${type}`);
              for (const claveInterna in objetoInterno) {
                if (objetoInterno.hasOwnProperty(claveInterna)) {
                  const valorInterno = objetoInterno[claveInterna];
                  // console.log(`Clave: ${claveInterna}, Valor: ${valorInterno}`);
                  dataVistaPrevia.push(valorInterno)
                }
              }
            }

          }
        } else {
          console.log('El documento no existe');
        }
      })
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  try {
    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)
      .doc(action.value.id)
      .get().then(doc => {
        for (const data of dataVistaPrevia) {
          if (data === 'text' || data === 'id')
           // console.log("DATA", data)
          cruce = doc.data().respuesta?.extraccion?.id
        }

      });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
    //.where('estado', '==', 1)
    .get();

  documentosSnapshot.forEach(doc => {
    const idDoc = doc.id
    documentos.push(idDoc);
  });

  for (const id of documentos) {
    const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
      .where('respuesta.extraccion.id', '==', cruce)
      .get();

    documentosOCRSnapshot.forEach(doc => {
      const documentData = doc.data();
     // console.log("documentData", documentData)
      const dataDocument = {
        lote: id,
        fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
        name: documentData.datosDocumento.name,
        size: documentData.datosDocumento.tamano,
        id: doc.id,
        resumen: documentData.resumen ? documentData.resumen : ''
      };
      consultaData.push(dataDocument);


    });
  }
  if (consultaData.length !== 0) {
    showDocument = true
  }
  //console.log("DOCUMENTOS CRUCE", consultaData)



  yield put(crossDocSuccess({
    consultaData: consultaData,
    showDocument: showDocument
  }))
}


function* getDocumentosProc(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      .where('estado', '==', 1)
      .get();

    documentosSnapshot.forEach(doc => {
      const idDoc = doc.id
      documentos.push(idDoc);
    });

    for (const id of documentos) {
      const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .where('datosDocumento.estado', '==', 3).get();

      documentosOCRSnapshot.forEach(doc => {
        const documentData = doc.data();
        const dataDocument = {
          lote: id,
          fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
          name: documentData.datosDocumento.name,
          size: documentData.datosDocumento.tamano,
          id: doc.id,
          resumen: documentData.resumen ? documentData.resumen : ''
        };
        consultaData.push(dataDocument);


      });

    }

  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  yield put(documentosProcesadosSuccess(consultaData))

} 

export function* watchUsers() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(LOAD_MENU, loadMenuImageOCR);
  yield takeLatest(COMPANY_DATA, companyData);
  yield takeLatest(GET_OCR_DOC, getOCRDocumentos);
  yield takeLatest(GET_DOC_PROC, getDocumentosProc);
  yield takeLatest(GET_DOC_FALL, getDocumentosFallidos);
  yield takeLatest(DOWNLOAD_DOC, downloadDoc);
  yield takeLatest(SHOW_DOC_PROC, mostrarDocumentosProcesados);
  yield takeLatest(CROSS_DOC, crossDoc); 
  
  





}
