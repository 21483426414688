import moment from "moment";
import { getFromSession } from '../../controller/session'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import {
    DATA_LINECHART,
    TMPESP_PROM,
    TMPRESP_PROM,
    TMPCONV_PROM,
    CONVS_RESUELTAS,
    CONVS_CANAL,
    CONVS_CERRADAS,

    LISTAR_AGENTES,
    LISTAR_ESTADOS,
    LANZAR_EVENTOS,
    FILTRAR_BUSQUEDA,

    SELECT_CONVERSATIONDETAIL,
    MODAL_DESCARGAR_DATOS,
    SINGLE_BUSQUEDA,
    GRAFICOS_TEMAS,
    CONVS_ABANDONADAS,
    OCULTAR_ESTADISTICAS,
    CONVS_AGENTES,
    CONVS_NOTAS_OCULTAS,
    CONVS_CONECTED_USERS,
    CONVS_TRANSFERENCIAS,
    HEADER_TABLE_NOTAS,
    HEADER_TABLE_TRANSFERENCIAS,
    HEADER_TABLE_CONECTEDUSERS,
    CONVS_CHATBOT,
    HEADER_TABLE_CHATBOT,
    CONVS_COLAS_ATENCION,
    HEADER_TABLE_COLAS_ATENCION,
    CONVS_ATENDIDAS_CHATBOT,
    catalogoE,
    catalogoSa,
    GRAFICA_QUERY_SENTIMENT,
    GRAFICA_ENCUESTA_GENERAL,
    PAGINAR_CONVS_CERRADAS,
    DESCARGAR_CSV_CONVS_CERRADAS,
    REPORTE_WS_FALLIDOS,
    DATOS_CONVS_CHATBOT,
    PAGINAR_DATA_CONVS_CHABOT,
    DATOS_RESP_UNKNOWN_BOT,
    PAGINAR_DATA_UNKNOWNRESP_BOT,
    GRAFICA_TASA_COINCIDENCIAS,
    DATOS_COMENTARIOS_INSATISFACCION,
    DESCARGAR_CSV,
    PAGINAR_COMENTARIOS_INSATISFACCION,
    DATOS_INTENTS_PORCENTAJE,
    GENERAR_DATA_CSV,
    DATOS_GRAFICA_TOP_10,
    PAGINAR_DATA_CONVS_PRESUPUESTOS,
    DATOS_CONVS_PRESUPUESTOS,
    TMP_PROM_AGENTES,
    CONVS_RESUELTAS_AGENTES,
    GRAFICO_PROM_RESP_AGENTES,
    GRAFICO_PROM_ESP_AGENTES,
    CONVERSACIONES_SERVICIOS_EXTERNOS,
    SEND_GRAFICO_DATOS_REPORTE_HUECOS,
    GET_GRAFICO_DATOS_REPORTE_HUECOS,
    GET_TABLA_DATOS_ZONA_PARQUEO,
    GET_TABLA_DATOS_REGISTRO_GAB,
    GET_TABLA_DATOS_ATENCION_CANAL,
    GET_TABLA_DATOS_ATENCION_AGENTE,
    HEADERS_WS,
    ENCUESTAS_AGENTES,
    CONVS_CERRADAS_AGENTES,
    CONVS_CERRADAS_AGENTES_SUCCESS,
    PAGINAR_CONVS_CERRADAS_AGENTES,
    DESCARGAR_CSV_CONVS_CERRADAS_AGENTES,
    FILTRAR_BUSQUEDA_AGENTE,
    LIMPIAR_FORM

} from './constants';
import axios from "axios";
import { put, call, takeLatest, take, select } from "redux-saga/effects";
import {
    obtenerDatosGraficaLineChartSuccess,
    obtenerTmpPromEspConvsCerradasSuccess,
    obtenerTmpPromRespConvsCerradasSuccess,
    obtenerTmpPromConvSuccess,
    obtenerConvsCerradasResueltasSuccess,
    obtenerCanalConvsCerradasSuccess,
    listarAgentesSuccess,
    listarEstadosSuccess,

    obtenerConvsCerradasSuccess,
    filtrarBusquedaSuccess,
    selectConversationDetailSuccess,
    abrirModalDescargarDatosSuccess,
    abrirModalDescargarDatosError,
    singleBusquedaSuccess,
    obtenerDatosGraficaTemasSuccess,
    obtenerConvsCerradasError,
    showToast,
    obtenerDataPieConvsAbandonadasError,
    obtenerDataPieConvsAbandonadasSuccess,
    ocultarEstadisticasConvsAbandonadasSuccess,
    obtenerDataConvsAgentesSuccess,
    obtenerDatatTableAgentesSuccess,
    obtenerDataNotasOcultasSuccess,
    obtenerDataConectedUsersSuccess,
    obtenerDataTransferenciasSuccess,
    obtenerDataChatBotSuccess,
    saveDataNotasOcultas,
    saveDataTransferencias,
    modalEsperaData,
    obtenerColasAtencionSuccess,
    obtenerPieConvsAtendidasChatBotSuccess,
    obtenerDataGraficaQuerySentimentError,
    obtenerDataGraficaQuerySentimentSuccess,
    obtenerDatosGraficaEncuestaSuccess,
    paginarConvsCerradasError,
    paginarConvsCerradasSuccess,
    descargarCSVconvsCerradasError,
    descargarCSVconvsCerradasSuccess,
    obtenerDataWsFallidosSuccess,
    obtenerDatosConvsChatbotSuccess,
    paginarDatosConvsChatbotSuccess,
    obtenerDatosRespBotSuccess,
    obtenerDatosRespUnknownBotSuccess,
    paginarDatosRespUnknownBotSuccess,
    obtenerDataTasaCoincidenciaBotSuccess,
    obtenerDataComentariosInsatisfaccionSuccess,
    paginarDataComentariosInsatisfaccionSuccess,
    obtenerDatosIntentsPorcentajeUsoSuccess,
    generarCsvSuccess,
    obtenerDatosGraficaTop10Success,
    paginarDatosConvsPresupuestosSuccess,
    obtenerDatosConvsPresupuestosSuccess,
    obtenerTmpPromAgentesSuccess,
    obtenerConvsResueltasAgentesSuccess,
    obtenerConvsResueltasAgentesError,
    graficarTmpPromRespAgentesSuccess,
    graficarTmpPromEspAgentesSuccess,
    tablesPromAgent,
    sendTablaDatosReporteHuecos,
    sendGraficoDatosReporteHuecos,
    generarCsvConvsChatbotSuccess,
    generarCsvDataUnknownBotSuccess,
    generarCsvDataInsatisfaccionSuccess,
    sendTablaDatosZonaParqueo,
    sendTablaDatosRegistroGAB,
    sendTablaDatosAtencionCanal,
    sendTablaDatosAtencionAgente,
    encuestasAgentesSucess,
    obtenerDataConvsAgentesError,
    encuestasAgentesError,
    obtenerConvsCerradasAgenteSuccess,
    paginarConvsCerradasAgentesSuccess,
    paginarConvsCerradasAgentesError,
    descargarCSVconvsCerradasAgentesSuccess,
    filtrarBusquedaAgenteSuccess,
    filtrarBusquedaError,
    saveSearchBigQueryTable,
    saveSearchTableGenerales,
    manageSpiner,


} from './actions';

import { search, singleBusqueda, obtenerMensajes, contarConvsPorAgente, generateConsultaAgente }
    from "../../controller/conversation";

import { consultaProperty } from '../../controller/configuration';

import { lstrEstados } from "../../controller/estados";
import { lstrAgentes } from "../../controller/agentes";
import { obtenerRangoDeFechas } from "../../controller/manejoRangoFechas";

import {
    obtenerDataGraficaPieConvsAbandonadas,
    traerDatosGraficas,
    traerDatosGraficasBigQuery,

} from './../../controller/estadisticas';


import { firebaseDatabase, Filter, Timestamp, timestamp } from "../../controller/firebase";
import { getDataNotasOcultas, getDataTransferencias, getSearchBigqueryTable } from "./selectors";
import { traerDataGenerarCSV } from "../../controller/csv";
import { initialize } from "redux-form";
// import { get } from "jquery";



function generarQueryConsultaPaginada(reference, first, fecha, nextPage, rows) {

    let consulta = reference
    if (first == 0) {
        if (fecha) {
            consulta = reference.limit(rows)
        } else if (!nextPage) {
            consulta = reference.limit(rows)
        } else {
            consulta = false
        }

    } else if (first > 0) {
        if (nextPage) {
            consulta = reference.startAfter(fecha).limit(rows)
        } else {
            consulta = reference.endBefore(fecha).limitToLast(rows)
        }
    }
    return consulta;
}

function* obtenerConvsCerradas(action, limit = 10) {

    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);
    const company = getFromSession("company_id");
    try {

        let { convsCerradasDisplay, columns } = yield consultarConvsCerradas(timeIni, timeFin, company, limit)

        let response = {
            convsCerradasDisplay,
            columns
        }

        yield put(obtenerConvsCerradasSuccess(response));

    } catch (error) {
        console.error('saga.obtenerConvsCerradas', error);
        yield put(obtenerConvsCerradasError(error));

    }

}

function* obtenerConvsCerradasAgentes(action, limit = 10) {

    yield put(manageSpiner({ key: "tablaConvsCerradasAgentes", state: true }));
    let rango = obtenerRangoDeFechas(action.value);

    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);
    const company = getFromSession("company_id");
    try {

        let { convsCerradasDisplay, columns } = yield consultarConvsCerradasAgentes(timeIni, timeFin, company, limit)

        let response = {
            convsCerradasDisplay,
            columns
        }

        yield put(obtenerConvsCerradasAgenteSuccess(response));
        yield put(manageSpiner({ key: "tablaConvsCerradasAgentes", state: false }));

    } catch (error) {
        console.error('saga.obtenerConvsCerradasAgentes', error);
        yield put(obtenerConvsCerradasError(error));

    }

}

function* consultarConvsCerradas(timeIni, timeFin, company, agente, limit = false) {

    let convsCerradas = [];
    let encuesta = false

    const fechaIni = new Date(timeIni);
    const fechaFin = new Date(timeFin);

    let reference = firebaseDatabase.collection(`company/${company}/conversations`).
        //where('estado', "in", [10, 11]).
        where('fecha_ini', '>=', fechaIni).
        where('fecha_ini', "<=", fechaFin).orderBy("fecha_ini", "asc")


    //or(where('agente.mail', "==", agente),Where('agenteCalificado.mail', "==", agente))


    let { isSurveyEnabled, preguntasEncuesta } = yield getFromSession('configurationVariables');
    if (isSurveyEnabled) {
        encuesta = preguntasEncuesta
    }

    try {
        let consultaBig = {
            limit,
            page: 0,
            fechaIni: timeIni,
            fechaFin: timeFin,
            queryF: reference
        }

        if (limit) {
            reference = reference.limit(limit)
        }



        yield put(saveSearchBigQueryTable({ ...consultaBig }))

        if (agente) {
            const datas = yield generateConsultaAgente(fechaIni, fechaFin, agente, company)
            datas.forEach(conv => {
                convsCerradas.push({
                    "fechaIni": conv.fecha_ini.toDate(),
                    "fechaFin": conv.fecha_fin?.toDate(),
                    "fechaAbandono": conv.fecha_abandono ? conv.fecha_abandono.toDate() : false,
                    "fecha_ini": conv.fecha_ini,
                    "agente": conv.agenteCalificado ? conv.agenteCalificado : conv.agente,
                    "asunto": conv.asunto,
                    "canal": conv.canal,
                    "dispositivo": conv.device ? conv.device : "No detectado",
                    "cliente": conv.cliente,
                    "resuelta": conv.resuelta,
                    "encuesta": conv.encuesta ? conv.encuesta : false,
                    "tema": conv.tema ? conv.tema : false,
                    "idConv": conv.id,
                    "aceptaTerminos": emptyObject(conv.aceptaTerminos),
                    "suscribeContactar": emptyObject(conv.suscribeContactar),
                });
            });
        } else {
            yield reference.get().then(result => {

                result.forEach(doc => {
                    let conv = doc.data()
                    convsCerradas.push({
                        "fechaIni": conv.fecha_ini.toDate(),
                        "fechaFin": conv.fecha_fin?.toDate(),
                        "fechaAbandono": conv.fecha_abandono ? conv.fecha_abandono.toDate() : false,
                        "fecha_ini": conv.fecha_ini,
                        "agente": conv.agenteCalificado ? conv.agenteCalificado : conv.agente,
                        "asunto": conv.asunto,
                        "canal": conv.canal,
                        "dispositivo": conv.device ? conv.device : "No detectado",
                        "cliente": conv.cliente,
                        "resuelta": conv.resuelta,
                        "encuesta": conv.encuesta ? conv.encuesta : false,
                        "tema": conv.tema ? conv.tema : false,
                        "idConv": doc.id,
                        "aceptaTerminos": emptyObject(conv.aceptaTerminos),
                        "suscribeContactar": emptyObject(conv.suscribeContactar),
                    });
                });
            })
        }



        const { convsCerradasDisplay, columns } = yield ordenarDatosTabla(convsCerradas, encuesta);
        return { convsCerradasDisplay, columns }

    } catch (error) {
        console.error('saga.consultarConvsCerradas', error);
        throw error

    }


}
async function consultarConvsCerradasAgentes(timeIni, timeFin, company, limit = false) {
    let convsCerradas = [];
    let encuesta = false

    let reference = firebaseDatabase.collection(`company/${company}/conversations`).where('estado', "in", [10, 11]).
        where("fecha_aceptacion", ">", timeIni).where("fecha_aceptacion", "<", timeFin).orderBy("fecha_aceptacion", "desc")

    let { isSurveyEnabled, preguntasEncuesta } = await getFromSession('configurationVariables');
    if (isSurveyEnabled) {
        encuesta = preguntasEncuesta
    }

    try {
        if (limit) {
            reference = reference.limit(limit)
        }
        await reference.get().then(result => {

            result.forEach(doc => {
                let conv = doc.data()

                convsCerradas.push({
                    "fechaIni": conv.fecha_ini.toDate(),
                    "fechaFin": conv.fecha_fin?.toDate(),
                    "fechaAbandono": conv.fecha_abandono?.toDate(),
                    "fecha_ini": conv.fecha_ini,
                    "fecha_aceptacion": conv.fecha_aceptacion?.toDate(),
                    "agente": conv.agente,
                    "asunto": conv.asunto,
                    "canal": conv.canal,
                    "dispositivo": conv.device ? conv.device : "No detectado",
                    "cliente": conv.cliente,
                    "resuelta": conv.resuelta,
                    "encuesta": conv.encuesta ? conv.encuesta : false,
                    "tema": conv.tema ? conv.tema : false,
                    "idConv": doc.id,
                    "fecha_asignacion": conv.fecha_asignacion?.toDate(),
                    "aceptaTerminos": emptyObject(conv.aceptaTerminos),
                    "suscribeContactar": emptyObject(conv.suscribeContactar),
                });
            });
        })


        const { convsCerradasDisplay, columns } = await ordenarDatosTabla(convsCerradas, encuesta);

        return { convsCerradasDisplay, columns }

    } catch (error) {
        console.error('saga.consultarConvsCerradas', error);
        throw error

    }


}


async function ordenarDatosTabla(convsCerradas, preguntasEncuesta = false) {
    try {

        let convsCerradasDisplay = [];
        let columns = [];
        let asunto;
        let fechaIni;
        let fechaFin;
        let apellidos;
        let contacto;
        let fecha_aceptacion;
        let fecha_asignacion;


        if (convsCerradas) {
            for (const convCerrada of convsCerradas) {
                fechaIni = moment(convCerrada.fechaIni).format("lll");
                //Si es una conversacion que fue abandonada por lo tanto no tiene fecha_fin entonces
                //significa que fue abandonada y su estado es 12, entonces el campo es fecha_abandono
                if (convCerrada.fechaAbandono) {
                    fechaFin = moment(convCerrada.fechaAbandono).format("lll");
                } else if (convCerrada.fechaFin) {
                    fechaFin = moment(convCerrada.fechaFin).format("lll")
                }

                if (convCerrada.fechaAsignacion || convCerrada.fecha_asignacion) {
                    fecha_asignacion = moment(convCerrada.fecha_asignacion).format("lll")
                }
                if (convCerrada.fechaAceptacion || convCerrada.fecha_aceptacion) {
                    fecha_aceptacion = moment(convCerrada.fecha_aceptacion).format("lll")
                }

                //lo siguiente se hace para evitar que entren a la tabla conversaciones mal creadas cuyo asunto 
                //es un dato tipo mapa
                if (typeof convCerrada.asunto == 'string') {
                    asunto = convCerrada.asunto;

                }

                apellidos = convCerrada.agente ? convCerrada.agente.apellidos ? convCerrada.agente.apellidos : ' ' : '';

                if (convCerrada.canal === 'whatsapp') {
                    contacto = convCerrada.cliente.telefono ? convCerrada.cliente.telefono : 'Sin teléfono';
                } else {
                    if (!convCerrada.cliente || !convCerrada.cliente.mail) {
                        console.log('conv mala', convCerrada);

                    } else {
                        contacto = convCerrada.cliente.mail ? convCerrada.cliente.mail : 'Sin correo';
                    }

                }

                const datos = {
                    fechaIni: fechaIni,
                    fechaFin: fechaFin,
                    agente: convCerrada.agente ? convCerrada.agente.nombres ? convCerrada.agente.nombres + ' ' + apellidos : convCerrada.agente.nombre : 'No existe el campo agente',
                    nickName: convCerrada.agente ? convCerrada.agente.nickname ? convCerrada.agente.nickname : '------' : 'No existe el campo agente',
                    emailAgente: convCerrada.agente ? convCerrada.agente.mail ? convCerrada.agente.mail : '------' : 'No existe el campo agente',
                    cliente: convCerrada.cliente.nombre + ' - ' + convCerrada.cliente.tipoId + ' - ' + contacto,
                    canal: convCerrada.canal,
                    dispositivo: convCerrada.dispositivo,
                    asunto: asunto ? asunto : 'Sin Asunto',
                    temaNivel1: convCerrada.tema && convCerrada.tema.nivel1 ? convCerrada.tema.nivel1 : 'SIN TIPIFICAR',
                    temaNivel2: convCerrada.tema && convCerrada.tema.nivel2 ? convCerrada.tema.nivel2 : 'SIN TIPIFICAR',
                    temaNivel3: convCerrada.tema && convCerrada.tema.nivel3 ? convCerrada.tema.nivel3 : 'SIN TIPIFICAR',
                    resuelta: convCerrada.resuelta ? 'Si' : 'No',
                    terminos: convCerrada.aceptaTerminos === true ? "Acepta" : convCerrada.aceptaTerminos === false ? "No acepta" : "",
                    contacto: convCerrada.suscribeContactar === true ? "Si" : convCerrada.suscribeContactar === false ? "No acepta" : "",
                    idReference: convCerrada.idConv,
                    estado: convCerrada.estado,
                    nombreCliente: convCerrada.cliente.nombre,
                    contactoCliente: contacto,
                    fecha_aceptacion: fecha_aceptacion ? fecha_aceptacion : false,
                    fecha_asignacion: fecha_asignacion ? fecha_asignacion : false
                }
                if (convCerrada.fecha_aceptacion || convCerrada.fecha_asignacion) {
                    columns = [
                        { field: 'idReference', header: 'ID Conversación' },
                        { field: 'fechaIni', header: 'Fecha Inicio' },
                        { field: 'fechaFin', header: 'Fecha Fin' },
                        { field: 'fecha_asignacion', header: 'Fecha Asignación' },
                        { field: 'fecha_aceptacion', header: 'Fecha Aceptación' },
                        { field: 'agente', header: 'Agente' },
                        { field: 'nickName', header: 'Nickname' },
                        { field: 'emailAgente', header: 'Email del agente' },
                        { field: 'cliente', header: 'Cliente' },
                        { field: 'canal', header: 'Canal' },
                        { field: 'dispositivo', header: 'Dispositivo origen' },
                        { field: 'asunto', header: 'Asunto' },
                        { field: 'temaNivel1', header: 'Nivel 1' },
                        { field: 'temaNivel2', header: 'Nivel 2' },
                        { field: 'temaNivel3', header: 'Nivel 3' },
                        { field: 'resuelta', header: 'Resuelta' },
                        { field: 'terminos', header: 'Terminos y condiciones' },
                        { field: 'contacto', header: 'Suscripción para contactar' },

                    ]
                } else {

                    columns = [
                        { field: 'idReference', header: 'ID Conversación' },
                        { field: 'fechaIni', header: 'Fecha Inicio' },
                        { field: 'fechaFin', header: 'Fecha Fin' },
                        { field: 'agente', header: 'Agente' },
                        { field: 'nickName', header: 'Nickname' },
                        { field: 'emailAgente', header: 'Email del agente' },
                        { field: 'cliente', header: 'Cliente' },
                        { field: 'canal', header: 'Canal' },
                        { field: 'dispositivo', header: 'Dispositivo origen' },
                        { field: 'asunto', header: 'Asunto' },
                        { field: 'temaNivel1', header: 'Nivel 1' },
                        { field: 'temaNivel2', header: 'Nivel 2' },
                        { field: 'temaNivel3', header: 'Nivel 3' },
                        { field: 'resuelta', header: 'Resuelta' },
                        { field: 'terminos', header: 'Terminos y condiciones' },
                        { field: 'contacto', header: 'Suscripción para contactar' },
                    ]
                }

                if (preguntasEncuesta) {
                    for (let pregunta in preguntasEncuesta) {
                        datos[preguntasEncuesta[pregunta].nombreCorto] = convCerrada.encuesta && convCerrada.encuesta[preguntasEncuesta[pregunta].nombreCorto] ? convCerrada.encuesta[preguntasEncuesta[pregunta].nombreCorto] : '';
                        columns.push({ field: preguntasEncuesta[pregunta].nombreCorto, header: preguntasEncuesta[pregunta].nombre })
                    }

                }
                convsCerradasDisplay.push(datos);
            }
        }
        return { convsCerradasDisplay, columns };
    } catch (error) {
        console.error('saga.ordenarDatosTabla', error);
        throw error;
    }
}



function* selectConversationDetail(convData) {

    try {
        let idReference = convData.value;
        let conversation = yield obtenerMensajes(idReference, 1500);

        yield put(selectConversationDetailSuccess({ conversation }))

    } catch (error) {
        console.error('saga.selectConversationDetail', error);
        throw error;
    }

}

function* abrirModalDescargarDatos(convData) {
    try {
        let dataInfo = convData.value;

        let idReference = convData.value.idReference;

        let conversation = yield obtenerMensajes(idReference, 1600);

        yield put(abrirModalDescargarDatosSuccess({ dataInfo, conversation, verModalDescargarConversacion: true }))

    } catch (error) {
        console.error('saga.abrirModalDescargarDatos', error);
        yield put(abrirModalDescargarDatosError(error));

    }

}


function* obtenerDatosGraficaLineChart(action) {

    try {

        yield put(manageSpiner({ key: "lineChart", state: true }));
        let rango = obtenerRangoDeFechas(action.value);
        let whatsapp = [];
        let fecha = [];
        let web = [];
        let total = [];
        let ejeY = [];
        let fechaCor = [];
        let arrayCsv = [];
        let horaCor = [];
        let horaCor2 = [];

        let color = '#d35ce6';
        let colorFondo = '#e49cf0'
        let label = 'Conversaciones cerradas';

        let payload = {
            'dataLabel': label,
            'color': color,
            'colorBackground': colorFondo,
            'graficoGrande': true
        }

        let resp = yield traerDatosGraficasBigQuery(rango, "estadisticasGenerales");
        if (resp) {

            resp.graficoPpal.map(item => {
                fecha.push(item.fec.value);
            });

            for (var i = 0; i < fecha.length; i++) {
                var split = fecha[i].split("T");
                fechaCor.push(split[0]);
                horaCor.push(split[1]);
            }

            for (var i = 0; i < fecha.length; i++) {
                var split = horaCor[i].split(".");
                horaCor2.push(split[0]);
            }

            if (action.value === 'HOY' || action.value === 'AYER') {
                payload["ejeX"] = horaCor2;
            } else {
                payload["ejeX"] = fechaCor;
            }

            resp.graficoPpal.map(item => {
                whatsapp.push(item.whatsapp);
            });
            resp.graficoPpal.map(item => {
                web.push(item.web);
            });

            resp.graficoPpal.map(item => {
                total.push(item.total);
            });
        }
        ejeY.push(whatsapp)
        ejeY.push(web)
        ejeY.push(total)

        resp.graficoPpal.map((item, i) => {
            arrayCsv.push({
                fecha: payload["ejeX"][i],
                total: item.total,
                web: item.web,
                whatsapp: item.whatsapp,
            })
        });




        payload["labels"] = ['WhatsApp', 'Web', 'Total'];
        payload["ejeY"] = ejeY;
        payload["arrayCsv"] = arrayCsv;

        yield put(obtenerDatosGraficaLineChartSuccess({ payload, 'size': resp.total }))
        yield put(manageSpiner({ key: "lineChart", state: false }));

    } catch (error) {
        console.error('saga.obternerDatosGraficaLineChart', error);

    }
}


function* obtenerTmpPromEspConvsCerradas(action) {
    try {

        let rango = obtenerRangoDeFechas(action.value);
        let { respuesta } = yield traerDatosGraficas(rango, 'tmpPromEsperaConvsCerradas')

        yield put(obtenerTmpPromEspConvsCerradasSuccess(respuesta))

        //yield obtenerTmpPromEsperaConvsCerradas(rango.fechaIni, rango.fechaFin, obtenerTmpPromEspConvsCerradasSuccess);

    } catch (error) {
        console.error('saga.obtenerTmpPromedioEspConvsCerradas', error);
    }
}

function* obtenerTmpPromRespConvsCerradas(action) {
    try {

        let rango = obtenerRangoDeFechas(action.value);

        let { respuesta } = yield traerDatosGraficas(rango, 'tmpPromRespConvsCerradas')


        yield put(obtenerTmpPromRespConvsCerradasSuccess(respuesta))


    } catch (error) {
        console.error('saga.obtenerTmpPromRespConvsCerradas', error);
        throw error;

    }
}

function* obtenerDataPieConvsAbandonadas(action) {
    try {
        yield put(manageSpiner({ key: "pieAbandono", state: true }))
        let rango = obtenerRangoDeFechas(action.value);
        let labels = ['Atendidas', 'Abandonadas']
        let titulo = 'Conversaciones atendidas';
        let tooltip = 'Gráfico que muestra cuantas conversaciones fueron o no atendidas'
        let parametros = {
            'labels': labels,
            'titulo': titulo,
            'tooltip': tooltip
        }
        yield put(manageSpiner({ key: "pieAbandono", state: false }))
        yield obtenerDataGraficaPieConvsAbandonadas(rango.fechaIni, rango.fechaFin, obtenerDataPieConvsAbandonadasSuccess, parametros)

    } catch (error) {
        console.error('saga.obtenerDataPieConvsAbandonadas', error);
        yield obtenerDataPieConvsAbandonadasError(error);

    }
}

function* obtenerConvsCerradasResueltas(action) {
    try {
        yield put(manageSpiner({ key: "pie1", state: true }));
        let rango = obtenerRangoDeFechas(action.value);

        let labels = ['Resueltas', 'No resueltas']
        let resueltas = []
        let convGrafica = [];
        let titulo = 'Conversaciones resueltas';
        let tooltip = 'Gráfico que muestra cuantas conversaciones fueron o no resueltas'
        let payload = {
            'labels': labels,
            'titulo': titulo,
            'tooltip': tooltip
        }

        let resp = yield traerDatosGraficasBigQuery(rango, "estadisticasGenerales");
        if (resp) {

            resp.resueltas.map(item => {
                resueltas.push(item.f0_);
            });
        }
        convGrafica.push(labels)
        convGrafica.push(resueltas)
        payload['numeros'] = resueltas
        payload['graficas'] = convGrafica



        yield put(obtenerConvsCerradasResueltasSuccess(payload))
        yield put(manageSpiner({ key: "pie1", state: false }));


    } catch (error) {
        console.error('saga.obtenerConvsResueltas', error);
        throw error;
    }
}

function* ocultarEstadisticasConvsAbandonadas() {
    try {
        const company = getFromSession("company_id");
        let verAbandono = yield consultaProperty(company, 'VER_ABANDONO');
        yield put(ocultarEstadisticasConvsAbandonadasSuccess(verAbandono));
    } catch (error) {
        console.error('saga.ocultarEstadisticasConvsAbandonadas', error);
        throw error;
    }
}

function* obtenerCanalConvsCerradas(action) {
    try {
        yield put(manageSpiner({ key: "pie2", state: true }));
        let rango = obtenerRangoDeFechas(action.value);
        let canalesValue = []
        let canales = []
        let graficas = [];
        let titulo = 'Canal de la Conversación';
        let tooltip = 'Gráfico que muestra los canales usados en las conversaciones y el número de cada canal.'
        let payload = { 'titulo': titulo, 'tooltip': tooltip };

        let resp = yield traerDatosGraficasBigQuery(rango, 'estadisticasGenerales');
        if (resp) {

            resp.canales.map(item => {
                canales.push(item.canal);
            });

            resp.canales.map(item => {
                canalesValue.push(item.f0_);
            });
        }
        graficas.push(canales);
        graficas.push(canalesValue);

        payload['labels'] = canales;
        payload['numeros'] = canalesValue;
        payload['graficas'] = graficas

        yield put(obtenerCanalConvsCerradasSuccess(payload))
        yield put(manageSpiner({ key: "pie2", state: false }));
    } catch (error) {
        console.error(error, 'saga.obtenerCanalConv', error);
        throw error;

    }
}

function* obtenerPieConvsAtendidasChatBot(action) {
    try {
        yield put(manageSpiner({ key: "pie3", state: true }));
        let rango = obtenerRangoDeFechas(action.value);
        let chatbot = [];
        let agentes = [];
        let respuesta = [];
        let graficas = [];
        let labels = ['Chatbot', 'Agente']
        let titulo = 'Atendidas por el Chatbot';
        let tooltip = 'Gráfico que muestra las conversaciones que fueron atendidas por el Chatbot.'
        let payload = { 'titulo': titulo, 'tooltip': tooltip, 'labels': labels };

        let resp = yield traerDatosGraficasBigQuery(rango, "estadisticasGenerales");
        if (resp) {

            resp.chatbot.map(item => {
                chatbot.push(item.chatbot);
            });

            resp.chatbot.map(item => {
                agentes.push(item.agentes);
            });
        }
        respuesta.push(chatbot);
        respuesta.push(agentes);
        payload['numeros'] = respuesta;

        graficas.push(labels)
        graficas.push(respuesta)
        payload['graficas'] = graficas;

        yield put(obtenerPieConvsAtendidasChatBotSuccess(payload))
        yield put(manageSpiner({ key: "pie3", state: false }));
    } catch (error) {
        console.error('saga.obtenerPieConvsAtendidasChatBot', error);
        throw error;

    }



}

function* obtenerDatosGraficaTemas(action) {
    try {

        yield put(manageSpiner({ key: "pieTemas", state: true }));
        let rango = obtenerRangoDeFechas(action.value);
        let payload = yield traerDatosGraficas(rango, 'temasConvs')

        payload.graficaNivel1['titulo'] = 'Tipificación Nivel 1'
        payload.graficaNivel1['tooltip'] = 'Gráfico que muestra la concurrencia de los temas en nivel 1'
        payload.graficaNivel2['titulo'] = 'Tipificación Nivel 2'
        payload.graficaNivel2['tooltip'] = 'Gráfico que muestra la concurrencia de los temas en nivel 2'
        payload.graficaNivel3['titulo'] = 'Tipificación Nivel 3'
        payload.graficaNivel3['tooltip'] = 'Gráfico que muestra la concurrencia de los temas en nivel 3'

        yield put(obtenerDatosGraficaTemasSuccess(payload))
        //yield traerTemas(rango.fechaIni, rango.fechaFin, obtenerDatosGraficaTemasSuccess, payload)
        yield put(manageSpiner({ key: "pieTemas", state: false }));
    } catch (error) {
        console.error('saga.obtenerDatosGraficaTemas', error);
        throw error;

    }
}

function* obtenerTmpPromConv(action) {
    try {

        let rango = obtenerRangoDeFechas(action.value);
        //yield obtenerTmpPromConversacion(rango.fechaIni, rango.fechaFin, obtenerTmpPromConvSuccess);
        let { respuesta } = yield traerDatosGraficas(rango, 'tmpPromConversacion')

        yield put(obtenerTmpPromConvSuccess(respuesta));

    } catch (error) {
        console.error('saga.obtenerTmpPromConv', error);
        throw error;

    }

}

function* listarAgentes() {

    try {
        let objAgentes = yield lstrAgentes();
        let agentesDisplay = [];

        objAgentes.forEach(obj => {
            const agentes = {
                nombre: obj.data().nombres + ' ' + obj.data().apellidos,
                mail: obj.data().mail,

            }
            agentesDisplay.push(agentes);

        })

        yield put(listarAgentesSuccess({ agentesDisplay: agentesDisplay, objAgentes: objAgentes }));
    } catch (error) {
        console.error('saga.listarAgentes', error);
        throw error;

    }

}

function* listarEstados() {

    try {
        let objEstados = yield lstrEstados();
        let estadosDisplay = [];

        objEstados.forEach(obj => {
            const estados = {
                nombre: obj.data().nombre,
                code: obj.data().value
            }
            estadosDisplay.push(estados);

        })

        yield put(listarEstadosSuccess({ estadosDisplay: estadosDisplay, objEstados: objEstados }));
    } catch (error) {
        console.error("Listar estados SAGA:", error)
        throw error;

    }
}

function* desplegarEventosBuscador(action) {
    yield listarAgentes();

}


function* filtrarBusqueda(action) {
    let { dateIni, dateFin, agente, texto, idConversacion } = action.value;
    console.log("filtrarBusqueda", action)
    let bot = true
    yield put(modalEsperaData(true));
    yield put(saveSearchTableGenerales(action.value))
    try {

        let fechaIni = dateIni ? new Date(dateIni) : false;
        let fechaFin = dateFin ? new Date(dateFin) : false;

        let convsFiltradas = yield search(fechaIni, fechaFin, agente, texto, bot, idConversacion);

        if (convsFiltradas || convsFiltradas.length > 0) {
            let convsFiltradasData = yield procesarConvs(convsFiltradas, idConversacion)
            let encuesta = false

            let { isSurveyEnabled, preguntasEncuesta } = yield getFromSession('configurationVariables');
            if (isSurveyEnabled) {
                encuesta = preguntasEncuesta
            }

            const { convsCerradasDisplay, columns } = yield ordenarDatosTabla(convsFiltradasData, encuesta);

            let primeraPagina = convsCerradasDisplay.slice(0, 10);

            let response = {
                columns,
                "convsCerradasDisplay": primeraPagina,
                "listaFiltradaCompleta": convsCerradasDisplay
            }

            yield put(filtrarBusquedaSuccess(response))
        } else {
            //no hay conversaciones   
        }
        yield put(modalEsperaData(false))
    } catch (error) {
        console.error('sagaPanelAgente.filtrarBusqueda', error);
        throw error;
    }

}
function* filtrarBusquedaAgente(action) {

    let { dateIni, dateFin, agente, texto, idConversacion } = action.value;
    // La variable bot es para saber si traer info de bots o sólo agentes
    // bot = true significa que trae con bots y false para sólo los agentes
    let bot = false
    try {
        let fechaIni = dateIni ? new Date(dateIni) : false;
        let fechaFin = dateFin ? new Date(dateFin) : false;

        let convsFiltradas = yield search(fechaIni, fechaFin, agente, texto, bot, idConversacion);
        if (convsFiltradas && convsFiltradas.length > 0) {
            let convsFiltradasData = yield procesarConvs(convsFiltradas, idConversacion)
            console.log("convsFiltradasData", convsFiltradasData);
            let encuesta = false

            let { isSurveyEnabled, preguntasEncuesta } = yield getFromSession('configurationVariables');
            if (isSurveyEnabled) {
                encuesta = preguntasEncuesta
            }

            const { convsCerradasDisplay } = yield ordenarDatosTabla(convsFiltradasData, encuesta);

            let primeraPagina = convsCerradasDisplay.slice(0, 10);
            // console.log('primera pagina', primeraPagina);
            // console.log('convsCerradasDisplay', convsCerradasDisplay);

            let response = {
                "convsCerradasDisplay": primeraPagina,
                "listaFiltradaCompleta": convsCerradasDisplay
            }
            yield put(filtrarBusquedaAgenteSuccess(response))
        } else {
            yield put(filtrarBusquedaAgenteSuccess({
                "convsCerradasDisplay": true,
                "listaFiltradaCompleta": []
            }))
        }


       // let primeraPagina = convsCerradasDisplay.slice(0, 10);


    } catch (error) {
        console.error('sagaPanelAgente.filtrarBusquedaAgente', error);
        throw error;
    }

}

async function procesarConvs(convs, idConversacion) {
    let convsCerradas = []
    // Hace falta ver cuando no encuentra el ID está errado para que no saque error
    if (convs && convs.type && convs.type === 'bigquery') {
        if (convs.data && convs.data.length > 0) {
            await Promise.all(convs.data.map(async (conv) => {
                let client = conv.cliente.split(' ');
                convsCerradas.push({
                    "fechaIni": new Date(conv.fecha_ini.value),
                    "fechaFin": conv.fecha_fin ? new Date(conv.fecha_fin.value) : new Date(conv.fecha_abandono?.value),
                    "fechaAbandono": conv.fecha_abandono ? new Date(conv.fecha_abandono.value) : false,
                    "fecha_asignacion": conv.fecha_asignacion ? new Date(conv.fecha_asignacion.value) : false,
                    "fecha_aceptacion": conv.fecha_aceptacion ? new Date(conv.fecha_aceptacion.value) : false,
                    "fecha_ini": new Date(conv.fecha_ini.value),
                    "agente": { nombres: emptyObject(conv.agente_nombre), mail: emptyObject(conv.agente_email), nickname: emptyObject(conv.agente_nickname) },
                    "asunto": conv.asunto,
                    "canal": conv.canal,
                    "cliente": { mail: emptyObject(client[client.length - 1]), nombre: client[0] },
                    "resuelta": conv.resuelta,
                    "encuesta": conv.encuesta ? conv.encuesta : false,
                    "tema": conv.tema ? conv.tema : false,
                    "idConv": conv.idConv,
                    "aceptaTerminos": emptyObject(conv.aceptaTerminos),
                    "suscribeContactar": emptyObject(conv.suscribeContactar),
                });
            }))
        }
    } else if (convs !== undefined) {
        if (convs.length > 0) {
            await Promise.all(convs.map(async (doc) => {
                let conv = doc.data()
                if (!conv.fecha_fin) {
                    console.log('conv', conv);
                }
                convsCerradas.push({
                    "fechaIni": conv.fecha_ini.toDate(),
                    "fechaFin": conv.fecha_fin ? conv.fecha_fin.toDate() : conv.fecha_abandono.toDate(),
                    "fechaAbandono": conv.fecha_abandono ? conv.fecha_abandono.toDate() : false,
                    "fecha_asignacion": conv.fecha_asignacion ? conv.fecha_asignacion.toDate() : false,
                    "fecha_aceptacion": conv.fecha_aceptacion ? conv.fecha_aceptacion.toDate() : false,
                    "fecha_ini": conv.fecha_ini,
                    "agente": conv.agente,
                    "asunto": conv.asunto,
                    "canal": conv.canal,
                    "cliente": conv.cliente,
                    "resuelta": conv.resuelta,
                    "encuesta": conv.encuesta ? conv.encuesta : false,
                    "tema": conv.tema ? conv.tema : false,
                    "idConv": doc.id,
                    "aceptaTerminos": emptyObject(conv.aceptaTerminos),
                    "suscribeContactar": emptyObject(conv.suscribeContactar),
                });
            }))
        } else {
            if (convs && convs.fechaIni)
                convsCerradas.push({
                    "fechaIni": convs.fecha_ini.toDate(),
                    "fechaFin": convs.fecha_fin ? convs.fecha_fin.toDate() : convs.fecha_abandono.toDate(),
                    "fechaAbandono": convs.fecha_abandono ? convs.fecha_abandono.toDate() : false,
                    "fecha_asignacion": convs.fecha_asignacion ? convs.fecha_asignacion.toDate() : false,
                    "fecha_aceptacion": convs.fecha_aceptacion ? convs.fecha_aceptacion.toDate() : false,
                    "fecha_ini": convs.fecha_ini,
                    "agente": convs.agente,
                    "asunto": convs.asunto,
                    "canal": convs.canal,
                    "cliente": convs.cliente,
                    "resuelta": convs.resuelta,
                    "encuesta": convs.encuesta ? convs.encuesta : false,
                    "tema": convs.tema ? convs.tema : false,
                    "idConv": idConversacion,
                    "aceptaTerminos": emptyObject(convs.aceptaTerminos),
                    "suscribeContactar": emptyObject(convs.suscribeContactar),
                });
        }
    }


    return convsCerradas

}

function* singleSearch(action) {
    try {
        const { value: { fieldValue, items } } = action;
        console.log("search")
        let convsCerradas = yield singleBusqueda(fieldValue, items);
        const { convsCerradasDisplay } = yield ordenarDatosTabla(convsCerradas);
        let response = {
            convsCerradas,
            convsCerradasDisplay
        }
        yield put(singleBusquedaSuccess(response));

    } catch (error) {
        console.error('agentPanel.saga.singleSearch', error)
        throw error;
    }

}

function* obtenerDataConvsAgentes(action) {
    //yield createCatalog();
    let rango = obtenerRangoDeFechas(action.value);
    try {
        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);
        let convsCerradas = [];
        let reference = false;
        const company = getFromSession("company_id");
        reference = yield firebaseDatabase.collection(`company/${company}/conversations`).
            where('estado', "in", [10, 11, 12]).where('fecha_ini', '>', timeIni).
            where('fecha_ini', "<", timeFin).
            orderBy('fecha_ini', 'desc').
            get();
        const respuesta = [];
        yield reference.docs.map((doc, i) => {
            respuesta.push(doc.data())
        });


        yield crearData({ respuesta, rango });

    } catch (error) {
        console.error('saga.obtenerDataConvsAgentes', error);
        yield put(obtenerConvsCerradasError(error));

    }
}

function* obtenerDataNotasOcultas(action) {

    yield put(manageSpiner({ key: "notasOcultas", state: true }));
    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);

    let convsCerradas = [];
    let reference = false;
    let reference2 = false;
    try {
        const company = getFromSession("company_id");


        reference2 = yield firebaseDatabase.collectionGroup(`notasOcultas`).
            where('company', "==", `${company}`).
            where('fecha', '>', timeIni).
            where('fecha', "<", timeFin).
            orderBy('fecha', 'desc').
            get();

        const respuesta2 = [];
        var dataSavedNotas = [];
        yield reference2.docs.map((doc, i) => {
            respuesta2.push({ data: doc.data(), docPadre: doc._delegate._key.path.segments[8] })
        });

        if (respuesta2.length > 0) {
            dataSavedNotas = yield select(getDataNotasOcultas);
            var idConsulta = [];
            var respuesta = [];
            if (dataSavedNotas.length > 0) {
                idConsulta = yield getIdConsultas({ dataLocal: dataSavedNotas, dataConsultas: respuesta2 });
                if (idConsulta.length > 0) {
                    for (var element of idConsulta) {
                        reference = yield firebaseDatabase.collection(`company/${company}/conversations`).
                            doc(element.docPadre).get();
                        respuesta.push({ data: element.data, docPadre: element.docPadre, conversations: reference.data() })

                    }
                    respuesta.forEach(element => {
                        dataSavedNotas.push(element);
                    });
                }
            } else {
                for (var element of respuesta2) {
                    reference = yield firebaseDatabase.collection(`company/${company}/conversations`).
                        doc(element.docPadre).get();
                    dataSavedNotas.push({ data: element.data, docPadre: element.docPadre, docTrans: element.docTrans, conversations: reference.data() })

                }
            }
            yield put(saveDataNotasOcultas(dataSavedNotas));
        }


        const data = yield crearDataTable({ data: dataSavedNotas, coleccion: "notasocultas" });
        yield put(obtenerDataNotasOcultasSuccess({ data, columnsTable: HEADER_TABLE_NOTAS }));
        yield put(manageSpiner({ key: "notasOcultas", state: false }));

    } catch (error) {
        console.error('saga.obtenerDataNotasOcultas', error);
        yield put(obtenerConvsCerradasError(error));

    }
}

function convertirFecha(fecha) {
    const fech = fecha.toDate();
    return `${fech.getDate().toString()}/${(fech.getMonth() + 1).toString()}/${fech.getFullYear().toString()} 
    ${fech.getHours().toString()}:${fech.getMinutes().toString()}:${fech.getSeconds().toString()}`;
}

function* getIdConsultas(action) {
    const { dataLocal, dataConsultas } = action;
    var dataSolicitud = [];
    dataConsultas.forEach(item => {
        var banNoExist = true;
        dataLocal.forEach(element => {
            if (item.docPadre === element.docPadre) {
                banNoExist = false;
                return;
            }
        });
        if (banNoExist) {
            dataSolicitud.push(item.docPadre);
        }
    });
    dataSolicitud = dataSolicitud.filter(onlyUnique);
    return dataSolicitud;
}

function contarElementos(arr, val) {
    let conteo = 0;
    conteo = arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
    return conteo;
}

function agruparTema(data) {
    const labels = [...new Set(data)];
    let datosGrafica = {};
    for (let label of labels) {
        datosGrafica[label] = contarElementos(data, label);
    }
    return datosGrafica;
}

function* obtenerDataChatbot(action) {

    let selectedForm = action.value && action.value.selectedForm ? action.value.selectedForm : action.value
    let rango = obtenerRangoDeFechas(selectedForm);

    try {

        let votosPre = yield traerDatosGraficas(rango, "votoPresupuestoRango");
        yield put(obtenerDataChatBotSuccess(votosPre))

    } catch (error) {
        console.error('saga.obtenerDataChatbot', error);
        yield put(obtenerConvsCerradasError(error));

    }
}
function* obtenerDataColasAtencion(action) {
    let rango = obtenerRangoDeFechas(action.value);

    try {
        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);

        let reference = false;
        const company = getFromSession("company_id");
        reference = yield firebaseDatabase.collection(`company/${company}/conversations`).
            where('fecha_ini_cola', '>', timeIni).
            where('fecha_ini_cola', "<", timeFin).
            where('cola', "==", "S").
            orderBy('fecha_ini_cola', 'desc').
            get();
        const respuesta = [];
        yield reference.docs.map((doc, i) => {
            respuesta.push(doc.data())
        });

        yield crearDataColas({ respuesta, rango });

    } catch (error) {
        console.error('saga.obtenerDataColasAtencion', error);
        yield put(obtenerConvsCerradasError(error));

    }
}
function* crearDataColas(action) {
    const { respuesta, rango } = action;

    yield put(manageSpiner({ key: "colasAtencion", state: true }))
    var final = []
    const promedioArr = [];
    const conteo = [];
    var tableColas = []
    var barChart = {};
    if (respuesta.length > 0) {
        var tipo = yield crearLabelChart({ rango });
        var banTipo = tipo.banTipo;

        respuesta.forEach(element => {
            var fechaInicio = element.fecha_ini_cola ? new Date(element.fecha_ini_cola?.toDate()).getTime() : false;
            var fechaFin = element.fecha_fin_cola ? new Date(element.fecha_fin_cola?.toDate()).getTime() : false;
            if (fechaInicio && fechaFin) {
                var diff = fechaFin - fechaInicio;
                tableColas.push({
                    fecha_ini_cola: convertirFecha(element.fecha_ini_cola),
                    fecha_fin_cola: element.fecha_fin_cola ? convertirFecha(element.fecha_fin_cola) : 'En cola',
                    agente: `${emptyObject(element.agente?.nombres)} ${emptyObject(element.agente?.apellidos)}`,
                    correoAgente: element.agente?.mail,
                    clienteName: emptyObject(element.cliente?.nombre),
                    correoCliente: emptyObject(element.cliente?.mail),
                    cellCliente: emptyObject(element.cliente?.telefono),
                    canal: element.canal,
                    skillName: element.skillName,
                    tiempoEs: crearString(diff),
                })
            }
        });
        tipo.tipo.forEach(tipos => {
            var prom = 0;
            var con = 0;
            var conColas = 0;
            var promedio = '00:00:00:00'
            const result = [];
            respuesta.forEach(element => {
                if (banTipo) {
                    const tem = tipos.split("/")
                    if (parseInt(tem[2]) === element.fecha_ini_cola.toDate().getFullYear() && parseInt(tem[1]) === element.fecha_ini_cola.toDate().getMonth() + 1 && parseInt(tem[0]) === element.fecha_ini_cola.toDate().getDate()) {
                        var fechaInicio = element.fecha_ini_cola ? new Date(element.fecha_ini_cola?.toDate()).getTime() : false;
                        var fechaFin = element.fecha_fin_cola ? new Date(element.fecha_fin_cola?.toDate()).getTime() : false;

                        if (fechaInicio && fechaFin) {
                            conColas += 1;
                            var diff = fechaFin - fechaInicio;
                            prom = prom + diff;
                            con += 1;
                        }


                    }

                } else {
                    const tem2 = tipos.split(":")
                    if (tem2[0] === element.fecha_ini_cola.toDate().getHours().toString()) {
                        var fechaInicio = element.fecha_ini_cola ? new Date(element.fecha_ini_cola?.toDate()).getTime() : false;
                        var fechaFin = element.fecha_fin_cola ? new Date(element.fecha_fin_cola?.toDate()).getTime() : false;

                        if (fechaInicio && fechaFin) {
                            conColas += 1;
                            var diff = fechaFin - fechaInicio;
                            prom = prom + diff;
                            con += 1;
                        }

                    }
                }


            });

            promedio = 0;
            if (con > 0) {
                promedio = ((prom / con) / (1000 * 60)).toFixed(4);
            }
            conteo.push(conColas);
            promedioArr.push(promedio)

        });
        const finalChatBot = [

            {
                type: 'line',
                label: 'Total colas atención',
                borderColor: '#53D611',
                borderWidth: 2,
                fill: false,
                tension: .4,
                data: conteo
            },
            {
                type: 'bar',
                label: 'Promedio espera en minutos',
                backgroundColor: '#BC9BBD',
                data: promedioArr,
                borderColor: 'white',
                yAxisID: 'y',
                borderWidth: 2
            },
        ]

        barChart = {
            labels: tipo.tipo,
            datasets: finalChatBot
        }

    }
    yield put(obtenerColasAtencionSuccess({ barChart, columnsTable: HEADER_TABLE_COLAS_ATENCION, data: tableColas }));
    yield put(manageSpiner({ key: "colasAtencion", state: false }))
}
function crearString(action) {
    const hour = Math.trunc(action / (1000 * 60 * 60))
    const min = Math.trunc(action / (1000 * 60));
    return `${hour.toString()}:${Math.trunc((action - (1000 * 60 * 60 * (hour))) / (1000 * 60)).toString()}:${Math.trunc((action - (1000 * 60 * min)) / 1000).toString()}:${Math.trunc((action / 1000 - Math.trunc(action / 1000)) * 1000).toString()}`

}

function* obtenerDataConectedUsers(action) {
    yield put(manageSpiner({ key: "estadisticaConectedUser", state: true }));
    let rango = obtenerRangoDeFechas(action.value);

    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);

    let reference = false;
    try {
        const company = getFromSession("company_id");
        reference = yield firebaseDatabase.collection(`company/${company}/connectedUsers`).
            where("agente.rol", "==", "AGENTE").
            where('fecha_ini', '>', timeIni).
            where('fecha_ini', '<', timeFin).
            orderBy('fecha_ini', 'desc').
            get();

        const respuesta = [];
        yield reference.docs.map((doc, i) => {
            respuesta.push(doc.data())
        });



        const data = yield crearDataTable({ data: respuesta, coleccion: "conectedUsers" });


        yield put(obtenerDataConectedUsersSuccess({ data, columnsTable: HEADER_TABLE_CONECTEDUSERS }));
        yield put(manageSpiner({ key: "estadisticaConectedUser", state: false }));
    } catch (error) {
        console.error('saga.obtenerDataConectedUsers', error);
        yield put(obtenerConvsCerradasError(error));

    }
}

function* obtenerDataTransferencias(action) {
    yield put(manageSpiner({ key: "transferenciaEntreAgentes", state: true }));
    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);

    let reference2 = false;
    try {

        const company = getFromSession("company_id");
        reference2 = yield firebaseDatabase.collectionGroup(`transferencias`).
            where('company', "==", `${company}`).
            where('fecha', '>', timeIni).
            where('fecha', "<", timeFin).
            orderBy('fecha', 'desc').
            get();


        const respuesta2 = [];
        yield reference2.docs.map((doc, i) => {
            console.log("******************", doc);
            respuesta2.push({ data: doc.data(), docPadre: doc._delegate._key.path.segments[8], docTrans: doc._delegate._key.path.segments[10] })
        });



        const bar = yield crearDataTransferencias({ respuesta: respuesta2, rango });

        const data = yield crearDataTable({ data: respuesta2, coleccion: "transferencias" });
        yield put(obtenerDataTransferenciasSuccess({ data, bar: bar.barchart, table2: bar.tableTransfer, columnsTable: HEADER_TABLE_TRANSFERENCIAS }));
        yield put(manageSpiner({ key: "transferenciaEntreAgentes", state: false }));

    } catch (error) {
        console.error('saga.obtenerDataTransferencias', error);
        yield put(obtenerConvsCerradasError(error));

    }
}
function ordenarPie(a, b) {
    if (a.total < b.total) {
        return 1;
    }
    if (a.total > b.total) {
        return -1;
    }
    return 0;
}
function ordenarArrayAsc(a, b) {
    if (a.totalC > b.totalC) {
        return 1;
    }
    if (a.totalC < b.totalC) {
        return -1;
    }
    return 0;
}
function ordenarArrayDesc(a, b) {
    if (a.totalC < b.totalC) {
        return 1;
    }
    if (a.totalC > b.totalC) {
        return -1;
    }
    return 0;
}

function* crearDataTable(action) {
    const company = getFromSession("company_id");
    const { data, coleccion } = action;
    const datos = [];
    if (data.length > 0) {
        if (coleccion === "notasocultas") {
            for (var element of data) {
                datos.push({
                    fecha: convertirFecha(element.data.fecha),
                    autorNo: element.data.autor.nombre,
                    rolAutorNo: element.data.autor.rol,
                    habilidad: element.data.autor.habilidad,
                    notaOculta: element.data.nota,
                    agente: `${element.conversations.agente.nombres} ${emptyObject(element.conversations.agente.apellidos)}`,
                    correoAgente: element.conversations.agente.mail,
                    canal: element.conversations.canal,
                    skillName: element.conversations.skillName,
                })
            }


        }
        if (coleccion === "transferencias") {
            for (var element of data) {
                datos.push({
                    fecha: convertirFecha(element.data.fecha),
                    habilidadDestino: element.data.habilidad_destino,
                    habilidadorigen: element.data.habilidad_origen,
                    usr_origen: element.data.usr_origen,
                    usr_destino: element.data.usr_destino,
                    agente: `${emptyObject(element.data?.usr_origen)}`,
                    nickname: emptyObject(element.data?.agente_nick),
                    correoAgente: emptyObject(element.data?.agente_mail),
                    canal: element.data?.canal,
                    cliente: emptyObject(element.data?.cliente_mail),
                    mailcliente: emptyObject(element.data?.cliente_name),
                })
            }

        }
        if (coleccion === "conectedUsers") {
            for (var element of data) {
                let convsAsignadas = yield contarConvsPorAgente(company, element.agente.idDoc)

                datos.push({
                    fechaini: convertirFecha(element.fecha_ini),
                    fechafin: emptyObject(element.fecha_fin?.toString()).length > 0 ? convertirFecha(element.fecha_fin) : "",
                    agente: `${element.agente?.nombres} ${emptyObject(element.agente?.apellidos)}`,
                    correoAgente: element.agente.mail,
                    nickname: element.agente.nickname,
                    conv_actuales: convsAsignadas,
                    conv_atendidas: element.conv_atendidas,
                    conv_maximas: element.conv_maximas,
                    estado: element.estado === 1 ? "Conectado" : element.estado === 2 ? "Desconectado" : "Ausente",
                })
            }

        }
        if (coleccion === "chatbot") {
            for (var element of data) {
                datos.push({
                    fecha: convertirFecha(element.chatbot.fecha),
                    fechaOriginal: element.chatbot.fecha,
                    company: element.chatbot.company,
                    constestada: element.chatbot.contestada ? "SI" : "NO",
                    canal: element.chatbot.canal,
                    intentName: element.chatbot.intentName,
                    user_message: element.chatbot.user_message,
                })
            }
        }
        if (coleccion === "chatbotUnknown") {
            for (var element of data) {
                if (element.chatbot.intentName === "Default Fallback Intent") {
                    datos.push({
                        fechaOriginal: element.chatbot.fecha,
                        fecha: convertirFecha(element.chatbot.fecha),
                        canal: element.chatbot.canal,
                        user_message: element.chatbot.user_message,
                    })
                }
            }
        }
        if (coleccion === "chatbotComentarios") {
            for (var element of data) {
                if (element.chatbot.encuesta && parseInt(element.chatbot.encuesta["ratingStars"])) {
                    if (parseInt(element.chatbot.encuesta["ratingStars"]) <= 3) {

                        datos.push({
                            fechaOriginal: element.chatbot.fecha_ini,
                            fecha: convertirFecha(element.chatbot.fecha_ini),
                            canal: element.chatbot.canal,
                            calification: element.chatbot.encuesta["ratingStars"],
                            comentario: element.chatbot.encuesta["comentarios"] ? element.chatbot.encuesta["comentarios"] : "Sin comentarios",
                        })
                    }
                }
            }
        }
        if (coleccion === "infoVotos") {

            for (var element of data) {
                let info = element.votoCausasCiudadanas

                if (info.nombreCausa) {

                    datos.push({
                        fecha: convertirFecha(info.fecha),
                        canal: info.canal,
                        nombreCausa: info.nombreCausa,
                        tipoDocumento: info.tipoIdentificacion,
                        documentoIdentidad: info.documento,
                        fechaExpedicion: info.expedicionDocumento ? info.expedicionDocumento : "Sin datos",
                        telefono: info.telefono ? info.telefono : "Sin datos",
                        respuestaVoto: info.codRespuesta === 200 ? "SI" : "NO",
                        codigoCausa: info.codigoCausa,
                        codRespuesta: info.codRespuesta,
                        mensajeCodRespuesta: info.message
                    })
                }
            }
        }
        if (coleccion === "presupuestos") {

            for (var info of data) {
                datos.push({
                    fecha: convertirFecha(info.fechaRegistro),
                    canal: info.canal,
                    cedula: info.cedula,
                    codigoPro: info.codigoPropuesta,
                    nombreLocalidad: info.nombreLocalidad,
                    telefono: info.telefono ? info.telefono : "Sin datos",
                    titulo: info.titulo,
                    tema: info.tema,
                })

            }
        }
        if (coleccion === "reporteHuecos") {
            for (var info of data) {
                datos.push({
                    fecha: convertirFecha(info.fechaRegistro),
                    canal: info.canal,
                    correo: info.correo,
                    exitosoFile: info.exitosoAddFile ? "SI" : "NO",
                    exitosoReport: info.exitosoCreateReport ? "SI" : "NO",
                    clasificacion: info.clasificacion,
                    telefono: info.telefono ? info.telefono : "Sin datos",
                    latitud: info.ubicacion.latitude,
                    longitud: info.ubicacion.longitude,
                    idReport: info.responseCreateReport[0].objectId,
                    idFile: info.responseAddFile.objectId,
                })

            }
        }

        if (coleccion === "zonaParqueo") {
            for (var info of data) {
                datos.push({
                    fecha: convertirFecha(info.fechaRegistro),
                    canal: info.canal,
                    correo: info.correo,
                    exitosoFile: info.exitosoAddFile ? "SI" : "NO",
                    exitosoReport: info.exitosoCreateReport ? "SI" : "NO",
                    razon: info.razon,
                    telefono: info.telefono ? info.telefono : "Sin datos",
                    latitud: info.ubicacion.latitude,
                    longitud: info.ubicacion.longitude,
                    idReport: info.responseCreateReport.data.notificationId,
                    idFile: info.responseAddFile.data?.id
                })

            }
        }
        if (coleccion === "registroGAB") {
            // console.log("data*****", data);

            for (var info of data) {
                datos.push({
                    fecha: convertirFecha(info.fechaRegistro),
                    canal: info.canal,
                    correo: info.correo,
                    exitosoFile: info.exitosoAddFile ? "SI" : "NO",
                    exitosoReport: info.exitosoRegistroGab ? "SI" : "NO",
                    numeroDoc: info.numeroDoc,
                    nombre: info.nombre,
                    apellidos: info.apellido + " " + info.apellido2,
                    telefono: info.telefono ? info.telefono : "Sin datos",
                    latitud: info.rtaIdeca.latitude,
                    longitud: info.rtaIdeca.longitude,
                    exitosoReport: info.respuestaRegistro.code ? "NO" : "SI",
                    idConv: info.idConv
                })

            }
        }
        if (coleccion === "dataAtencionAgente") {
            for (var info of data) {
                datos.push({
                    fecha: info.fecha.value,
                    agente: info.agente_nombre,
                    canal: info.canal,
                    hora: info.hora,
                    interaccionesAtendidas: info.interaccionesAtendidas,
                    tiempoProd: info.tiempoProductivo,
                    tiempoTotal: info.tiempoTotal,
                    tiempoEspera: info.tiempoEspera,
                    tiempoRespuesta: info.tiempoRespuesta,
                    tiempoEsperaAgente: info.tiempoEsperaAgente
                })
            }

        }
        if (coleccion === "dataAtencionCanal") {
            for (var info of data) {
                datos.push({
                    fecha: info.fecha.value,
                    canal: info.canal,
                    hora: info.hora,
                    interaccionesAtendidas: info.interaccionesAtendidas,
                    tiempoTotal: info.tiempoTotal,
                    tiempoEspera: info.tiempoEspera,
                    InteraccionesOfrecidas: info.InteraccionesOfrecidas,
                    abandonadas: info.abandonadas,
                    nivelAtencion: info.nivelAtencion,
                    tasaAbandonoWeb: info.tasaAbandonoWeb,
                    tiempoProductivo: info.tiempoProductivo,
                })
                // console.log("DATOS dataAtencionCanal", datos);

            }
        }
        if (coleccion === "tablePromedios") {
            for (var info of data) {
                datos.push({
                    nameAgent: info.label,
                    promAgen: info.value,
                })

            }
        }
    }

    return datos;//.sort(ordenarFecha);
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function emptyObject(value) {
    return typeof value !== 'object' && value !== undefined ? value : ''
}

function* crearLabelChart(action) {
    const { rango } = action;
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);

    let fechaInicial = moment(timeIni);
    let fechaFinal = moment(timeFin);
    let diferenciaDias = fechaFinal.diff(fechaInicial, 'days');
    const tipo = [];
    var banTipo = true;
    if (diferenciaDias > 0) {
        //rango fechas
        var aux = new Date(rango.fechaIni);
        var ban = true;
        while (ban) {

            const temp = `${aux.getDate().toString()}/${(aux.getMonth() + 1).toString()}/${aux.getFullYear().toString()}`;

            if (aux.getFullYear() === timeFin.getFullYear() && aux.getMonth() === timeFin.getMonth() && aux.getDate() === timeFin.getDate()) {
                ban = false;
            }
            tipo.push(temp);

            aux.setDate(aux.getDate() + 1);
        }

    } else {
        banTipo = false;
        // rango horas
        var cont = 0;
        while (cont < 25) {
            tipo.push(`${cont.toString()}:00`);
            cont += 1;
        }
    }
    return { tipo, banTipo };
}

function* crearData(action) {
    const { respuesta, rango } = action;
    yield put(manageSpiner({ key: "tablaConvsPorAgente", state: true }));

    var tipo = yield crearLabelChart({ rango });
    var banTipo = tipo.banTipo;
    const agentes = []
    respuesta.forEach(element => {
        if (element.agente?.nombres !== "Chatbot" && emptyObject(element.agente?.nombres).length > 0) {
            agentes.push(element.agente?.nombres);
        }
    });

    const agentesUni = agentes.filter(onlyUnique);
    var finalAgentes = []
    var tableAgentes = []


    agentesUni.forEach(item => {
        if (item.length > 0) {
            const auxTemp = []
            var contConv = 0;
            tipo.tipo.forEach(tipos => {
                var cont = 0;
                respuesta.forEach(element => {
                    if (banTipo) {
                        const tem = tipos.split("/")
                        if (parseInt(tem[2]) === element.fecha_ini.toDate().getFullYear() && parseInt(tem[1]) === element.fecha_ini.toDate().getMonth() + 1 && parseInt(tem[0]) === element.fecha_ini.toDate().getDate()) {
                            if (item === emptyObject(element.agente?.nombres)) {
                                cont += 1;
                                contConv += 1;
                            }
                        }

                    } else {
                        const tem2 = tipos.split(":")
                        if (tem2[0] === element.fecha_ini.toDate().getHours().toString()) {
                            if (item === emptyObject(element.agente?.nombres)) {
                                cont += 1;
                                contConv += 1;
                            }
                        }
                    }

                });
                auxTemp.push(cont);

            });
            tableAgentes.push({ agente: item, totalC: contConv })
            finalAgentes.push(
                {
                    agente: item,
                    type: 'bar',
                    label: item,
                    backgroundColor: setBg(),
                    data: auxTemp,
                }
            )

        }

    });
    tableAgentes.sort(ordenarArrayAsc)


    var barchart = {
        labels: tipo.tipo,
        datasets: []
    }


    tableAgentes.forEach(element => {
        finalAgentes.forEach(item => {
            if (item.agente === element.agente) {
                barchart.datasets.push({
                    type: item.type,
                    label: item.label,
                    backgroundColor: item.backgroundColor,
                    data: item.data,
                });
            }
        });
    });


    tableAgentes.sort(ordenarArrayDesc)


    const barcharTotal = yield crearDataBarAgente({ tableAgentes, agentesUni });
    // yield put(obtenerDataConvsAgentesSuccess({ barchart, barcharTotal }));
    //s   yield put(obtenerDatatTableAgentesSuccess({ data: tableAgentes, columnsTable: colum }));
    yield put(manageSpiner({ key: "tablaConvsPorAgente", state: false }));

}

function* crearDataTransferencias(action) {
    const { respuesta, rango } = action;

    var tipo = yield crearLabelChart({ rango });
    var banTipo = tipo.banTipo;


    var finalAgentes = [];
    var tableTransfer = []

    const auxTemp = []
    const auxChatbot = [];

    tipo.tipo.forEach(tipos => {
        var cont = 0;
        var contChat = 0;
        respuesta.forEach(element => {
            if (banTipo) {
                const tem = tipos.split("/")
                if (parseInt(tem[2]) === element.data.fecha.toDate().getFullYear() && parseInt(tem[1]) === element.data.fecha.toDate().getMonth() + 1 && parseInt(tem[0]) === element.data.fecha.toDate().getDate()) {

                    if (element.data?.usr_origen === "Chatbot" && emptyObject(element.data?.usr_origen).length > 0) {
                        contChat += 1;
                    }

                }

            } else {
                const tem2 = tipos.split(":")
                if (tem2[0] === element.data.fecha.toDate().getHours().toString()) {

                    if (element.data?.usr_origen === "Chatbot" && emptyObject(element.data?.usr_origen).length > 0) {
                        contChat += 1;
                    }
                }
            }


        });
        auxChatbot.push(contChat);
        auxTemp.push(cont);
        if (contChat > 0) {
            tableTransfer.push(
                { fecha: tipos, total: contChat }
            )
        }
    });


    finalAgentes.push(

        {
            agente: "ChatBot",
            type: 'bar',
            label: "ChatBot",
            backgroundColor: setBg(),
            data: auxChatbot,
        }
    )



    var barchart = {
        labels: tipo.tipo,
        datasets: finalAgentes,
    }

    return { barchart, tableTransfer };
}

function* crearGraficoChatBot(action) {
    const { respuesta, rango } = action;

    var tipo = yield crearLabelChart({ rango });
    var banTipo = tipo.banTipo;

    var dataDias = tipo.tipo;
    var barchart = {}
    if (respuesta.length > 0) {
        const coincidencia = [];
        const respuestaSi = [];
        const respuestaNo = [];
        const total = [];

        dataDias.forEach(element => {
            var respSi = 0;
            var respNo = 0;
            var totalC = 0;
            respuesta.forEach(item => {
                if (banTipo) {
                    const tem = element.split("/")
                    if (parseInt(tem[2]) === item.chatbot.fecha.getFullYear() && parseInt(tem[1]) === item.chatbot.fecha.getMonth() + 1 && parseInt(tem[0]) === item.chatbot.fecha.getDate()) {
                        totalC += 1;
                        if (item.chatbot?.contestada) {
                            respSi += 1;
                        } else { respNo += 1; }
                    }

                } else {

                    const tem2 = element.split(":")
                    /* console.log('item', item); */
                    if (tem2[0] === item.chatbot.fecha.getHours().toString()) {
                        totalC += 1;
                        if (item.chatbot?.contestada) {
                            respSi += 1;
                        } else { respNo += 1; }
                    }
                }

            });
            coincidencia.push(respSi ? ((respSi / totalC) * 100).toFixed(2) : 0);
            respuestaSi.push(respSi);
            respuestaNo.push(respNo);
            total.push(totalC)
        });


        const finalChatBot = [

            {
                type: 'line',
                label: 'Total preguntas',
                borderColor: '#53D611',
                borderWidth: 2,
                fill: false,
                tension: .4,
                data: total
            },
            {
                type: 'line',
                label: 'Preguntas No contestadas',
                borderColor: '#D63811',
                borderWidth: 2,
                fill: true,
                tension: .4,
                data: respuestaNo
            },
            {
                type: 'line',
                label: 'Preguntas contestadas',
                borderColor: '#2E89F1',
                borderWidth: 2,
                fill: false,
                tension: .4,
                data: respuestaSi
            },
            {
                type: 'bar',
                label: 'Tasa de preguntas contestadas',
                backgroundColor: '#BC9BBD',
                data: coincidencia,
                borderColor: 'white',
                yAxisID: 'y',
                borderWidth: 2
            },
        ]

        barchart = {
            labels: dataDias,
            datasets: finalChatBot
        }
    }
    return barchart;

}

function* crearDataBarAgente(action) {
    const { tableAgentes, agentesUni } = action;
    const data = []

    var barchart = {
        labels: [],
        datasets: []
    }

    var cont = 0;
    tableAgentes.forEach(element => {
        const aux = []
        var con = 0;
        tableAgentes.forEach(ele => {
            if (con === cont) {
                aux.push(element.totalC);
            } else {
                aux.push(0);
            }
            con += 1;
        });
        barchart.labels.push(element.agente)
        barchart.datasets.push(
            {
                type: 'bar',
                label: element.agente,
                backgroundColor: setBg(),
                data: aux,
            }
        )
        cont += 1;
    });



    return barchart;
}

const setBg = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

function* createCatalog() {

    const datos = catalogoE;
    //const datos = catalogoSa;

    try {
        yield datos.map(item => {
            //firebaseDatabase.collection(`company/coomevaEps/catalogos/SALAS_SIP/data`).add(item);
            firebaseDatabase.collection(`company/coomevaEps/catalogos/PUNTOS_DISPENSACION/data`).add(item);
        });

    } catch (e) {
        console.error(e);
    }
}
function* obtenerDataGraficaQuerySentiment(action) {
    try {
        yield put(manageSpiner({ key: "tablaSentiment", state: true }));
        //console.log('action de la funcion nueva***', action);

        let rango = obtenerRangoDeFechas(action.value);
        let label = 'Número de mensajes';
        let tooltip = {
            'text': 'Gráfica que muestra el nivel satisfaccion de la atencion del chatbot',
            'style': 'bigTooltip'
        }

        let parametros = {
            'textTooltip': tooltip,
            'dataLabel': label,
            'graficoGrande': true
        }

        let { datos, datosTabla } = yield traerDatosGraficas(rango, "querySentimentAnalisis");

        datos["textTooltip"] = parametros.textTooltip;
        datos["dataLabel"] = parametros.dataLabel;



        yield put(obtenerDataGraficaQuerySentimentSuccess({ datos, datosTabla }))
        yield put(manageSpiner({ key: "tablaSentiment", state: false }));

        // yield obtenerDataQueryTextSentiment(rango.fechaIni, rango.fechaFin, obtenerDataGraficaQuerySentimentSuccess, parametros, company)

    } catch (error) {
        yield put(obtenerDataGraficaQuerySentimentError(error));
        throw error;

    }

}

function* obtenerDatosGraficaEncuesta(action) {
    try {
        yield put(manageSpiner({ key: "pie4", state: true }));
        let labels = [];
        let calificacion = [];
        let graficas = [];
        let rango = obtenerRangoDeFechas(action.value);
        let titulo = 'Calificación encuesta general';
        let tooltip = 'Gráfico que muestra las calificación general de la atención por chat.'
        let payload = { 'titulo': titulo, 'tooltip': tooltip };

        let resp = yield traerDatosGraficasBigQuery(rango, "estadisticasGenerales");

        if (resp) {
            resp.encuesta.map(item => {
                labels.push(item.label);
            });

            resp.encuesta.map(item => {
                calificacion.push(item.calificacion);
            });
        }
        graficas.push(labels)
        graficas.push(calificacion)
        payload["numeros"] = calificacion
        payload["labels"] = labels
        payload["graficas"] = graficas

        yield put(obtenerDatosGraficaEncuestaSuccess(payload))
        yield put(manageSpiner({ key: "pie4", state: false }));

    } catch (error) {


    }
}

function* filtrarConversacionesBigQuery(body) {
    try {
        const urlServicio = process.env.REACT_APP_CONSULTA_BIGQUERY;;
        const headers = HEADERS_WS;
        const respues = yield call(axios.post, urlServicio, body, headers);
        return respues.data;

    } catch (error) {
        throw `saja.js - filtrarConversacionesBigQuery - ${error}`
    }
}

function* paginarConvsCerradas(action) {

    try {
        const { first, rows, page } = action.value.event;
        const { ultimaConv, nextPage, seleccionRangoBusqueda } = action.value;

        const company = getFromSession('company_id');
        let reference = false;
        let esPrimeraPagina = false;
        let response = {};


        let convsFiltradas = []
        let consultaBig = yield select(getSearchBigqueryTable)

        if (consultaBig && !consultaBig.queryF) {
            consultaBig.limit = rows;
            consultaBig.page = first;
            yield put(saveSearchBigQueryTable({ ...consultaBig }))

            let convsFiltradasBig = yield filtrarConversacionesBigQuery(consultaBig);
            console.log(convsFiltradasBig)

            if (convsFiltradasBig && convsFiltradasBig.conversations && convsFiltradasBig.conversations.length > 0)
                convsFiltradas = { data: convsFiltradasBig.conversations, type: "bigquery" };
        } else {
            // let rango = obtenerRangoDeFechas(seleccionRangoBusqueda);
            // console.log(rango);
            // const timeIni = new Date(rango.fechaIni);
            // const timeFin = new Date(rango.fechaFin);

            // reference = firebaseDatabase.collection(`company/${company}/conversations`)
            //     .where("estado", "in", [10, 11, 12, 13]).where('fecha_ini', '>', timeIni).
            //     where('fecha_ini', "<", timeFin).orderBy("fecha_ini", "desc")

            reference = consultaBig.queryF;
            if (first == 0) {
                if (ultimaConv) {
                    reference = reference.limit(rows)
                } else if (!nextPage) {
                    reference = reference.limit(rows)
                } else {
                    reference = false
                }

            } else if (first > 0) {
                if (nextPage) {
                    reference = reference.startAfter(ultimaConv.fecha_ini).limit(rows)
                } else {
                    reference = reference.endBefore(ultimaConv.fecha_ini).limitToLast(rows)
                }
            }



            if (reference) {
                yield reference.get().then((result) => {
                    result.forEach(doc => {
                        convsFiltradas.push(doc);
                    });
                })
            }
        }

        if (convsFiltradas || convsFiltradas.length > 0) {
            let convsFiltradasData = yield procesarConvs(convsFiltradas, false)
            let encuesta = false

            let { isSurveyEnabled, preguntasEncuesta } = yield getFromSession('configurationVariables');
            if (isSurveyEnabled) {
                encuesta = preguntasEncuesta
            }

            const { convsCerradasDisplay, columns } = yield ordenarDatosTabla(convsFiltradasData, encuesta);

            let primeraPagina = convsCerradasDisplay.slice(0, 10);
            // console.log('primera pagina', primeraPagina);
            // console.log('convsCerradasDisplay', convsCerradasDisplay);

            response = {
                columns,
                "convsCerradasDisplay": primeraPagina,
                "listaFiltradaCompleta": convsCerradasDisplay
            }

            console.log('primera pagina', response)
            yield put(filtrarBusquedaSuccess(response))

        } else {
            esPrimeraPagina = true
        }

        yield put(paginarConvsCerradasSuccess(
            {
                "convsCerradasPaginadas": response.convsCerradasDisplay,
                "esPrimeraPagina": esPrimeraPagina
            }
        ))


    } catch (error) {
        yield put(paginarConvsCerradasError(error))
        throw error;

    }
}
function* paginarConvsCerradasAgentes(action) {
    try {
        const company = getFromSession('company_id');
        let reference = false;

        const { first, rows } = action.value.event;
        const { ultimaConv, nextPage, seleccionRangoBusqueda } = action.value;
        let rango = obtenerRangoDeFechas(seleccionRangoBusqueda);
        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);
        reference = firebaseDatabase.collection(`company/${company}/conversations`).where('estado', "in", [10, 11]).
            where("fecha_aceptacion", ">", timeIni).where("fecha_aceptacion", "<", timeFin).orderBy("fecha_aceptacion", "desc")

        if (first == 0) {
            if (ultimaConv) {
                reference = reference.limit(rows)
            } else if (!nextPage) {
                reference = reference.limit(rows)
            } else {
                reference = false
            }

        } else if (first > 0) {
            if (nextPage) {
                reference = reference.startAfter(ultimaConv.fecha_ini).limit(rows)
            } else {
                reference = reference.endBefore(ultimaConv.fecha_ini).limitToLast(rows)
            }
        }

        let convsCerradas = []
        let esPrimeraPagina = false;
        let response = {};
        if (reference) {
            yield reference.get().then((result) => {
                result.forEach(doc => {
                    let conv = doc.data()
                    convsCerradas.push({
                        "fechaIni": conv.fecha_ini.toDate(),
                        "fechaFin": conv.fecha_fin.toDate(),
                        "fechaAbandono": conv.fecha_abandono ? conv.fecha_abandono.toDate() : false,
                        "fechaAceptacion": conv.fecha_aceptacion ? conv.fecha_aceptacion.toDate() : false,
                        "fechaAsignacion": conv.fecha_asignacion ? conv.fecha_aceptacion.toDate() : false,
                        "fecha_ini": conv.fecha_ini,
                        "agente": conv.agente,
                        "asunto": conv.asunto,
                        "canal": conv.canal,
                        "cliente": conv.cliente,
                        "resuelta": conv.resuelta,
                        "encuesta": conv.encuesta ? conv.encuesta : false,
                        "tema": conv.tema ? conv.tema : false,
                        "idConv": doc.id
                    });
                });
            })

            let encuesta = false

            let { isSurveyEnabled, preguntasEncuesta } = yield getFromSession('configurationVariables');
            console.log("preguntasEncuesta", preguntasEncuesta)
            if (isSurveyEnabled) {
                encuesta = preguntasEncuesta
            }

            const { convsCerradasDisplay, columns } = yield ordenarDatosTabla(convsCerradas, encuesta);

            response = {
                convsCerradasDisplay,
                columns
            }

        } else {
            esPrimeraPagina = true
        }

        yield put(paginarConvsCerradasAgentesSuccess(
            {
                "convsCerradasPaginadas": response.convsCerradasDisplay,
                "esPrimeraPagina": esPrimeraPagina
            }
        ))

    } catch (error) {
        yield put(paginarConvsCerradasAgentesError(error))
        throw error;

    }
}



function* descargarCSVConvsCerradas(action) {
    try {

        let consulta = yield select(getSearchBigqueryTable);
        let rango = action.value;
        let agente = false;
        if (consulta && consulta.fechaIni)
            rango = { dateIni: consulta.fechaIni, dateFin: consulta.fechaFin }

        if (consulta && consulta.agente)
            agente = consulta.agente;
        const timeIni = new Date(rango.dateIni);
        const timeFin = new Date(rango.dateFin);
        const company = getFromSession("company_id");

        let { convsCerradasDisplay } = yield consultarConvsCerradas(timeIni, timeFin, company, agente)

        yield put(descargarCSVconvsCerradasSuccess(convsCerradasDisplay))


    } catch (error) {
        yield put(descargarCSVconvsCerradasError(error))
        throw error

    }

}
function* descargarCSVConvsCerradasAgentes(action) {
    try {

        let rango = obtenerRangoDeFechas(action.value);

        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);
        const company = getFromSession("company_id");

        let { convsCerradasDisplay } = yield consultarConvsCerradasAgentes(timeIni, timeFin, company)

        yield put(descargarCSVconvsCerradasAgentesSuccess(convsCerradasDisplay))

    } catch (error) {
        yield put(descargarCSVconvsCerradasError(error))
        throw error

    }

}


function* obtenerDataWsFallidos(action) {

    try {

        let rango = obtenerRangoDeFechas(action.value);

        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);
        const company = getFromSession("company_id");
        let dataWsFallidos = []

        let reference = firebaseDatabase.collection(`company/${company}/registrosFallidosWS`)
            .where('fechaRegistro', '>', timeIni).where('fechaRegistro', "<", timeFin).orderBy("fechaRegistro", "desc")

        yield reference.get().then(response => {
            //console.log('response.size', response.size);
            response.forEach(doc => {
                let data = doc.data();
                let fechaRegistro = moment(data.fechaRegistro.toDate()).format("lll");
                let fechaFalloRegistro = moment(data.fechaFalloReg.toDate(),).format("lll");
                dataWsFallidos.push({
                    fechaRegistro: fechaRegistro,
                    fechaFalloRegistro: fechaFalloRegistro,
                    flujoWS: data.flujoWS,
                    idConv: data.idconv,
                    canal: data.canal,
                    telefono: data.telefono ? data.telefono : "No aplica",
                    numeroDoc: data.data.numeroDoc ? data.data.numeroDoc : "No aplica",
                    intent: data.data.intent,
                    estado: data.estado,
                    nombreCausa: data.data.nameCa
                })
            });
        })


        yield put(obtenerDataWsFallidosSuccess({ dataTableWsFallidos: dataWsFallidos }))

    } catch (error) {
        console.error('saga.obtenerDataWsFallidos', error);
        throw error

    }


}

function* obtenerDatosConvsChatbot(action, limit = 10) {
    try {
        const rango = obtenerRangoDeFechas(action.value);
        let reference;

        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);
        const company = getFromSession("company_id");

        const botActivo = yield consultaProperty(company, "BOT_ENABLED")

        if (botActivo) {
            //vamos a consultar la subcoleccion de chatbotHistory donde estan los datos de las conversaciones con el bot
            let resultadoConsulta = []
            reference = firebaseDatabase.collectionGroup(`chatbotHistory`).where('company', "==", `${company}`).
                where('fecha', '>', timeIni).where('fecha', "<", timeFin).orderBy('fecha', 'desc').limit(limit);

            yield reference.get().then(response => {
                response.docs.map((doc, i) => {
                    resultadoConsulta.push({ chatbot: doc.data() })
                });

            })

            const dataFinal = resultadoConsulta;

            const data = yield crearDataTable({ data: dataFinal, coleccion: "chatbot" });
            // console.log('data', data);

            yield put(obtenerDatosConvsChatbotSuccess({ datosTablaConvsChatBot: data }))
        }

    } catch (error) {
        console.error('saga.obtenerDatosConvsChatbot', error);
        throw error
    }

}


function* paginarDatosConvsChatbot(action) {
    try {
        const company = getFromSession('company_id');
        let reference = false;
        let data = false;


        const { first, rows } = action.value.event;
        const { ultimaConv, nextPage, seleccionRangoBusqueda } = action.value;
        let rango = obtenerRangoDeFechas(seleccionRangoBusqueda);
        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);

        let ultimaConvFecha = ultimaConv.fechaOriginal


        reference = firebaseDatabase.collectionGroup(`chatbotHistory`).where('company', "==", `${company}`).
            where('fecha', '>', timeIni).where('fecha', "<", timeFin).orderBy('fecha', 'desc')

        let query = generarQueryConsultaPaginada(reference, first, ultimaConvFecha, nextPage, rows);

        let resultadoConsulta = []
        let esPrimeraPagina = false;

        if (query) {
            yield query.get().then(response => {
                response.docs.map((doc, i) => {
                    resultadoConsulta.push({ chatbot: doc.data() })

                });

            })

            const dataFinal = resultadoConsulta;

            data = yield crearDataTable({ data: dataFinal, coleccion: "chatbot" });


        } else {
            esPrimeraPagina = true
        }

        yield put(paginarDatosConvsChatbotSuccess(
            {
                "dataPaginada": data,
                "esPrimeraPagina": esPrimeraPagina
            }
        ))

    } catch (error) {
        console.error("saga.paginarDatosConvsChatbot", error);
        throw error
    }

}
function* obtenerDatosConvsPresupuestos(action, limit = 10) {
    try {
        const rango = obtenerRangoDeFechas(action.value);
        let reference;
        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);
        const company = getFromSession("company_id");

        const botActivo = yield consultaProperty(company, "BOT_ENABLED")

        if (botActivo) {
            //vamos a consultar la subcoleccion de chatbotHistory donde estan los datos de las conversaciones con el bot
            let resultadoConsulta = []
            reference = firebaseDatabase.collectionGroup(`votacionesPresupuestos`).where('exitoso', "==", true).
                where('fechaRegistro', '>', timeIni).where('fechaRegistro', "<", timeFin).orderBy('fechaRegistro', 'desc').limit(limit);

            yield reference.get().then(response => {
                response.docs.map((doc, i) => {
                    resultadoConsulta.push(doc.data())
                });

            })

            const dataFinal = resultadoConsulta;

            const data = yield crearDataTable({ data: dataFinal, coleccion: "presupuestos" });

            yield put(obtenerDatosConvsPresupuestosSuccess(data))
        }


    } catch (error) {
        console.error('saga.obtenerDatosConvsChatbot', error);
        throw error
    }

}
function* paginarDatosConvsPresupuestos(action) {
    try {
        let reference = false;
        let data = false;
        /* debugger */
        const { first, rows } = action.value.event;
        const { ultimaConv, nextPage, seleccionRangoBusqueda } = action.value;
        let rango = obtenerRangoDeFechas(seleccionRangoBusqueda);
        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);
        /* console.log('ultimaConv', ultimaConv); */
        let ultimaConvFecha = ultimaConv.fechaOriginal

        reference = firebaseDatabase.collectionGroup(`votacionesPresupuestos`).where('exitoso', "==", true).
            where('fechaRegistro', '>', timeIni).where('fechaRegistro', "<", timeFin).orderBy('fechaRegistro', 'desc')

        let query = generarQueryConsultaPaginada(reference, first, ultimaConvFecha, nextPage, rows);

        let resultadoConsulta = []
        let esPrimeraPagina = false;

        if (query) {
            yield query.get().then(response => {
                response.docs.map((doc, i) => {
                    resultadoConsulta.push(doc.data())

                });

            })

            const dataFinal = resultadoConsulta;

            data = yield crearDataTable({ data: dataFinal, coleccion: "presupuestos" });


        } else {
            esPrimeraPagina = true
        }

        yield put(paginarDatosConvsPresupuestosSuccess(
            {
                "dataPaginada": data,
                "esPrimeraPagina": esPrimeraPagina
            }
        ));
    } catch (error) {
        console.error("saga.paginarDatosConvsChatbot", error);
        throw error
    }

}



function* obtenerDatosRespUnknownBot(action, limit = 10) {
    try {
        yield put(manageSpiner({ key: "tablaRespUnknown", state: true }));
        const rango = obtenerRangoDeFechas(action.value);
        let reference;

        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);
        const company = getFromSession("company_id");

        const botActivo = yield consultaProperty(company, "BOT_ENABLED")
        if (botActivo) {
            //vamos a consultar la subcoleccion de chatbotHistory donde estan los datos de las conversaciones con el bot
            let resultadoConsulta = []
            reference = firebaseDatabase.collectionGroup(`chatbotHistory`).where('company', "==", `${company}`).
                where('intentName', "==", "Default Fallback Intent").
                where('fecha', '>', timeIni).where('fecha', "<", timeFin).orderBy('fecha', 'desc').limit(limit);

            yield reference.get().then(response => {
                response.docs.map((doc, i) => {
                    resultadoConsulta.push({ chatbot: doc.data() })
                });

            })

            const dataFinal = resultadoConsulta;

            const data = yield crearDataTable({ data: dataFinal, coleccion: "chatbotUnknown" });


            yield put(obtenerDatosRespUnknownBotSuccess({ datosRespBot: data }))
            yield put(manageSpiner({ key: "tablaRespUnknown", state: false }));
        }


    } catch (error) {
        console.error('saga.obtenerDatosRespBot', error);
        throw error
    }
}



function* paginarDatosRespUnknownBot(action) {
    try {
        const company = getFromSession('company_id');
        let reference = false;
        let data = false;
        /* debugger */

        const { first, rows } = action.value.event;
        const { ultimaConv, nextPage, seleccionRangoBusqueda } = action.value;
        let rango = obtenerRangoDeFechas(seleccionRangoBusqueda);
        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);
        /*   console.log('ultimaConvDatosRespUnknownBot', ultimaConv); */

        const ultimaConvFecha = ultimaConv.fechaOriginal

        reference = firebaseDatabase.collectionGroup(`chatbotHistory`).
            where('company', "==", `${company}`).
            where('intentName', "==", "Default Fallback Intent").
            where('fecha', '>', timeIni).where('fecha', "<", timeFin).orderBy('fecha', 'desc')

        let query = generarQueryConsultaPaginada(reference, first, ultimaConvFecha, nextPage, rows);

        let resultadoConsulta = []
        let esPrimeraPagina = false;

        if (query) {
            yield query.get().then(response => {
                response.docs.map((doc, i) => {
                    resultadoConsulta.push({ chatbot: doc.data() })
                });
            })
            const dataFinal = resultadoConsulta;
            data = yield crearDataTable({ data: dataFinal, coleccion: "chatbot" });
        } else {
            esPrimeraPagina = true
        }
        yield put(paginarDatosRespUnknownBotSuccess(
            {
                "dataPaginada": data,
                "esPrimeraPagina": esPrimeraPagina
            }
        ))
    } catch (error) {
        console.error('saga.paginarDatosRespUnknownBot', error);
        throw error
    }

}

function* obtenerDatosIntentsPorcentajeUso(action) {
    try {


        yield put(manageSpiner({ key: "tablaIntentsPorcentaje", state: true }));
        const rango = obtenerRangoDeFechas(action.value);
        const company = getFromSession("company_id");
        let resp = false
        let itemsRespuesta = []

        const botActivo = yield consultaProperty(company, "BOT_ENABLED")

        if (botActivo) {
            //vamos a consultar la subcoleccion de chatbotHistory donde estan los datos de las conversaciones con el bot
            resp = yield traerDatosGraficasBigQuery(rango, "intentsPorcentaje");
        }
        //  console.log('obtenerDatosIntentsPorcentajeUsoBigQuery', resp.respuesta);
        if (resp) {
            resp.respuesta.forEach((item, i) => {
                itemsRespuesta.push({
                    intent: item.INTENT,
                    total: item.tot,
                    porcentaje: item.porc
                })
            })
        }

        yield put(obtenerDatosIntentsPorcentajeUsoSuccess({ "dataIntent": itemsRespuesta }))
        yield put(manageSpiner({ key: "tablaIntentsPorcentaje", state: false }));

    } catch (error) {
        console.error('saga.obtenerDatosRespBot', error);
        throw error
    }

}

function* obtenerDataTasaCoincidenciaBot(action) {

    try {


        yield put(manageSpiner({ key: "tablaTasaCoincidencia", state: true }));
        const rango = obtenerRangoDeFechas(action.value);
        const company = getFromSession("company_id");
        let label = []
        let labels = []
        let tot = []
        let contestada = []
        let noContestada = []
        let tasa = []
        let bar = false
        let array = []
        let fechaCor = []
        let dataCsvTotal = []
        let arrayCsv = []
        let dataCsvContestadas = []
        let dataCsvNoContestadas = []
        let dataCsvTasa = []

        const botActivo = yield consultaProperty(company, "BOT_ENABLED");

        if (botActivo) {
            bar = yield traerDatosGraficasBigQuery(rango, "tasaCoincidenciaBot");
        }
        // console.log("graficos barras originales", bar.datasets)

        bar.respuesta.map(item => {
            label.push(item.fecha);
        });


        bar.respuesta.map(item => {
            tot.push(item.total)
            contestada.push(item.contestadas)
            noContestada.push(item.no_contestadas)
            tasa.push(item.tasa)
        });


        tot.forEach((item, i) => {
            dataCsvTotal.push({
                fecha: fechaCor[i],
                valor: item
            })
        })

        label.map(item => {
            labels.push(item.value)
        })

        for (var i = 0; i < label.length; i++) {
            var split = labels[i].split("T");
            fechaCor.push(split[0]);
        }

        bar.respuesta.map((item, i) => {
            arrayCsv.push({
                fecha: fechaCor[i],
                total: item.total,
                contestadas: item.contestadas,
                no_contestadas: item.no_contestadas,
                tasa: item.tasa
            })
        });

        let totalR = {
            type: 'line',
            borderColor: setBg(),
            borderWidth: 2,
            data: tot,
            fill: false,
            label: 'Total respuestas',
            tension: 0.4,
        }


        let contestadasR = {
            type: 'line',
            borderColor: setBg(),
            borderWidth: 2,
            data: contestada,
            fill: false,
            label: 'Preguntas contestadas',
            tension: 0.4,
        }

        contestada.forEach((item, i) => {
            dataCsvContestadas.push({
                fecha: fechaCor[i],
                valor: item
            })
        })

        let noContestadasR = {
            type: 'line',
            borderColor: setBg(),
            borderWidth: 2,
            data: noContestada,
            fill: true,
            label: 'Preguntas no contestadas',
            tension: 0.4,
        }

        noContestada.forEach((item, i) => {
            dataCsvNoContestadas.push({
                fecha: fechaCor[i],
                valor: item
            })
        })

        let tasaR = {
            type: 'bar',
            backgroundColor: setBg(),
            borderColor: "white",
            borderWidth: 2,
            data: tasa,
            label: 'Tasa preguntas contestadas',
            yAxisID: "y"
        }


        tasa.forEach((item, i) => {
            dataCsvTasa.push({
                fecha: fechaCor[i],
                valor: item
            })
        })

        const dataCsv = {
            dataCsvTotal,
            dataCsvContestadas,
            dataCsvNoContestadas,
            dataCsvTasa,
            arrayCsv
        }


        array.push(tasaR)
        array.push(totalR)
        array.push(noContestadasR)
        array.push(contestadasR)

        let respueta = {
            labels: fechaCor,
            datasets: array,
            dataCsv
        }

        //    console.log("respuesta", respueta)



        yield put(obtenerDataTasaCoincidenciaBotSuccess(respueta))
        yield put(manageSpiner({ key: "tablaTasaCoincidencia", state: false }));


    } catch (error) {
        console.error('saga.obtenerDataTasaCoincidenciaBot', error);
        throw error

    }

}

function* obtenerDataComentariosInsatisfaccion(action, limit = 10) {
    try {

        yield put(manageSpiner({ key: "tablaComentariosInsatisfactorios", state: true }));
        const rango = obtenerRangoDeFechas(action.value);
        let reference;

        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);
        const company = getFromSession("company_id");

        const botActivo = yield consultaProperty(company, "BOT_ENABLED")
        if (botActivo) {

            let resultadoConsulta = []
            reference = firebaseDatabase.collection(`company/${company}/conversations`)
                .where("encuesta.ratingStars", "in", [2, 1, 3, "2", "1", "3"])
                .where("fecha_ini", ">", timeIni).where("fecha_ini", "<", timeFin).orderBy("fecha_ini", "desc")
                .limit(limit)

            yield reference.get().then(response => {
                //console.log('response.size', response.size);
                response.docs.map((doc, i) => {
                    let docData = doc.data()
                    resultadoConsulta.push({ chatbot: docData })
                });
            })

            const dataFinal = resultadoConsulta;
            const data = yield crearDataTable({ data: dataFinal, coleccion: "chatbotComentarios" });
            // console.log('dataComentarios Insatisfaccion****', data);
            yield put(obtenerDataComentariosInsatisfaccionSuccess({ comentariosInsatisfaccion: data }))
            yield put(manageSpiner({ key: "tablaComentariosInsatisfactorios", state: false }));
        }

    } catch (error) {
        console.error('saga.obtenerDataComentariosInsatisfaccion', error);
        throw error
    }

}

function* paginarDataComentariosInsatisfaccion(action) {
    try {
        /*   console.log('entro a paginarrrrr comentarios insatisfaccion'); */
        const company = getFromSession('company_id');
        let reference = false;
        let data = false;
        /* debugger */

        const { first, rows } = action.value.event;
        const { ultimaConv, nextPage, seleccionRangoBusqueda } = action.value;
        let rango = obtenerRangoDeFechas(seleccionRangoBusqueda);
        const timeIni = new Date(rango.fechaIni);
        const timeFin = new Date(rango.fechaFin);

        const ultimaConvFecha = ultimaConv.fechaOriginal

        reference = firebaseDatabase.collection(`company/${company}/conversations`)
            .where("encuesta.ratingStars", "in", [2, 1, 3, "2", "1", "3"])
            .where("fecha_ini", ">", timeIni).where("fecha_ini", "<", timeFin).orderBy("fecha_ini", "desc")

        let query = generarQueryConsultaPaginada(reference, first, ultimaConvFecha, nextPage, rows);

        let resultadoConsulta = []
        let esPrimeraPagina = false;

        if (query) {
            yield query.get().then(response => {
                response.docs.map((doc, i) => {
                    resultadoConsulta.push({ chatbot: doc.data() })
                });
            })
            const dataFinal = resultadoConsulta;
            data = yield crearDataTable({ data: dataFinal, coleccion: "chatbotComentarios" });
        } else {
            esPrimeraPagina = true
        }
        yield put(paginarDataComentariosInsatisfaccionSuccess(
            {
                "dataPaginada": data,
                "esPrimeraPagina": esPrimeraPagina
            }
        ))

    } catch (error) {
        console.error('saga.paginarDataComentariosInsatisfaccion', error);
        throw error

    }

}

function formatearFechaServidor(fecha) {

    const date = new Date(fecha)
    const timeZone = 'America/Bogota'
    const zonedDate = utcToZonedTime(date, timeZone)
    const fechaJs = new Date(zonedDate)
    let fechaFormateada = moment(fechaJs).format("lll");

    return fechaFormateada
}

function* generaCsv(action) {
    try {

        const { nombreConsulta, seleccionRangoBusqueda } = action.value
        console.log(nombreConsulta);
        let data = []
        const rango = obtenerRangoDeFechas(seleccionRangoBusqueda);

        let resp = yield traerDataGenerarCSV(rango, nombreConsulta);


        if (nombreConsulta == "convsChatbot") {
            resp.forEach(doc => {
                const fecha = formatearFechaServidor(doc.fecha)
                data.push({
                    fecha: fecha,
                    company: doc.chatbot.company,
                    contestada: doc.chatbot.contestada ? "SI" : "NO",
                    canal: doc.chatbot.canal,
                    intentName: doc.chatbot.intentName,
                    user_message: doc.chatbot.user_message,
                })
            });

            yield put(generarCsvConvsChatbotSuccess({ dataGenerada: data }))

        } else if (nombreConsulta == "dataUnknownBot") {
            resp.forEach(doc => {
                const fecha = formatearFechaServidor(doc.fecha)
                data.push({
                    fecha: fecha,
                    canal: doc.chatbot.canal,
                    user_message: doc.chatbot.user_message,
                })
            });

            yield put(generarCsvDataUnknownBotSuccess({ dataGenerada: data }))

        } else if (nombreConsulta == "comentariosInsatisfaccion") {
            resp.forEach(doc => {
                const fecha = formatearFechaServidor(doc.fecha)
                data.push({
                    fecha: fecha,
                    canal: doc.chatbot.canal,
                    calification: doc.chatbot.encuesta["ratingStars"],
                    comentario: doc.chatbot.encuesta["comentarios"] ? doc.chatbot.encuesta["comentarios"] : "Sin comentarios"
                })
            });
            yield put(generarCsvDataInsatisfaccionSuccess({ dataGenerada: data }))
        }

    } catch (error) {
        console.error('saga.generarCsv', error);
        throw error
    }

}

function* obtenerDatosGraficaTop10(action) {


    try {
        /* console.log('entro a saga obtenerDatosGraficaTop10'); */
        yield put(manageSpiner({ key: "pieTop10", state: true }));
        const rango = obtenerRangoDeFechas(action.value);
        const company = getFromSession("company_id");
        let resp = false
        let resp2 = false
        let resp3 = false
        let intentsPorcentaje = []

        let intentsAgrupadosBigQuery = {}
        const botActivo = yield consultaProperty(company, "BOT_ENABLED")
        let intentsNombreCodigo = yield consultaProperty(company, 'INTENTS_NOMBRE_CODIGO');
        if (botActivo) {

            resp2 = yield traerDatosGraficasBigQuery(rango, "top10respuestasBot")
            resp3 = yield traerDatosGraficasBigQuery(rango, "intentsPorcentaje")
            resp3.respuesta.forEach(item => {
                intentsPorcentaje.push({ intent: item.INTENT, total: item.tot, porcentaje: `${item.porc} %` })
            })

            if (resp2) {

                resp2.respuesta.forEach(element => {
                    intentsAgrupadosBigQuery[emptyObject(element?.titulo)] = element.tot
                });

                // Ajustar fechas
                ///INTENTS AGRUPADOS INICIO

                let intentsAgrupados = {}

                let datosPieIntentsAgrupados = {}
                let colors = []
                let datosPieCsv = []
                let datosAgrupadoCsv = []

                datosPieIntentsAgrupados['labels'] = Object.keys(intentsAgrupadosBigQuery)
                datosPieIntentsAgrupados.labels.forEach(element => colors.push(setBg()))
                datosPieIntentsAgrupados['datasets'] = [
                    { data: Object.values(intentsAgrupadosBigQuery), backgroundColor: colors }
                ]

                ///INTENTS AGRUPADOS FIN
                var pie = {};
                for (const key in intentsAgrupadosBigQuery) {
                    datosPieCsv.push({
                        nombre: `${key}`,
                        total: intentsAgrupadosBigQuery[key]
                    })
                }
                if (intentsPorcentaje.length > 0) {
                    //ordenar datos de mayor a menor
                    intentsPorcentaje.sort(ordenarPie);

                    pie = {
                        labels: [],
                        datasets: [{
                            data: [],
                            backgroundColor: [],
                        }],
                    }
                    for (const key in datosPieIntentsAgrupados) {
                        let itemAgrupado = {}
                        itemAgrupado[key] = intentsPorcentaje[key];

                        datosAgrupadoCsv.push(itemAgrupado)
                    }


                    // console.log("intentsPorcentaje", intentsPorcentaje)



                    //top diez
                    var con = 1;
                    var conOtros = 0;
                    intentsPorcentaje.forEach(element => {
                        if (con < 10) {
                            pie.labels.push(element.intent);
                            pie.datasets[0].data.push(element.total);
                            pie.datasets[0].backgroundColor.push(setBg());
                        } else {
                            conOtros = conOtros + element.total;
                        }
                        con += 1;

                    })
                    if (conOtros > 0) {
                        intentsPorcentaje.push({
                            intent: 'OTROS',
                            total: conOtros,
                            porcentaje: 'N/A'
                        })
                        pie.labels.push("Otras respuestas");
                        pie.datasets[0].data.push(conOtros);
                        pie.datasets[0].backgroundColor.push(setBg());
                    }
                }

                yield put(obtenerDatosGraficaTop10Success({ pie: pie, datosPieIntentsAgrupados: datosPieIntentsAgrupados, datosPieCsv: intentsPorcentaje, datosAgrupadoCsv: datosPieCsv }))
                yield put(manageSpiner({ key: "pieTop10", state: false }));
            }
        }

    } catch (error) {
        console.error('saga.obtenerDatosGraficaTop10', error);
        throw error
    }

}
function* obtenerTablaPromedios(resp) {
    let tableRespuesta = [];
    if (resp) {
        resp.forEach(element => {
            if (element && element.data) {
                let cantidadReg = 0;
                let sumReg = 0;
                element.data.forEach(e => {
                    if (e > 0) {
                        cantidadReg += 1;
                        sumReg += e;
                    }
                });
                tableRespuesta.push({ label: element.label, value: (Math.floor(sumReg / cantidadReg)) })

            }
        });
    }

    return tableRespuesta;
}
function* obtenerTmpPromAgentes(action) {
    try {

        yield put(manageSpiner({ key: "estadisticasPorAgente", state: true }));
        const rango = obtenerRangoDeFechas(action.value);

        let resp = false;
        resp = yield traerDatosGraficasBigQuery(rango, "tmpPromAgentes");
        var tipo = yield crearLabelChart({ rango });
        var banTipo = tipo.banTipo;
        let agentes = []

        let respGraficaFueraHorario = [];
        let fechaGraficaFueraHorario = [];
        let totalGraficaFueraHorario = [];
        let fechaCor = [];
        let horaCor = [];
        let labelGraficaFueraHorario = [];
        let dataGraficaFueraHorario = [];

        if (resp) {
            resp.graficaFueraHorario.map((doc, i) => {
                totalGraficaFueraHorario.push(doc.total);
                fechaGraficaFueraHorario.push(doc.fec);
            });

            fechaGraficaFueraHorario.map((doc, i) =>
                respGraficaFueraHorario.push(doc.value)
            )

            for (var i = 0; i < respGraficaFueraHorario.length; i++) {
                var split = respGraficaFueraHorario[i].split(/[T.]+/);
                fechaCor.push(split[0]);
                horaCor.push(split[1]);
            }
        }

        if (action.value === 'HOY' || action.value === 'AYER') {
            labelGraficaFueraHorario = horaCor
        } else {
            labelGraficaFueraHorario = fechaCor
        }

        totalGraficaFueraHorario.forEach((item, i) => {
            dataGraficaFueraHorario.push({
                fec: labelGraficaFueraHorario[i],
                total: item
            })
        })

        let respuestaGraficaFueraHorario = {
            labels: labelGraficaFueraHorario,
            datasets: [{
                label: 'Conversaciones fuera horario',
                backgroundColor: setBg(),
                data: totalGraficaFueraHorario
            }]
        }

        resp["graficaFueraHorario"] = respuestaGraficaFueraHorario;

        const columGraficaFueraHorario = [
            { field: "fec", header: "Fecha Conversaciones" },
            { field: "total", header: "Total conversaciones" }
        ]

        if (resp && resp.graficaAgentesFecha) {

            yield resp.graficaAgentesFecha.map((doc, i) => {
                agentes.push(doc.f0_);
            });
        }
        const agentesUni = agentes.filter(onlyUnique);
        var finalAgentes = []
        var tableAgentes = []

        agentesUni.forEach(item => {
            if (item.length > 0) {
                const auxTemp = []
                var contConv = 0;
                var tot = 0;

                tipo.tipo.forEach(tipos => {
                    var cont = 0;
                    resp.graficaAgentesFecha.forEach(element => {

                        if (banTipo) {
                            const tem = tipos.split("-")
                            const date_ = new Date(element.fecha.value)

                            let a = tipos.split("/")
                            a = a[1] + "/" + a[0] + "/" + a[2]
                            a = new Date(a)
                            const [month, day, year] = [a.getMonth() + 1, a.getDate() - 1, a.getFullYear()];

                            if (year == date_.getFullYear() && month == date_.getMonth() + 1 && day == date_.getDate()) {
                                if (item === element.f0_) {
                                    cont += 1;
                                    contConv += 1;
                                    tot = element.tot
                                }
                            }

                        } else {

                            var d = new Date(element?.fecha.value);
                            const tem2 = tipos.split(":")
                            if (tem2[0] === new Date(element?.fecha.value).getUTCHours().toString()) {
                                if (item == emptyObject(element?.f0_)) {
                                    cont += 1;
                                    contConv += 1;
                                    tot = element.tot
                                }
                            }
                        }
                    });
                    auxTemp.push(tot);
                    tot = 0;
                });

                tableAgentes.push({ agente: item, totalC: contConv })
                finalAgentes.push(
                    {
                        agente: item,
                        type: 'bar',
                        label: item,
                        backgroundColor: setBg(),
                        data: auxTemp,
                    }
                )
            }
        });

        var barchart = {
            labels: tipo.tipo,
            datasets: []
        }

        tableAgentes.forEach(element => {
            finalAgentes.forEach(item => {
                if (item.agente === element.agente) {
                    barchart.datasets.push({
                        type: item.type,
                        label: item.label,
                        backgroundColor: item.backgroundColor,
                        data: item.data,
                    });
                }
            });
        });
        // yield put(obtenerDataConvsAgentesSuccess({ barchart, barcharTotal }));
        yield put(obtenerDataConvsAgentesSuccess(barchart));


        let f0_ = []
        let tot = []

        if (resp && resp.graficaAgentesTotal) {

            yield resp.graficaAgentesTotal.map((doc, i) => {
                f0_.push(doc.f0_);
                tot.push(doc.tot);
            });
        }


        let respuesta = {
            labels: f0_,
            datasets: [{
                label: 'Conversaciones',
                backgroundColor: setBg(),
                data: tot
            }]
        }
        resp["graficoAgente"] = respuesta;

        const colum = [
            { field: "f0_", header: "Nombres Agente" },
            { field: "tot", header: "Total conversaciones" },
            { field: "tiempoRespuesta", header: "Tiempo de respuesta*" },
            { field: "tiempoEsperaAgente", header: "Tiempo de Espera Agente*" },
        ]


        yield put(obtenerDatatTableAgentesSuccess({ data: resp.graficaAgentesTotal, columnsTable: colum, columnsTableFueraHorario: columGraficaFueraHorario, dataFueraHorario: dataGraficaFueraHorario }));
        yield put(obtenerTmpPromAgentesSuccess(resp))
        yield put(manageSpiner({ key: "estadisticasPorAgente", state: false }));
    } catch (error) {
        yield put(obtenerDataConvsAgentesError())
        console.error('saga.obtenerTmpPromAgentes', error);
        throw error
    }

}

function* obtenerConvsResueltasAgentes(action) {
    try {
        yield put(manageSpiner({ key: "pieConvsResueltasAgentes", state: true }));
        const rango = obtenerRangoDeFechas(action.value);
        let resp = false;
        resp = yield traerDatosGraficasBigQuery(rango, "convsResueltasAgentes");

        let labels = ['Resueltas', 'No resueltas']
        let titulo = 'Conversaciones resueltas';
        let tooltip = 'Gráfico que muestra cuantas conversaciones fueron o no resueltas'
        let payload = {
            'labels': labels,
            'titulo': titulo,
            'tooltip': tooltip
        }

        payload['numeros'] = resp.numeros
        payload['totalConvs'] = resp.totalConvs

        let labelsAgentes = [];
        let numerosAgentes = []
        if (resp && resp.canales) {

            yield resp.canales.map((doc, i) => {
                labelsAgentes.push(doc.canal);
                numerosAgentes.push(doc.total);
            });

            payload['canalesAgentes'] = { labels: labelsAgentes, numeros: numerosAgentes, titulo: "Grafico conversaciones por canal", tooltip: "Cantidad de conversaciones atendidas por diferentes canales" }
            yield put(obtenerConvsResueltasAgentesSuccess(payload))
            yield put(manageSpiner({ key: "pieConvsResueltasAgentes", state: false }));
        }

    } catch (error) {
        yield put(obtenerConvsResueltasAgentesError())
        console.error('saga.obtenerConvsResueltasAgentes', error);
        throw error
    }

}

function* encuestasAgentes(action) {
    try {
        yield put(manageSpiner({ key: "pieEncuestasAgentes", state: true }));
        const rango = obtenerRangoDeFechas(action.value);
        let resp = false;
        resp = yield traerDatosGraficasBigQuery(rango, "calificacionEncuestaGeneral");

        let titulo = 'Encuesta de satisfaccion';
        let tooltip = 'Gráfico que muestra las calificaciones de la atencion de los agentes'

        let labelsEncuesta = [];
        let calificacion = []
        if (resp) {
            yield resp.map((doc, i) => {
                labelsEncuesta.push(doc.label);
                calificacion.push(doc.calificacion);
            });
        }

        let payload = {
            'titulo': titulo,
            'tooltip': tooltip,
            'labels': labelsEncuesta,
            'numeros': calificacion
        }

        yield put(encuestasAgentesSucess(payload))
        yield put(manageSpiner({ key: "pieEncuestasAgentes", state: false }));

    } catch (error) {
        yield put(encuestasAgentesError())
        console.error('saga.obtenerConvsResueltasAgentes', error);
        throw error
    }

}


function* graficarTmpPromRespAgentes(action) {
    try {
        const rango = obtenerRangoDeFechas(action.value);

        let resp = false;
        resp = yield traerDatosGraficas(rango, "barrasTmpRespAgentes");
        const tiempoProd = yield obtenerTablaPromedios(resp.datasets);

        const data = yield crearDataTable({ data: tiempoProd, coleccion: "tablePromedios" });
        yield put(tablesPromAgent({ field: "tableResAgent", data }));
        yield put(graficarTmpPromRespAgentesSuccess(resp))

    } catch (error) {
        console.error('saga.graficarTmpPromRespAgentes', error);
        throw error
    }
}

function* graficarTmpPromEspAgentes(action) {
    try {
        const rango = obtenerRangoDeFechas(action.value);

        let resp = false;
        resp = yield traerDatosGraficas(rango, "barrasTmpEspAgentes")
        const tiempoProd = yield obtenerTablaPromedios(resp.datasets);

        const data = yield crearDataTable({ data: tiempoProd, coleccion: "tablePromedios" });
        yield put(tablesPromAgent({ field: "tableEspeAgentes", data }));
        yield put(graficarTmpPromEspAgentesSuccess(resp))

    } catch (error) {
        console.error('saga.graficarTmpPromEspAgentes', error);
        throw error

    }
}

function* graficarServiciosExternos(action) {
    console.log('saga.graficarServiciosExtern', action);
    let reference = false;
    const company = getFromSession("company_id");

    let rango = obtenerRangoDeFechas(action.value.selectedForm);

    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);
    reference = yield firebaseDatabase.collection(`company/${company}/serviciosExternos/REPORTE_HUECOS/data`)
        .where("fechaRegistro", ">=", timeIni)
        .where("fechaRegistro", "<", timeFin)
        //.orderBy("fechaRegistro", "asc")
        //  
        // where('cola', "==", "S").
        //orderBy('fechaRegistro', 'desc').
        .get();
    const respuesta = [];
    yield reference.docs.map((doc, i) => {
        respuesta.push(doc.data())
    });

    const graficoBar = yield createGraficoServiciosExternos({ respuesta, rango })

    console.log("graficoBar", graficoBar);

    const data = yield crearDataTable({ data: respuesta, coleccion: "reporteHuecos" });

    yield put(sendTablaDatosReporteHuecos({ dataHuecos: data, graficoBar }));
    console.log("data servicios externos", data);
}

function* createGraficoServiciosExternos(action) {
    try {
        const { respuesta, rango } = action;

        var tipo = yield crearLabelChart({ rango });
        var banTipo = tipo.banTipo;
        let dataServicios = [];
        let dataFinalExterno = [];
        tipo.tipo.forEach(tipos => {
            let con = 0;
            respuesta.forEach(element => {

                if (banTipo) {
                    //rango de fechas viene en dias 
                    const tem = tipos.split("/")
                    if (parseInt(tem[2]) === element.fechaRegistro.toDate().getFullYear() && parseInt(tem[1]) === element.fechaRegistro.toDate().getMonth() + 1 && parseInt(tem[0]) === element.fechaRegistro.toDate().getDate()) {
                        con += 1;
                    }

                } else {
                    //rango de fecha viene en horas
                    const tem2 = tipos.split(":")
                    if (tem2[0] === element.fechaRegistro.toDate().getHours().toString()) {

                        con += 1;
                    }
                }


            });
            dataServicios.push(con);

        });

        dataFinalExterno.push(

            {
                agente: "Servicios Externos",
                type: 'bar',
                label: "Servicios Externos",
                backgroundColor: setBg(),
                data: dataServicios,
            }
        )



        var barchart = {
            labels: tipo.tipo,
            datasets: dataFinalExterno,
        }

        return barchart;
    } catch (error) {
        console.error("exception - ", error)
    }
}



function* tablaHuecos(action) {
    // console.log('saga.tablaHuecos', action);
    let reference = false;
    const company = getFromSession("company_id");

    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);
    reference = yield firebaseDatabase.collection(`company/${company}/serviciosExternos/REPORTE_HUECOS/data`).
        where('fechaRegistro', ">", timeIni).
        where('fechaRegistro', "<", timeFin).
        orderBy('fechaRegistro', 'desc').
        get();
    const respuesta = [];
    yield reference.docs.map((doc) => {
        respuesta.push(doc.data())
    });


    const data = yield crearDataTable({ data: respuesta, coleccion: "reporteHuecos" });
    // console.log('data servicios exsternos',data)
    yield put(sendTablaDatosReporteHuecos(data));

}



function* obtenerReporteHuecos(action) {
    try {
        let rango = obtenerRangoDeFechas(action.value);

        let color = '#d35ce6';
        let colorFondo = '#e49cf0'
        let label = 'Conversaciones cerradas';

        let payload = {
            'dataLabel': label,
            'color': color,
            'colorBackground': colorFondo,
            'graficoGrande': true
        }

        let resp = yield traerDatosGraficas(rango, "reporteHuecos");




        payload["labels"] = resp.payload?.labels;
        payload["ejeX"] = resp.payload?.ejeX;
        payload["ejeY"] = resp.payload?.ejeY;


        yield put(sendGraficoDatosReporteHuecos({ payload, 'size': resp.size }))


    } catch (error) {
        console.error('saga.obternerDatosGraficaLineChart', error);

    }
}


function* obtenerDataZonaParqueo(action) {

    let reference = false;
    const company = getFromSession("company_id");

    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);
    reference = yield firebaseDatabase.collection(`company/${company}/serviciosExternos/REPORTE_ZONAS_PARQUEO/data`).
        where('fechaRegistro', ">", timeIni).
        where('fechaRegistro', "<", timeFin).
        orderBy('fechaRegistro', 'desc').
        get();
    const respuesta = [];
    yield reference.docs.map((doc) => {
        respuesta.push(doc.data())
    });


    const data = yield crearDataTable({ data: respuesta, coleccion: "zonaParqueo" });

    yield put(sendTablaDatosZonaParqueo(data));

}


function* obtenerDataRegistroGAB(action) {

    let reference = false;
    const company = getFromSession("company_id");

    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);
    reference = yield firebaseDatabase.collection(`company/${company}/serviciosExternos/REPORTE_REGISTRO_GAB/data`).
        where('fechaRegistro', ">", timeIni).
        where('fechaRegistro', "<", timeFin).
        orderBy('fechaRegistro', 'desc').
        get();
    const respuesta = [];
    yield reference.docs.map((doc) => {
        respuesta.push(doc.data())
    });


    const data = yield crearDataTable({ data: respuesta, coleccion: "registroGAB" });

    yield put(sendTablaDatosRegistroGAB(data));

}
function* obtenerDataAtencionCanal(action) {

    yield put(manageSpiner({ key: "tablaDatosAtencionCanal", state: true }));
    let reference = false;
    const company = getFromSession("company_id");

    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);


    let respuestaConsolidado = yield consumeConsolidadoCanal({
        company,
        "fechaIni": timeIni,
        "fechaFin": timeFin,
        "nombreGrafica": "estadisticaAgenteConsolidadoAtencionCanal"
    })
    const data = yield crearDataTable({ data: respuestaConsolidado.respuesta, coleccion: "dataAtencionCanal" });

    yield put(sendTablaDatosAtencionCanal(data));
    yield put(manageSpiner({ key: "tablaDatosAtencionCanal", state: false }));

}


function* obtenerDataAtencionAgente(action) {
    // console.log('saga.obtenerDataAtencionAgente*****', action);
    yield put(manageSpiner({ key: "tablaDatosAtencionAgente", state: true }));
    let reference = false;
    const company = getFromSession("company_id");

    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);

    let respuestaConsolidado = yield consumeConsolidadoCanal({
        company,
        "fechaIni": timeIni,
        "fechaFin": timeFin,
        "nombreGrafica": "estadisticaAgenteConsolidadoAtencionAgente"
    })
    const data = yield crearDataTable({ data: respuestaConsolidado.respuesta, coleccion: "dataAtencionAgente" });
    yield put(sendTablaDatosAtencionAgente(data));
    yield put(manageSpiner({ key: "tablaDatosAtencionAgente", state: false }));

}


function* consumeConsolidadoCanal(action) {
    /* Función que recibe el documento y tipo de documento del afiliado
        y consulta el validador de derechos y retorna la información del afiliado+
     */
    const { company, fechaIni, fechaFin, nombreGrafica } = action;
    var date = "";
    var date = new Date();
    const urlConsulta = process.env.REACT_APP_CONSULTA_BIGQUERY;
    const headers = HEADERS_WS;
    const body = action;
    var resul = [];
    try {

        const data = [];
        data.push(urlConsulta)
        data.push(headers)
        data.push(body)
        const ax = (data) => {
            return axios({ method: "post", url: data[0], Headers: data[1], data: data[2] })
        }

        const res = yield call(ax, data);

        return res.data;


    } catch (error) {
        console.error(error, "error")
        return error;
    }
}

function* cleanFormFilterTableGenerales() {
    yield initialize("searchForm", {})
}


export function* watchAgent() {
    yield takeLatest(DATA_LINECHART, obtenerDatosGraficaLineChart);
    yield takeLatest(TMPESP_PROM, obtenerTmpPromEspConvsCerradas);
    yield takeLatest(TMPRESP_PROM, obtenerTmpPromRespConvsCerradas);
    yield takeLatest(TMPCONV_PROM, obtenerTmpPromConv);
    yield takeLatest(CONVS_RESUELTAS, obtenerConvsCerradasResueltas);
    yield takeLatest(CONVS_CANAL, obtenerCanalConvsCerradas);
    yield takeLatest(CONVS_CERRADAS, obtenerConvsCerradas)
    yield takeLatest(FILTRAR_BUSQUEDA, filtrarBusqueda);
    yield takeLatest(LISTAR_ESTADOS, listarEstados);
    yield takeLatest(LISTAR_AGENTES, listarAgentes);
    yield takeLatest(LANZAR_EVENTOS, desplegarEventosBuscador);
    yield takeLatest(SELECT_CONVERSATIONDETAIL, selectConversationDetail);
    yield takeLatest(MODAL_DESCARGAR_DATOS, abrirModalDescargarDatos);
    yield takeLatest(GRAFICOS_TEMAS, obtenerDatosGraficaTemas);
    yield takeLatest(CONVS_TRANSFERENCIAS, obtenerDataTransferencias)
    yield takeLatest(SINGLE_BUSQUEDA, singleSearch);
    yield takeLatest(CONVS_ABANDONADAS, obtenerDataPieConvsAbandonadas);
    yield takeLatest(OCULTAR_ESTADISTICAS, ocultarEstadisticasConvsAbandonadas);
    yield takeLatest(CONVS_AGENTES, obtenerDataConvsAgentes);
    yield takeLatest(CONVS_NOTAS_OCULTAS, obtenerDataNotasOcultas);
    yield takeLatest(CONVS_CONECTED_USERS, obtenerDataConectedUsers);
    yield takeLatest(CONVS_CHATBOT, obtenerDataChatbot);
    yield takeLatest(CONVS_COLAS_ATENCION, obtenerDataColasAtencion);
    yield takeLatest(CONVS_ATENDIDAS_CHATBOT, obtenerPieConvsAtendidasChatBot);
    yield takeLatest(GRAFICA_QUERY_SENTIMENT, obtenerDataGraficaQuerySentiment);
    yield takeLatest(GRAFICA_ENCUESTA_GENERAL, obtenerDatosGraficaEncuesta);
    yield takeLatest(PAGINAR_CONVS_CERRADAS, paginarConvsCerradas);
    yield takeLatest(DESCARGAR_CSV_CONVS_CERRADAS, descargarCSVConvsCerradas);
    yield takeLatest(REPORTE_WS_FALLIDOS, obtenerDataWsFallidos);
    yield takeLatest(DATOS_CONVS_CHATBOT, obtenerDatosConvsChatbot);
    yield takeLatest(DATOS_CONVS_PRESUPUESTOS, obtenerDatosConvsPresupuestos);
    yield takeLatest(PAGINAR_DATA_CONVS_CHABOT, paginarDatosConvsChatbot);
    yield takeLatest(PAGINAR_DATA_CONVS_PRESUPUESTOS, paginarDatosConvsPresupuestos);
    yield takeLatest(DATOS_RESP_UNKNOWN_BOT, obtenerDatosRespUnknownBot);
    yield takeLatest(PAGINAR_DATA_UNKNOWNRESP_BOT, paginarDatosRespUnknownBot);
    yield takeLatest(DATOS_INTENTS_PORCENTAJE, obtenerDatosIntentsPorcentajeUso);
    yield takeLatest(GRAFICA_TASA_COINCIDENCIAS, obtenerDataTasaCoincidenciaBot);
    yield takeLatest(DATOS_COMENTARIOS_INSATISFACCION, obtenerDataComentariosInsatisfaccion);
    yield takeLatest(PAGINAR_COMENTARIOS_INSATISFACCION, paginarDataComentariosInsatisfaccion);
    yield takeLatest(GENERAR_DATA_CSV, generaCsv);
    yield takeLatest(DATOS_GRAFICA_TOP_10, obtenerDatosGraficaTop10);
    yield takeLatest(TMP_PROM_AGENTES, obtenerTmpPromAgentes);
    yield takeLatest(CONVS_RESUELTAS_AGENTES, obtenerConvsResueltasAgentes);
    yield takeLatest(GRAFICO_PROM_RESP_AGENTES, graficarTmpPromRespAgentes);
    yield takeLatest(GRAFICO_PROM_ESP_AGENTES, graficarTmpPromEspAgentes);
    yield takeLatest(CONVERSACIONES_SERVICIOS_EXTERNOS, tablaHuecos);
    yield takeLatest(GET_GRAFICO_DATOS_REPORTE_HUECOS, obtenerReporteHuecos);
    yield takeLatest(GET_TABLA_DATOS_ZONA_PARQUEO, obtenerDataZonaParqueo);
    yield takeLatest(GET_TABLA_DATOS_REGISTRO_GAB, obtenerDataRegistroGAB);
    yield takeLatest(GET_TABLA_DATOS_ATENCION_CANAL, obtenerDataAtencionCanal);
    yield takeLatest(GET_TABLA_DATOS_ATENCION_AGENTE, obtenerDataAtencionAgente);
    yield takeLatest(ENCUESTAS_AGENTES, encuestasAgentes);
    yield takeLatest(ENCUESTAS_AGENTES, obtenerConvsCerradasAgentes);
    yield takeLatest(PAGINAR_CONVS_CERRADAS_AGENTES, paginarConvsCerradasAgentes);
    yield takeLatest(DESCARGAR_CSV_CONVS_CERRADAS_AGENTES, descargarCSVConvsCerradasAgentes);
    yield takeLatest(FILTRAR_BUSQUEDA_AGENTE, filtrarBusquedaAgente);
    yield takeLatest(LIMPIAR_FORM, cleanFormFilterTableGenerales)


}